// Generated by purs version 0.13.6
"use strict";
var Concur_Core_DOM = require("../Concur.Core.DOM/index.js");
var Concur_Core_LiftWidget = require("../Concur.Core.LiftWidget/index.js");
var Concur_Core_Types = require("../Concur.Core.Types/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var React_DOM = require("../React.DOM/index.js");
var viewAdapter = function (f) {
    return function (ps) {
        return function (vs) {
            return [ f(ps)(vs) ];
        };
    };
};
var text = function (dictLiftWidget) {
    return function (str) {
        return Concur_Core_LiftWidget.liftWidget(dictLiftWidget)(Concur_Core_Types.display([ React_DOM.text(str) ]));
    };
};
var number = function (dictLiftWidget) {
    return function (x) {
        return Concur_Core_LiftWidget.liftWidget(dictLiftWidget)(Concur_Core_Types.display([ React_DOM.number(x) ]));
    };
};
var $$int = function (dictLiftWidget) {
    return function (x) {
        return Concur_Core_LiftWidget.liftWidget(dictLiftWidget)(Concur_Core_Types.display([ React_DOM["int"](x) ]));
    };
};
var elLeaf = function (dictLiftWidget) {
    return function (f) {
        return Concur_Core_DOM.elLeaf(dictLiftWidget)(Data_Functor.functorArray)(function (ps) {
            return [ f(ps) ];
        });
    };
};
var embed = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.embed);
};
var embed$prime = function (dictLiftWidget) {
    return embed(dictLiftWidget)([  ]);
};
var hr = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.hr);
};
var hr$prime = function (dictLiftWidget) {
    return hr(dictLiftWidget)([  ]);
};
var img = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.img);
};
var img$prime = function (dictLiftWidget) {
    return img(dictLiftWidget)([  ]);
};
var input = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.input);
};
var input$prime = function (dictLiftWidget) {
    return input(dictLiftWidget)([  ]);
};
var keygen = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.keygen);
};
var keygen$prime = function (dictLiftWidget) {
    return keygen(dictLiftWidget)([  ]);
};
var link = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.link);
};
var link$prime = function (dictLiftWidget) {
    return link(dictLiftWidget)([  ]);
};
var menuitem = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.menuitem);
};
var menuitem$prime = function (dictLiftWidget) {
    return menuitem(dictLiftWidget)([  ]);
};
var meta = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.meta);
};
var meta$prime = function (dictLiftWidget) {
    return meta(dictLiftWidget)([  ]);
};
var param = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.param);
};
var param$prime = function (dictLiftWidget) {
    return param(dictLiftWidget)([  ]);
};
var source = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.source);
};
var source$prime = function (dictLiftWidget) {
    return source(dictLiftWidget)([  ]);
};
var track = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.track);
};
var track$prime = function (dictLiftWidget) {
    return track(dictLiftWidget)([  ]);
};
var wbr = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.wbr);
};
var wbr$prime = function (dictLiftWidget) {
    return wbr(dictLiftWidget)([  ]);
};
var el$prime = function (dictShiftMap) {
    return function (dictMultiAlternative) {
        return function (f) {
            return Concur_Core_DOM["el'"](dictShiftMap)(dictMultiAlternative)(Data_Functor.functorArray)(viewAdapter(f));
        };
    };
};
var em = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.em);
    };
};
var em$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return em(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var fieldset = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.fieldset);
    };
};
var fieldset$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return fieldset(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var figcaption = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.figcaption);
    };
};
var figcaption$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return figcaption(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var figure = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.figure);
    };
};
var figure$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return figure(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var footer = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.footer);
    };
};
var footer$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return footer(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var form = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.form);
    };
};
var form$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return form(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var h1 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h1);
    };
};
var h1$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return h1(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var h2 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h2);
    };
};
var h2$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return h2(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var h3 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h3);
    };
};
var h3$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return h3(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var h4 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h4);
    };
};
var h4$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return h4(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var h5 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h5);
    };
};
var h5$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return h5(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var h6 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h6);
    };
};
var h6$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return h6(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var head = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.head);
    };
};
var head$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return head(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var header = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.header);
    };
};
var header$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return header(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var html = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.html);
    };
};
var html$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return html(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var i = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.i);
    };
};
var i$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return i(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var iframe = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.iframe);
    };
};
var iframe$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return iframe(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var ins = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ins);
    };
};
var ins$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return ins(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var kbd = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.kbd);
    };
};
var kbd$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return kbd(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var label = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.label);
    };
};
var label$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return label(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var legend = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.legend);
    };
};
var legend$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return legend(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var li = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.li);
    };
};
var li$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return li(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var main = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.main);
    };
};
var main$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return main(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var mark = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.mark);
    };
};
var mark$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return mark(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var menu = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.menu);
    };
};
var menu$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return menu(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var meter = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.meter);
    };
};
var meter$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return meter(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var nav = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.nav);
    };
};
var nav$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return nav(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var noscript = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.noscript);
    };
};
var noscript$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return noscript(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var object = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.object);
    };
};
var object$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return object(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var ol = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ol);
    };
};
var ol$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return ol(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var optgroup = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.optgroup);
    };
};
var optgroup$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return optgroup(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var option = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.option);
    };
};
var option$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return option(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var output = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.output);
    };
};
var output$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return output(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var p = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.p);
    };
};
var p$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return p(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var picture = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.picture);
    };
};
var picture$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return picture(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var pre = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.pre);
    };
};
var pre$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return pre(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var progress = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.progress);
    };
};
var progress$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return progress(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var q = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.q);
    };
};
var q$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return q(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var rp = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.rp);
    };
};
var rp$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return rp(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var rt = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.rt);
    };
};
var rt$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return rt(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var ruby = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ruby);
    };
};
var ruby$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return ruby(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var s = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.s);
    };
};
var s$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return s(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var samp = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.samp);
    };
};
var samp$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return samp(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var script = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.script);
    };
};
var script$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return script(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var section = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.section);
    };
};
var section$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return section(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var select = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.select);
    };
};
var select$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return select(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var small = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.small);
    };
};
var small$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return small(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var span = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.span);
    };
};
var span$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return span(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var strong = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.strong);
    };
};
var strong$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return strong(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var style = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.style);
    };
};
var style$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return style(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var sub = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.sub);
    };
};
var sub$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return sub(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var summary = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.summary);
    };
};
var summary$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return summary(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var sup = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.sup);
    };
};
var sup$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return sup(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var table = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.table);
    };
};
var table$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return table(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var tbody = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.tbody);
    };
};
var tbody$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return tbody(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var td = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.td);
    };
};
var td$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return td(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var textarea = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.textarea);
    };
};
var textarea$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return textarea(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var tfoot = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.tfoot);
    };
};
var tfoot$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return tfoot(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var th = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.th);
    };
};
var th$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return th(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var thead = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.thead);
    };
};
var thead$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return thead(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var time = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.time);
    };
};
var time$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return time(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var title = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.title);
    };
};
var title$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return title(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var tr = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.tr);
    };
};
var tr$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return tr(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var u = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.u);
    };
};
var u$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return u(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var ul = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ul);
    };
};
var ul$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return ul(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var $$var = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM["var"]);
    };
};
var var$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return $$var(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var video = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.video);
    };
};
var video$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return video(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var el = function (dictShiftMap) {
    return function (f) {
        return Concur_Core_DOM.el(dictShiftMap)(Data_Functor.functorArray)(viewAdapter(f));
    };
};
var em_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.em);
};
var fieldset_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.fieldset);
};
var figcaption_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.figcaption);
};
var figure_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.figure);
};
var footer_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.footer);
};
var form_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.form);
};
var h1_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h1);
};
var h2_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h2);
};
var h3_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h3);
};
var h4_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h4);
};
var h5_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h5);
};
var h6_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h6);
};
var head_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.head);
};
var header_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.header);
};
var html_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.html);
};
var i_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.i);
};
var iframe_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.iframe);
};
var ins_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ins);
};
var kbd_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.kbd);
};
var label_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.label);
};
var legend_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.legend);
};
var li_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.li);
};
var main_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.main);
};
var mark_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.mark);
};
var menu_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.menu);
};
var meter_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.meter);
};
var nav_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.nav);
};
var noscript_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.noscript);
};
var object_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.object);
};
var ol_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ol);
};
var optgroup_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.optgroup);
};
var option_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.option);
};
var output_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.output);
};
var p_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.p);
};
var picture_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.picture);
};
var pre_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.pre);
};
var progress_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.progress);
};
var q_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.q);
};
var rp_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.rp);
};
var rt_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.rt);
};
var ruby_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ruby);
};
var s_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.s);
};
var samp_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.samp);
};
var script_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.script);
};
var section_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.section);
};
var select_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.select);
};
var small_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.small);
};
var span_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.span);
};
var strong_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.strong);
};
var style_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.style);
};
var sub_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.sub);
};
var summary_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.summary);
};
var sup_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.sup);
};
var table_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.table);
};
var tbody_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.tbody);
};
var td_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.td);
};
var textarea_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.textarea);
};
var tfoot_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.tfoot);
};
var th_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.th);
};
var thead_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.thead);
};
var time_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.time);
};
var title_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.title);
};
var tr_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.tr);
};
var u_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.u);
};
var ul_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ul);
};
var var_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM["var"]);
};
var video_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.video);
};
var dt_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dt);
};
var dt = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dt);
    };
};
var dt$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return dt(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var dl_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dl);
};
var dl = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dl);
    };
};
var dl$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return dl(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var div_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.div);
};
var div = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.div);
    };
};
var div$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return div(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var dialog_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dialog);
};
var dialog = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dialog);
    };
};
var dialog$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return dialog(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var dfn_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dfn);
};
var dfn = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dfn);
    };
};
var dfn$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return dfn(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var details_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.details);
};
var details = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.details);
    };
};
var details$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return details(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var del_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.del);
};
var del = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.del);
    };
};
var del$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return del(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var dd_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dd);
};
var dd = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dd);
    };
};
var dd$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return dd(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var datalist_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.datalist);
};
var datalist = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.datalist);
    };
};
var datalist$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return datalist(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var colgroup_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.colgroup);
};
var colgroup = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.colgroup);
    };
};
var colgroup$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return colgroup(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var col = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.col);
};
var col$prime = function (dictLiftWidget) {
    return col(dictLiftWidget)([  ]);
};
var code_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.code);
};
var code = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.code);
    };
};
var code$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return code(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var cite_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.cite);
};
var cite = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.cite);
    };
};
var cite$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return cite(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var caption_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.caption);
};
var caption = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.caption);
    };
};
var caption$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return caption(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var canvas_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.canvas);
};
var canvas = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.canvas);
    };
};
var canvas$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return canvas(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var button_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.button);
};
var button = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.button);
    };
};
var button$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return button(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var br = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.br);
};
var br$prime = function (dictLiftWidget) {
    return br(dictLiftWidget)([  ]);
};
var body_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.body);
};
var body = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.body);
    };
};
var body$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return body(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var blockquote_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.blockquote);
};
var blockquote = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.blockquote);
    };
};
var blockquote$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return blockquote(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var big_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.big);
};
var big = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.big);
    };
};
var big$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return big(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var bdo_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.bdo);
};
var bdo = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.bdo);
    };
};
var bdo$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return bdo(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var bdi_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.bdi);
};
var bdi = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.bdi);
    };
};
var bdi$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return bdi(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var base = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.base);
};
var base$prime = function (dictLiftWidget) {
    return base(dictLiftWidget)([  ]);
};
var b_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.b);
};
var b = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.b);
    };
};
var b$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return b(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var audio_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.audio);
};
var audio = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.audio);
    };
};
var audio$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return audio(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var aside_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.aside);
};
var aside = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.aside);
    };
};
var aside$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return aside(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var article_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.article);
};
var article = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.article);
    };
};
var article$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return article(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var area = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.area);
};
var area$prime = function (dictLiftWidget) {
    return area(dictLiftWidget)([  ]);
};
var address_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.address);
};
var address = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.address);
    };
};
var address$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return address(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var abbr_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.abbr);
};
var abbr = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.abbr);
    };
};
var abbr$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return abbr(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var a_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.a);
};
var a = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.a);
    };
};
var a$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return a(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var _map_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.map);
};
var _map = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.map);
    };
};
var _map$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return _map(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
var _data_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM["_data"]);
};
var _data = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM["_data"]);
    };
};
var _data$prime = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return _data(dictMultiAlternative)(dictShiftMap)([  ]);
    };
};
module.exports = {
    viewAdapter: viewAdapter,
    el: el,
    "el'": el$prime,
    elLeaf: elLeaf,
    text: text,
    "int": $$int,
    number: number,
    a_: a_,
    a: a,
    "a'": a$prime,
    abbr_: abbr_,
    abbr: abbr,
    "abbr'": abbr$prime,
    address_: address_,
    address: address,
    "address'": address$prime,
    area: area,
    "area'": area$prime,
    article_: article_,
    article: article,
    "article'": article$prime,
    aside_: aside_,
    aside: aside,
    "aside'": aside$prime,
    audio_: audio_,
    audio: audio,
    "audio'": audio$prime,
    b_: b_,
    b: b,
    "b'": b$prime,
    base: base,
    "base'": base$prime,
    bdi_: bdi_,
    bdi: bdi,
    "bdi'": bdi$prime,
    bdo_: bdo_,
    bdo: bdo,
    "bdo'": bdo$prime,
    big_: big_,
    big: big,
    "big'": big$prime,
    blockquote_: blockquote_,
    blockquote: blockquote,
    "blockquote'": blockquote$prime,
    body_: body_,
    body: body,
    "body'": body$prime,
    br: br,
    "br'": br$prime,
    button_: button_,
    button: button,
    "button'": button$prime,
    canvas_: canvas_,
    canvas: canvas,
    "canvas'": canvas$prime,
    caption_: caption_,
    caption: caption,
    "caption'": caption$prime,
    cite_: cite_,
    cite: cite,
    "cite'": cite$prime,
    code_: code_,
    code: code,
    "code'": code$prime,
    col: col,
    "col'": col$prime,
    colgroup_: colgroup_,
    colgroup: colgroup,
    "colgroup'": colgroup$prime,
    "_data_": _data_,
    "_data": _data,
    "_data'": _data$prime,
    datalist_: datalist_,
    datalist: datalist,
    "datalist'": datalist$prime,
    dd_: dd_,
    dd: dd,
    "dd'": dd$prime,
    del_: del_,
    del: del,
    "del'": del$prime,
    details_: details_,
    details: details,
    "details'": details$prime,
    dfn_: dfn_,
    dfn: dfn,
    "dfn'": dfn$prime,
    dialog_: dialog_,
    dialog: dialog,
    "dialog'": dialog$prime,
    div_: div_,
    div: div,
    "div'": div$prime,
    dl_: dl_,
    dl: dl,
    "dl'": dl$prime,
    dt_: dt_,
    dt: dt,
    "dt'": dt$prime,
    em_: em_,
    em: em,
    "em'": em$prime,
    embed: embed,
    "embed'": embed$prime,
    fieldset_: fieldset_,
    fieldset: fieldset,
    "fieldset'": fieldset$prime,
    figcaption_: figcaption_,
    figcaption: figcaption,
    "figcaption'": figcaption$prime,
    figure_: figure_,
    figure: figure,
    "figure'": figure$prime,
    footer_: footer_,
    footer: footer,
    "footer'": footer$prime,
    form_: form_,
    form: form,
    "form'": form$prime,
    h1_: h1_,
    h1: h1,
    "h1'": h1$prime,
    h2_: h2_,
    h2: h2,
    "h2'": h2$prime,
    h3_: h3_,
    h3: h3,
    "h3'": h3$prime,
    h4_: h4_,
    h4: h4,
    "h4'": h4$prime,
    h5_: h5_,
    h5: h5,
    "h5'": h5$prime,
    h6_: h6_,
    h6: h6,
    "h6'": h6$prime,
    head_: head_,
    head: head,
    "head'": head$prime,
    header_: header_,
    header: header,
    "header'": header$prime,
    hr: hr,
    "hr'": hr$prime,
    html_: html_,
    html: html,
    "html'": html$prime,
    i_: i_,
    i: i,
    "i'": i$prime,
    iframe_: iframe_,
    iframe: iframe,
    "iframe'": iframe$prime,
    img: img,
    "img'": img$prime,
    input: input,
    "input'": input$prime,
    ins_: ins_,
    ins: ins,
    "ins'": ins$prime,
    kbd_: kbd_,
    kbd: kbd,
    "kbd'": kbd$prime,
    keygen: keygen,
    "keygen'": keygen$prime,
    label_: label_,
    label: label,
    "label'": label$prime,
    legend_: legend_,
    legend: legend,
    "legend'": legend$prime,
    li_: li_,
    li: li,
    "li'": li$prime,
    link: link,
    "link'": link$prime,
    main_: main_,
    main: main,
    "main'": main$prime,
    "_map_": _map_,
    "_map": _map,
    "_map'": _map$prime,
    mark_: mark_,
    mark: mark,
    "mark'": mark$prime,
    menu_: menu_,
    menu: menu,
    "menu'": menu$prime,
    menuitem: menuitem,
    "menuitem'": menuitem$prime,
    meta: meta,
    "meta'": meta$prime,
    meter_: meter_,
    meter: meter,
    "meter'": meter$prime,
    nav_: nav_,
    nav: nav,
    "nav'": nav$prime,
    noscript_: noscript_,
    noscript: noscript,
    "noscript'": noscript$prime,
    object_: object_,
    object: object,
    "object'": object$prime,
    ol_: ol_,
    ol: ol,
    "ol'": ol$prime,
    optgroup_: optgroup_,
    optgroup: optgroup,
    "optgroup'": optgroup$prime,
    option_: option_,
    option: option,
    "option'": option$prime,
    output_: output_,
    output: output,
    "output'": output$prime,
    p_: p_,
    p: p,
    "p'": p$prime,
    param: param,
    "param'": param$prime,
    picture_: picture_,
    picture: picture,
    "picture'": picture$prime,
    pre_: pre_,
    pre: pre,
    "pre'": pre$prime,
    progress_: progress_,
    progress: progress,
    "progress'": progress$prime,
    q_: q_,
    q: q,
    "q'": q$prime,
    rp_: rp_,
    rp: rp,
    "rp'": rp$prime,
    rt_: rt_,
    rt: rt,
    "rt'": rt$prime,
    ruby_: ruby_,
    ruby: ruby,
    "ruby'": ruby$prime,
    s_: s_,
    s: s,
    "s'": s$prime,
    samp_: samp_,
    samp: samp,
    "samp'": samp$prime,
    script_: script_,
    script: script,
    "script'": script$prime,
    section_: section_,
    section: section,
    "section'": section$prime,
    select_: select_,
    select: select,
    "select'": select$prime,
    small_: small_,
    small: small,
    "small'": small$prime,
    source: source,
    "source'": source$prime,
    span_: span_,
    span: span,
    "span'": span$prime,
    strong_: strong_,
    strong: strong,
    "strong'": strong$prime,
    style_: style_,
    style: style,
    "style'": style$prime,
    sub_: sub_,
    sub: sub,
    "sub'": sub$prime,
    summary_: summary_,
    summary: summary,
    "summary'": summary$prime,
    sup_: sup_,
    sup: sup,
    "sup'": sup$prime,
    table_: table_,
    table: table,
    "table'": table$prime,
    tbody_: tbody_,
    tbody: tbody,
    "tbody'": tbody$prime,
    td_: td_,
    td: td,
    "td'": td$prime,
    textarea_: textarea_,
    textarea: textarea,
    "textarea'": textarea$prime,
    tfoot_: tfoot_,
    tfoot: tfoot,
    "tfoot'": tfoot$prime,
    th_: th_,
    th: th,
    "th'": th$prime,
    thead_: thead_,
    thead: thead,
    "thead'": thead$prime,
    time_: time_,
    time: time,
    "time'": time$prime,
    title_: title_,
    title: title,
    "title'": title$prime,
    tr_: tr_,
    tr: tr,
    "tr'": tr$prime,
    track: track,
    "track'": track$prime,
    u_: u_,
    u: u,
    "u'": u$prime,
    ul_: ul_,
    ul: ul,
    "ul'": ul$prime,
    var_: var_,
    "var": $$var,
    "var'": var$prime,
    video_: video_,
    video: video,
    "video'": video$prime,
    wbr: wbr,
    "wbr'": wbr$prime
};
