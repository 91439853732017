// Generated by purs version 0.13.6
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Monad_Free = require("../Control.Monad.Free/index.js");
var Control_MultiAlternative = require("../Control.MultiAlternative/index.js");
var Control_Parallel_Class = require("../Control.Parallel.Class/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Control_ShiftMap = require("../Control.ShiftMap/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_AVar = require("../Effect.AVar/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_AVar = require("../Effect.Aff.AVar/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var WidgetStep = function (x) {
    return x;
};
var Widget = function (x) {
    return x;
};
var widgetShiftMap = new Control_ShiftMap.ShiftMap(function (f) {
    return f(Control_Category.identity(Control_Category.categoryFn));
});
var widgetMonadRec = Control_Monad_Free.freeMonadRec;
var widgetFunctor = Control_Monad_Free.freeFunctor;
var widgetBind = Control_Monad_Free.freeBind;
var widgetApply = Control_Monad_Free.freeApply;
var widgetApplicative = Control_Monad_Free.freeApplicative;
var widgetMonad = new Control_Monad.Monad(function () {
    return widgetApplicative;
}, function () {
    return widgetBind;
});
var unWidgetStep = function (v) {
    return v;
};
var unWidget = function (v) {
    return v;
};
var resume = function (dictFunctor) {
    return Control_Monad_Free["resume'"](function (g) {
        return function (i) {
            return new Data_Either.Right(Data_Functor.map(dictFunctor)(i)(g));
        };
    })(Data_Either.Left.create);
};
var mapViewStep = function (f) {
    return function (v) {
        var mod = function (ws$prime) {
            return {
                view: f(ws$prime.view),
                cont: ws$prime.cont
            };
        };
        return Data_Functor.map(Data_Either.functorEither)(mod)(v);
    };
};
var mapView = function (f) {
    return function (v) {
        return Control_Monad_Free.hoistFree(mapViewStep(f))(v);
    };
};
var functorWidgetStep = new Data_Functor.Functor(function (f) {
    return function (v) {
        var mod = function (v1) {
            if (v1 instanceof Data_Either.Right) {
                return new Data_Either.Right({
                    cont: Data_Functor.map(Effect_Aff.functorAff)(f)(v1.value0.cont),
                    view: v1.value0.view
                });
            };
            if (v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(Data_Functor.map(Effect.functorEffect)(f)(v1.value0));
            };
            throw new Error("Failed pattern match at Concur.Core.Types (line 45, column 5 - line 45, column 57): " + [ v1.constructor.name ]);
        };
        return mod(v);
    };
});
var flipEither = function (v) {
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Right(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return new Data_Either.Left(v.value0);
    };
    throw new Error("Failed pattern match at Concur.Core.Types (line 73, column 1 - line 76, column 13): " + [ v.constructor.name ]);
};
var effAction = function ($82) {
    return Widget(Control_Monad_Free.liftF(WidgetStep(Data_Either.Left.create($82))));
};
var pulse = function (dictMonoid) {
    return effAction(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
};
var widgetMonadEff = function (dictMonoid) {
    return new Effect_Class.MonadEffect(function () {
        return widgetMonad;
    }, effAction);
};
var displayStep = function (v) {
    return new Data_Either.Right({
        view: v,
        cont: Effect_Aff.never
    });
};
var display = function (v) {
    return Control_Monad_Free.liftF(displayStep(v));
};
var widgetSemigroup = function (dictMonoid) {
    return new Data_Semigroup.Semigroup(function (w1) {
        return function (w2) {
            return Control_MultiAlternative.orr(widgetMultiAlternative(dictMonoid))([ w1, w2 ]);
        };
    });
};
var widgetPlus = function (dictMonoid) {
    return new Control_Plus.Plus(function () {
        return widgetAlt(dictMonoid);
    }, display(Data_Monoid.mempty(dictMonoid)));
};
var widgetMultiAlternative = function (dictMonoid) {
    return new Control_MultiAlternative.MultiAlternative(function () {
        return widgetPlus(dictMonoid);
    }, function (wss) {
        var merge = function (dictMonoid1) {
            return function (ws) {
                return function (wscs) {
                    var wsm = Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function ($83) {
                        return Control_Monad_Free.wrap(WidgetStep(Data_Either.Right.create($83)));
                    })(ws);
                    return Control_Bind.bind(Effect_Aff.bindAff)(Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Data_FoldableWithIndex.foldlWithIndex(Data_Array_NonEmpty_Internal.foldableWithIndexNonEmptyArray)(function (i) {
                        return function (r) {
                            return function (w) {
                                return Control_Alt.alt(Effect_Aff.altParAff)(Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Data_Functor.map(Effect_Aff.functorAff)(Data_Tuple.Tuple.create(i))(w)))(r);
                            };
                        };
                    })(Control_Plus.empty(Effect_Aff.plusParAff))(wscs)))(function (v) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(combine(dictMonoid1)(Data_Maybe.fromMaybe(wsm)(Data_Array_NonEmpty.updateAt(v.value0)(v.value1)(wsm))));
                    });
                };
            };
        };
        var combineViewsConts = function (dictMonoid1) {
            return function (ws) {
                return Control_Monad_Free.wrap(WidgetStep(new Data_Either.Right({
                    view: Data_Semigroup_Foldable.foldMap1(Data_Array_NonEmpty_Internal.foldable1NonEmptyArray)(dictMonoid1.Semigroup0())(function (v) {
                        return v.view;
                    })(ws),
                    cont: merge(dictMonoid1)(ws)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v) {
                        return v.cont;
                    })(ws))
                })));
            };
        };
        var combineInner1 = function (dictMonoid1) {
            return function (ws) {
                return function (freeNarr) {
                    var x = Data_Array_NonEmpty.uncons(freeNarr);
                    var v = resume(functorWidgetStep)(x.head);
                    if (v instanceof Data_Either.Left) {
                        return Control_Applicative.pure(Control_Monad_Free.freeApplicative)(v.value0);
                    };
                    if (v instanceof Data_Either.Right) {
                        if (v.value0 instanceof Data_Either.Left) {
                            return Control_Monad_Free.wrap(WidgetStep(new Data_Either.Left(function __do() {
                                var w = v.value0.value0();
                                return combineInner1(dictMonoid1)(ws)(Data_Array_NonEmpty["cons'"](w)(x.tail));
                            })));
                        };
                        if (v.value0 instanceof Data_Either.Right) {
                            return combineInner(dictMonoid1)(Data_Array_NonEmpty.snoc(ws)(v.value0.value0))(x.tail);
                        };
                        throw new Error("Failed pattern match at Concur.Core.Types (line 138, column 34 - line 142, column 61): " + [ v.value0.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at Concur.Core.Types (line 136, column 10 - line 142, column 61): " + [ v.constructor.name ]);
                };
            };
        };
        var combineInner = function (dictMonoid1) {
            return function (ws) {
                return function (freeArr) {
                    var v = Data_Array_NonEmpty.fromArray(freeArr);
                    if (v instanceof Data_Maybe.Nothing) {
                        return combineViewsConts(dictMonoid1)(ws);
                    };
                    if (v instanceof Data_Maybe.Just) {
                        return combineInner1(dictMonoid1)(ws)(v.value0);
                    };
                    throw new Error("Failed pattern match at Concur.Core.Types (line 113, column 31 - line 116, column 49): " + [ v.constructor.name ]);
                };
            };
        };
        var combine = function (dictMonoid1) {
            return function (wfs) {
                var x = Data_Array_NonEmpty.uncons(wfs);
                var v = resume(functorWidgetStep)(x.head);
                if (v instanceof Data_Either.Left) {
                    return Control_Applicative.pure(Control_Monad_Free.freeApplicative)(v.value0);
                };
                if (v instanceof Data_Either.Right) {
                    if (v.value0 instanceof Data_Either.Left) {
                        return Control_Monad_Free.wrap(WidgetStep(new Data_Either.Left(function __do() {
                            var w = v.value0.value0();
                            return combine(dictMonoid1)(Data_Array_NonEmpty["cons'"](w)(x.tail));
                        })));
                    };
                    if (v.value0 instanceof Data_Either.Right) {
                        return combineInner(dictMonoid1)(Data_Array_NonEmpty.singleton(v.value0.value0))(x.tail);
                    };
                    throw new Error("Failed pattern match at Concur.Core.Types (line 101, column 34 - line 105, column 63): " + [ v.value0.constructor.name ]);
                };
                throw new Error("Failed pattern match at Concur.Core.Types (line 99, column 10 - line 105, column 63): " + [ v.constructor.name ]);
            };
        };
        var v = Data_Array_NonEmpty.fromArray(wss);
        if (v instanceof Data_Maybe.Just) {
            return Widget(combine(dictMonoid)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(unWidget)(v.value0)));
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Control_Plus.empty(widgetPlus(dictMonoid));
        };
        throw new Error("Failed pattern match at Concur.Core.Types (line 88, column 13 - line 90, column 21): " + [ v.constructor.name ]);
    });
};
var widgetAlt = function (dictMonoid) {
    return new Control_Alt.Alt(function () {
        return widgetFunctor;
    }, Data_Semigroup.append(widgetSemigroup(dictMonoid)));
};
var widgetAlternative = function (dictMonoid) {
    return new Control_Alternative.Alternative(function () {
        return widgetApplicative;
    }, function () {
        return widgetPlus(dictMonoid);
    });
};
var widgetMonoid = function (dictMonoid) {
    return new Data_Monoid.Monoid(function () {
        return widgetSemigroup(dictMonoid);
    }, Control_Plus.empty(widgetPlus(dictMonoid)));
};
var andd = function (dictMonoid) {
    return function (ws) {
        return Control_Bind.bind(widgetBind)(Data_FoldableWithIndex.foldrWithIndex(Data_FoldableWithIndex.foldableWithIndexArray)(function (i) {
            return function (w) {
                return function (r) {
                    return Control_Alt.alt(widgetAlt(dictMonoid))(Data_Functor.map(widgetFunctor)(Data_Tuple.Tuple.create(i))(w))(r);
                };
            };
        })(Control_Plus.empty(widgetPlus(dictMonoid)))(ws))(function (v) {
            var ws$prime = Data_Maybe.fromMaybe(ws)(Data_Array.deleteAt(v.value0)(ws));
            var $73 = Data_Array.length(ws$prime) <= 0;
            if ($73) {
                return Control_Applicative.pure(widgetApplicative)([ v.value1 ]);
            };
            return Control_Bind.bind(widgetBind)(andd(dictMonoid)(ws$prime))(function (rest) {
                return Control_Applicative.pure(widgetApplicative)(Data_Maybe.fromMaybe([  ])(Data_Array.insertAt(v.value0)(v.value1)(rest)));
            });
        });
    };
};
var affAction = function (v) {
    return function (aff) {
        var handler = function (v1) {
            return function (v2) {
                if (v2 instanceof Data_Either.Left) {
                    return Effect_Console.log("Aff failed - " + Data_Show.show(Effect_Exception.showError)(v2.value0));
                };
                if (v2 instanceof Data_Either.Right) {
                    return Data_Functor["void"](Effect.functorEffect)(Effect_AVar.tryPut(v2.value0)(v1));
                };
                throw new Error("Failed pattern match at Concur.Core.Types (line 237, column 3 - line 237, column 55): " + [ v1.constructor.name, v2.constructor.name ]);
            };
        };
        return Widget(Control_Monad_Free.wrap(WidgetStep(new Data_Either.Left(function __do() {
            var $$var = Effect_AVar.empty();
            Effect_Aff.runAff_(handler($$var))(aff)();
            var ma = Effect_AVar.tryTake($$var)();
            if (ma instanceof Data_Maybe.Just) {
                return Control_Applicative.pure(Control_Monad_Free.freeApplicative)(ma.value0);
            };
            if (ma instanceof Data_Maybe.Nothing) {
                return Control_Monad_Free.liftF(WidgetStep(new Data_Either.Right({
                    view: v,
                    cont: Effect_Aff_AVar.take($$var)
                })));
            };
            throw new Error("Failed pattern match at Concur.Core.Types (line 232, column 8 - line 234, column 75): " + [ ma.constructor.name ]);
        }))));
    };
};
var asyncAction = function (v) {
    return function (handler) {
        return affAction(v)(Effect_Aff.makeAff((function () {
            var $84 = Data_Functor.map(Effect.functorEffect)(Effect_Aff.effectCanceler);
            return function ($85) {
                return $84(handler($85));
            };
        })()));
    };
};
var widgetMonadAff = function (dictMonoid) {
    return new Effect_Aff_Class.MonadAff(function () {
        return widgetMonadEff(dictMonoid);
    }, affAction(Data_Monoid.mempty(dictMonoid)));
};
module.exports = {
    WidgetStep: WidgetStep,
    unWidgetStep: unWidgetStep,
    displayStep: displayStep,
    Widget: Widget,
    unWidget: unWidget,
    flipEither: flipEither,
    resume: resume,
    andd: andd,
    pulse: pulse,
    mapView: mapView,
    mapViewStep: mapViewStep,
    display: display,
    effAction: effAction,
    affAction: affAction,
    asyncAction: asyncAction,
    functorWidgetStep: functorWidgetStep,
    widgetFunctor: widgetFunctor,
    widgetBind: widgetBind,
    widgetApplicative: widgetApplicative,
    widgetApply: widgetApply,
    widgetMonad: widgetMonad,
    widgetMonadRec: widgetMonadRec,
    widgetShiftMap: widgetShiftMap,
    widgetMultiAlternative: widgetMultiAlternative,
    widgetSemigroup: widgetSemigroup,
    widgetMonoid: widgetMonoid,
    widgetAlt: widgetAlt,
    widgetPlus: widgetPlus,
    widgetAlternative: widgetAlternative,
    widgetMonadEff: widgetMonadEff,
    widgetMonadAff: widgetMonadAff
};
