// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var get = function (dictCons) {
    return function (dictIsSymbol) {
        return function (l) {
            return function (r) {
                return $foreign.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
            };
        };
    };
};
var isTrusted = get()(new Data_Symbol.IsSymbol(function () {
    return "isTrusted";
}))(Data_Symbol.SProxy.value);
var key = get()(new Data_Symbol.IsSymbol(function () {
    return "key";
}))(Data_Symbol.SProxy.value);
var keyCode = get()(new Data_Symbol.IsSymbol(function () {
    return "keyCode";
}))(Data_Symbol.SProxy.value);
var locale = get()(new Data_Symbol.IsSymbol(function () {
    return "locale";
}))(Data_Symbol.SProxy.value);
var location = get()(new Data_Symbol.IsSymbol(function () {
    return "location";
}))(Data_Symbol.SProxy.value);
var metaKey = get()(new Data_Symbol.IsSymbol(function () {
    return "metaKey";
}))(Data_Symbol.SProxy.value);
var nativeEvent = get()(new Data_Symbol.IsSymbol(function () {
    return "nativeEvent";
}))(Data_Symbol.SProxy.value);
var pageX = get()(new Data_Symbol.IsSymbol(function () {
    return "pageX";
}))(Data_Symbol.SProxy.value);
var pageY = get()(new Data_Symbol.IsSymbol(function () {
    return "pageY";
}))(Data_Symbol.SProxy.value);
var propertyName = get()(new Data_Symbol.IsSymbol(function () {
    return "propertyName";
}))(Data_Symbol.SProxy.value);
var pseudoElement = get()(new Data_Symbol.IsSymbol(function () {
    return "pseudoElement";
}))(Data_Symbol.SProxy.value);
var relatedTarget = get()(new Data_Symbol.IsSymbol(function () {
    return "relatedTarget";
}))(Data_Symbol.SProxy.value);
var repeat = get()(new Data_Symbol.IsSymbol(function () {
    return "repeat";
}))(Data_Symbol.SProxy.value);
var screenX = get()(new Data_Symbol.IsSymbol(function () {
    return "screenX";
}))(Data_Symbol.SProxy.value);
var screenY = get()(new Data_Symbol.IsSymbol(function () {
    return "screenY";
}))(Data_Symbol.SProxy.value);
var shiftKey = get()(new Data_Symbol.IsSymbol(function () {
    return "shiftKey";
}))(Data_Symbol.SProxy.value);
var target = get()(new Data_Symbol.IsSymbol(function () {
    return "target";
}))(Data_Symbol.SProxy.value);
var targetTouches = get()(new Data_Symbol.IsSymbol(function () {
    return "targetTouches";
}))(Data_Symbol.SProxy.value);
var timeStamp = get()(new Data_Symbol.IsSymbol(function () {
    return "timeStamp";
}))(Data_Symbol.SProxy.value);
var touches = get()(new Data_Symbol.IsSymbol(function () {
    return "touches";
}))(Data_Symbol.SProxy.value);
var type_ = get()(new Data_Symbol.IsSymbol(function () {
    return "type";
}))(Data_Symbol.SProxy.value);
var view = get()(new Data_Symbol.IsSymbol(function () {
    return "view";
}))(Data_Symbol.SProxy.value);
var which = get()(new Data_Symbol.IsSymbol(function () {
    return "which";
}))(Data_Symbol.SProxy.value);
var eventPhase = get()(new Data_Symbol.IsSymbol(function () {
    return "eventPhase";
}))(Data_Symbol.SProxy.value);
var elapsedTime = get()(new Data_Symbol.IsSymbol(function () {
    return "elapsedTime";
}))(Data_Symbol.SProxy.value);
var detail = get()(new Data_Symbol.IsSymbol(function () {
    return "detail";
}))(Data_Symbol.SProxy.value);
var deltaZ = get()(new Data_Symbol.IsSymbol(function () {
    return "deltaZ";
}))(Data_Symbol.SProxy.value);
var deltaY = get()(new Data_Symbol.IsSymbol(function () {
    return "deltaY";
}))(Data_Symbol.SProxy.value);
var deltaX = get()(new Data_Symbol.IsSymbol(function () {
    return "deltaX";
}))(Data_Symbol.SProxy.value);
var deltaMode = get()(new Data_Symbol.IsSymbol(function () {
    return "deltaMode";
}))(Data_Symbol.SProxy.value);
var defaultPrevented = get()(new Data_Symbol.IsSymbol(function () {
    return "defaultPrevented";
}))(Data_Symbol.SProxy.value);
var data_ = get()(new Data_Symbol.IsSymbol(function () {
    return "data";
}))(Data_Symbol.SProxy.value);
var currentTarget = get()(new Data_Symbol.IsSymbol(function () {
    return "currentTarget";
}))(Data_Symbol.SProxy.value);
var ctrlKey = get()(new Data_Symbol.IsSymbol(function () {
    return "ctrlKey";
}))(Data_Symbol.SProxy.value);
var clipboardData = get()(new Data_Symbol.IsSymbol(function () {
    return "clipboardData";
}))(Data_Symbol.SProxy.value);
var clientY = get()(new Data_Symbol.IsSymbol(function () {
    return "clientY";
}))(Data_Symbol.SProxy.value);
var clientX = get()(new Data_Symbol.IsSymbol(function () {
    return "clientX";
}))(Data_Symbol.SProxy.value);
var charCode = get()(new Data_Symbol.IsSymbol(function () {
    return "charCode";
}))(Data_Symbol.SProxy.value);
var changedTouches = get()(new Data_Symbol.IsSymbol(function () {
    return "changedTouches";
}))(Data_Symbol.SProxy.value);
var cancelable = get()(new Data_Symbol.IsSymbol(function () {
    return "cancelable";
}))(Data_Symbol.SProxy.value);
var buttons = get()(new Data_Symbol.IsSymbol(function () {
    return "buttons";
}))(Data_Symbol.SProxy.value);
var button = get()(new Data_Symbol.IsSymbol(function () {
    return "button";
}))(Data_Symbol.SProxy.value);
var bubbles = get()(new Data_Symbol.IsSymbol(function () {
    return "bubbles";
}))(Data_Symbol.SProxy.value);
var animationName = get()(new Data_Symbol.IsSymbol(function () {
    return "animationName";
}))(Data_Symbol.SProxy.value);
var altKey = get()(new Data_Symbol.IsSymbol(function () {
    return "altKey";
}))(Data_Symbol.SProxy.value);
module.exports = {
    bubbles: bubbles,
    cancelable: cancelable,
    currentTarget: currentTarget,
    defaultPrevented: defaultPrevented,
    eventPhase: eventPhase,
    isTrusted: isTrusted,
    nativeEvent: nativeEvent,
    target: target,
    timeStamp: timeStamp,
    type_: type_,
    animationName: animationName,
    clipboardData: clipboardData,
    data_: data_,
    relatedTarget: relatedTarget,
    charCode: charCode,
    key: key,
    keyCode: keyCode,
    locale: locale,
    location: location,
    repeat: repeat,
    which: which,
    button: button,
    buttons: buttons,
    clientX: clientX,
    clientY: clientY,
    pageX: pageX,
    pageY: pageY,
    screenX: screenX,
    screenY: screenY,
    changedTouches: changedTouches,
    targetTouches: targetTouches,
    touches: touches,
    altKey: altKey,
    ctrlKey: ctrlKey,
    metaKey: metaKey,
    shiftKey: shiftKey,
    propertyName: propertyName,
    pseudoElement: pseudoElement,
    elapsedTime: elapsedTime,
    detail: detail,
    view: view,
    deltaMode: deltaMode,
    deltaX: deltaX,
    deltaY: deltaY,
    deltaZ: deltaZ,
    preventDefault: $foreign.preventDefault,
    isDefaultPrevented: $foreign.isDefaultPrevented,
    stopPropagation: $foreign.stopPropagation,
    isPropagationStopped: $foreign.isPropagationStopped,
    persist: $foreign.persist,
    getModifierState: $foreign.getModifierState
};
