// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Concur_Core_Props = require("../Concur.Core.Props/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var React_DOM_Props = require("../React.DOM.Props/index.js");
var y = function ($0) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.y($0));
};
var x = function ($1) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.x($1));
};
var wrap = function ($2) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.wrap($2));
};
var wmode = function ($3) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.wmode($3));
};
var width = function ($4) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.width($4));
};
var vocab = function ($5) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.vocab($5));
};
var viewBox = function ($6) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.viewBox($6));
};
var valueArray = function ($7) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.valueArray($7));
};
var value = function ($8) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.value($8));
};
var useMap = function ($9) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.useMap($9));
};
var unselectable = function ($10) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.unselectable($10));
};
var unsafeTargetValue = function (e) {
    return e.target.value;
};
var unsafeMkPropHandler = function (s) {
    return new Concur_Core_Props.Handler(function (f) {
        return React_DOM_Props.unsafeMkProps(s)(Effect_Uncurried.mkEffectFn1(f));
    });
};
var unsafeMkProp = function (s) {
    return function (v) {
        return new Concur_Core_Props.PrimProp(React_DOM_Props.unsafeMkProps(s)(v));
    };
};
var $$typeof = function ($11) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["typeof"]($11));
};
var title = function ($12) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.title($12));
};
var target = function ($13) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.target($13));
};
var tabIndex = function ($14) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.tabIndex($14));
};
var suppressContentEditableWarning = function ($15) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.suppressContentEditableWarning($15));
};
var summary = function ($16) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.summary($16));
};
var style = function ($17) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.style($17));
};
var strokeWidth = function ($18) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.strokeWidth($18));
};
var stroke = function ($19) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.stroke($19));
};
var step = function ($20) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.step($20));
};
var start = function ($21) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.start($21));
};
var srcSet = function ($22) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.srcSet($22));
};
var srcLang = function ($23) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.srcLang($23));
};
var srcDoc = function ($24) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.srcDoc($24));
};
var src = function ($25) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.src($25));
};
var spellCheck = function ($26) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.spellCheck($26));
};
var span = function ($27) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.span($27));
};
var sizes = function ($28) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.sizes($28));
};
var size = function ($29) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.size($29));
};
var shape = function ($30) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.shape($30));
};
var selected = function ($31) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.selected($31));
};
var security = function ($32) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.security($32));
};
var seamless = function ($33) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.seamless($33));
};
var scrolling = function ($34) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.scrolling($34));
};
var scoped = function ($35) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.scoped($35));
};
var scope = function ($36) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.scope($36));
};
var sandbox = function ($37) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.sandbox($37));
};
var rows = function ($38) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.rows($38));
};
var rowSpan = function ($39) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.rowSpan($39));
};
var role = function ($40) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.role($40));
};
var reversed = function ($41) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.reversed($41));
};
var results = function ($42) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.results($42));
};
var resource = function ($43) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.resource($43));
};
var required = function ($44) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.required($44));
};
var rel = function ($45) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.rel($45));
};
var ref = function ($46) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.ref($46));
};
var readOnly = function ($47) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.readOnly($47));
};
var radioGroup = function ($48) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.radioGroup($48));
};
var r = function ($49) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.r($49));
};
var property = function ($50) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.property($50));
};
var profile = function ($51) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.profile($51));
};
var preload = function ($52) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.preload($52));
};
var prefix = function ($53) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.prefix($53));
};
var poster = function ($54) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.poster($54));
};
var points = function ($55) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.points($55));
};
var placeholder = function ($56) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.placeholder($56));
};
var pattern = function ($57) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.pattern($57));
};
var optimum = function ($58) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.optimum($58));
};
var open = function ($59) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.open($59));
};
var opacity = function ($60) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.opacity($60));
};
var onWheelCapture = new Concur_Core_Props.Handler(React_DOM_Props.onWheelCapture);
var onWheel = new Concur_Core_Props.Handler(React_DOM_Props.onWheel);
var onWaitingCapture = new Concur_Core_Props.Handler(React_DOM_Props.onWaitingCapture);
var onWaiting = new Concur_Core_Props.Handler(React_DOM_Props.onWaiting);
var onVolumeChangeCapture = new Concur_Core_Props.Handler(React_DOM_Props.onVolumeChangeCapture);
var onVolumeChange = new Concur_Core_Props.Handler(React_DOM_Props.onVolumeChange);
var onTransitionEndCapture = new Concur_Core_Props.Handler(React_DOM_Props.onTransitionEndCapture);
var onTransitionEnd = new Concur_Core_Props.Handler(React_DOM_Props.onTransitionEnd);
var onTouchStartCapture = new Concur_Core_Props.Handler(React_DOM_Props.onTouchStartCapture);
var onTouchStart = new Concur_Core_Props.Handler(React_DOM_Props.onTouchStart);
var onTouchMoveCapture = new Concur_Core_Props.Handler(React_DOM_Props.onTouchMoveCapture);
var onTouchMove = new Concur_Core_Props.Handler(React_DOM_Props.onTouchMove);
var onTouchEndCapture = new Concur_Core_Props.Handler(React_DOM_Props.onTouchEndCapture);
var onTouchEnd = new Concur_Core_Props.Handler(React_DOM_Props.onTouchEnd);
var onTouchCancelCapture = new Concur_Core_Props.Handler(React_DOM_Props.onTouchCancelCapture);
var onTouchCancel = new Concur_Core_Props.Handler(React_DOM_Props.onTouchCancel);
var onToggleCapture = new Concur_Core_Props.Handler(React_DOM_Props.onToggleCapture);
var onToggle = new Concur_Core_Props.Handler(React_DOM_Props.onToggle);
var onTimeUpdateCapture = new Concur_Core_Props.Handler(React_DOM_Props.onTimeUpdateCapture);
var onTimeUpdate = new Concur_Core_Props.Handler(React_DOM_Props.onTimeUpdate);
var onSuspendCapture = new Concur_Core_Props.Handler(React_DOM_Props.onSuspendCapture);
var onSuspend = new Concur_Core_Props.Handler(React_DOM_Props.onSuspend);
var onSubmitCapture = new Concur_Core_Props.Handler(React_DOM_Props.onSubmitCapture);
var onSubmit = new Concur_Core_Props.Handler(React_DOM_Props.onSubmit);
var onStalledCapture = new Concur_Core_Props.Handler(React_DOM_Props.onStalledCapture);
var onStalled = new Concur_Core_Props.Handler(React_DOM_Props.onStalled);
var onSelectCapture = new Concur_Core_Props.Handler(React_DOM_Props.onSelectCapture);
var onSelect = new Concur_Core_Props.Handler(React_DOM_Props.onSelect);
var onSeekingCapture = new Concur_Core_Props.Handler(React_DOM_Props.onSeekingCapture);
var onSeeking = new Concur_Core_Props.Handler(React_DOM_Props.onSeeking);
var onSeekedCapture = new Concur_Core_Props.Handler(React_DOM_Props.onSeekedCapture);
var onSeeked = new Concur_Core_Props.Handler(React_DOM_Props.onSeeked);
var onScrollCapture = new Concur_Core_Props.Handler(React_DOM_Props.onScrollCapture);
var onScroll = new Concur_Core_Props.Handler(React_DOM_Props.onScroll);
var onRateChangeCapture = new Concur_Core_Props.Handler(React_DOM_Props.onRateChangeCapture);
var onRateChange = new Concur_Core_Props.Handler(React_DOM_Props.onRateChange);
var onProgressCapture = new Concur_Core_Props.Handler(React_DOM_Props.onProgressCapture);
var onProgress = new Concur_Core_Props.Handler(React_DOM_Props.onProgress);
var onPlayingCapture = new Concur_Core_Props.Handler(React_DOM_Props.onPlayingCapture);
var onPlaying = new Concur_Core_Props.Handler(React_DOM_Props.onPlaying);
var onPlayCapture = new Concur_Core_Props.Handler(React_DOM_Props.onPlayCapture);
var onPlay = new Concur_Core_Props.Handler(React_DOM_Props.onPlay);
var onPauseCapture = new Concur_Core_Props.Handler(React_DOM_Props.onPauseCapture);
var onPause = new Concur_Core_Props.Handler(React_DOM_Props.onPause);
var onPasteCapture = new Concur_Core_Props.Handler(React_DOM_Props.onPasteCapture);
var onPaste = new Concur_Core_Props.Handler(React_DOM_Props.onPaste);
var onMouseUpCapture = new Concur_Core_Props.Handler(React_DOM_Props.onMouseUpCapture);
var onMouseUp = new Concur_Core_Props.Handler(React_DOM_Props.onMouseUp);
var onMouseOverCapture = new Concur_Core_Props.Handler(React_DOM_Props.onMouseOverCapture);
var onMouseOver = new Concur_Core_Props.Handler(React_DOM_Props.onMouseOver);
var onMouseOutCapture = new Concur_Core_Props.Handler(React_DOM_Props.onMouseOutCapture);
var onMouseOut = new Concur_Core_Props.Handler(React_DOM_Props.onMouseOut);
var onMouseMoveCapture = new Concur_Core_Props.Handler(React_DOM_Props.onMouseMoveCapture);
var onMouseMove = new Concur_Core_Props.Handler(React_DOM_Props.onMouseMove);
var onMouseLeaveCapture = new Concur_Core_Props.Handler(React_DOM_Props.onMouseLeaveCapture);
var onMouseLeave = new Concur_Core_Props.Handler(React_DOM_Props.onMouseLeave);
var onMouseEnterCapture = new Concur_Core_Props.Handler(React_DOM_Props.onMouseEnterCapture);
var onMouseEnter = new Concur_Core_Props.Handler(React_DOM_Props.onMouseEnter);
var onMouseDownCapture = new Concur_Core_Props.Handler(React_DOM_Props.onMouseDownCapture);
var onMouseDown = new Concur_Core_Props.Handler(React_DOM_Props.onMouseDown);
var onLoadedMetadataCapture = new Concur_Core_Props.Handler(React_DOM_Props.onLoadedMetadataCapture);
var onLoadedMetadata = new Concur_Core_Props.Handler(React_DOM_Props.onLoadedMetadata);
var onLoadedDataCapture = new Concur_Core_Props.Handler(React_DOM_Props.onLoadedDataCapture);
var onLoadedData = new Concur_Core_Props.Handler(React_DOM_Props.onLoadedData);
var onLoadStartCapture = new Concur_Core_Props.Handler(React_DOM_Props.onLoadStartCapture);
var onLoadStart = new Concur_Core_Props.Handler(React_DOM_Props.onLoadStart);
var onLoadCapture = new Concur_Core_Props.Handler(React_DOM_Props.onLoadCapture);
var onLoad = new Concur_Core_Props.Handler(React_DOM_Props.onLoad);
var onKeyUpCapture = new Concur_Core_Props.Handler(React_DOM_Props.onKeyUpCapture);
var onKeyUp = new Concur_Core_Props.Handler(React_DOM_Props.onKeyUp);
var onKeyPressCapture = new Concur_Core_Props.Handler(React_DOM_Props.onKeyPressCapture);
var onKeyPress = new Concur_Core_Props.Handler(React_DOM_Props.onKeyPress);
var onKeyDownCapture = new Concur_Core_Props.Handler(React_DOM_Props.onKeyDownCapture);
var onKeyDown = new Concur_Core_Props.Handler(React_DOM_Props.onKeyDown);
var onInvalidCapture = new Concur_Core_Props.Handler(React_DOM_Props.onInvalidCapture);
var onInvalid = new Concur_Core_Props.Handler(React_DOM_Props.onInvalid);
var onInputCapture = new Concur_Core_Props.Handler(React_DOM_Props.onInputCapture);
var onInput = new Concur_Core_Props.Handler(React_DOM_Props.onInput);
var onFocusCapture = new Concur_Core_Props.Handler(React_DOM_Props.onFocusCapture);
var onFocus = new Concur_Core_Props.Handler(React_DOM_Props.onFocus);
var onErrorCapture = new Concur_Core_Props.Handler(React_DOM_Props.onErrorCapture);
var onError = new Concur_Core_Props.Handler(React_DOM_Props.onError);
var onEndedCapture = new Concur_Core_Props.Handler(React_DOM_Props.onEndedCapture);
var onEnded = new Concur_Core_Props.Handler(React_DOM_Props.onEnded);
var onEncryptedCapture = new Concur_Core_Props.Handler(React_DOM_Props.onEncryptedCapture);
var onEncrypted = new Concur_Core_Props.Handler(React_DOM_Props.onEncrypted);
var onEmptiedCapture = new Concur_Core_Props.Handler(React_DOM_Props.onEmptiedCapture);
var onEmptied = new Concur_Core_Props.Handler(React_DOM_Props.onEmptied);
var onDurationChangeCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDurationChangeCapture);
var onDurationChange = new Concur_Core_Props.Handler(React_DOM_Props.onDurationChange);
var onDropCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDropCapture);
var onDrop = new Concur_Core_Props.Handler(React_DOM_Props.onDrop);
var onDragStartCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDragStartCapture);
var onDragStart = new Concur_Core_Props.Handler(React_DOM_Props.onDragStart);
var onDragOverCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDragOverCapture);
var onDragOver = new Concur_Core_Props.Handler(React_DOM_Props.onDragOver);
var onDragLeaveCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDragLeaveCapture);
var onDragLeave = new Concur_Core_Props.Handler(React_DOM_Props.onDragLeave);
var onDragExitCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDragExitCapture);
var onDragExit = new Concur_Core_Props.Handler(React_DOM_Props.onDragExit);
var onDragEnterCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDragEnterCapture);
var onDragEnter = new Concur_Core_Props.Handler(React_DOM_Props.onDragEnter);
var onDragEndCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDragEndCapture);
var onDragEnd = new Concur_Core_Props.Handler(React_DOM_Props.onDragEnd);
var onDragCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDragCapture);
var onDrag = new Concur_Core_Props.Handler(React_DOM_Props.onDrag);
var onDoubleClickCapture = new Concur_Core_Props.Handler(React_DOM_Props.onDoubleClickCapture);
var onDoubleClick = new Concur_Core_Props.Handler(React_DOM_Props.onDoubleClick);
var onCutCapture = new Concur_Core_Props.Handler(React_DOM_Props.onCutCapture);
var onCut = new Concur_Core_Props.Handler(React_DOM_Props.onCut);
var onCopyCapture = new Concur_Core_Props.Handler(React_DOM_Props.onCopyCapture);
var onCopy = new Concur_Core_Props.Handler(React_DOM_Props.onCopy);
var onContextMenuCapture = new Concur_Core_Props.Handler(React_DOM_Props.onContextMenuCapture);
var onContextMenu = new Concur_Core_Props.Handler(React_DOM_Props.onContextMenu);
var onCompositionUpdateCapture = new Concur_Core_Props.Handler(React_DOM_Props.onCompositionUpdateCapture);
var onCompositionUpdate = new Concur_Core_Props.Handler(React_DOM_Props.onCompositionUpdate);
var onCompositionStartCapture = new Concur_Core_Props.Handler(React_DOM_Props.onCompositionStartCapture);
var onCompositionStart = new Concur_Core_Props.Handler(React_DOM_Props.onCompositionStart);
var onCompositionEndCapture = new Concur_Core_Props.Handler(React_DOM_Props.onCompositionEndCapture);
var onCompositionEnd = new Concur_Core_Props.Handler(React_DOM_Props.onCompositionEnd);
var onClickCapture = new Concur_Core_Props.Handler(React_DOM_Props.onClickCapture);
var onClick = new Concur_Core_Props.Handler(React_DOM_Props.onClick);
var onChangeCapture = new Concur_Core_Props.Handler(React_DOM_Props.onChangeCapture);
var onChange = new Concur_Core_Props.Handler(React_DOM_Props.onChange);
var onCanPlayThroughCapture = new Concur_Core_Props.Handler(React_DOM_Props.onCanPlayThroughCapture);
var onCanPlayThrough = new Concur_Core_Props.Handler(React_DOM_Props.onCanPlayThrough);
var onCanPlayCapture = new Concur_Core_Props.Handler(React_DOM_Props.onCanPlayCapture);
var onCanPlay = new Concur_Core_Props.Handler(React_DOM_Props.onCanPlay);
var onBlurCapture = new Concur_Core_Props.Handler(React_DOM_Props.onBlurCapture);
var onBlur = new Concur_Core_Props.Handler(React_DOM_Props.onBlur);
var onAnimationStartCapture = new Concur_Core_Props.Handler(React_DOM_Props.onAnimationStartCapture);
var onAnimationStart = new Concur_Core_Props.Handler(React_DOM_Props.onAnimationStart);
var onAnimationIterationCapture = new Concur_Core_Props.Handler(React_DOM_Props.onAnimationIterationCapture);
var onAnimationIteration = new Concur_Core_Props.Handler(React_DOM_Props.onAnimationIteration);
var onAnimationEndCapture = new Concur_Core_Props.Handler(React_DOM_Props.onAnimationEndCapture);
var onAnimationEnd = new Concur_Core_Props.Handler(React_DOM_Props.onAnimationEnd);
var onAbortCapture = new Concur_Core_Props.Handler(React_DOM_Props.onAbortCapture);
var onAbort = new Concur_Core_Props.Handler(React_DOM_Props.onAbort);
var nonce = function ($61) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.nonce($61));
};
var noValidate = function ($62) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.noValidate($62));
};
var name = function ($63) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.name($63));
};
var muted = function ($64) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.muted($64));
};
var multiple = function ($65) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.multiple($65));
};
var minLength = function ($66) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.minLength($66));
};
var min = function ($67) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.min($67));
};
var method = function ($68) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.method($68));
};
var mediaGroup = function ($69) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.mediaGroup($69));
};
var media = function ($70) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.media($70));
};
var maxLength = function ($71) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.maxLength($71));
};
var max = function ($72) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.max($72));
};
var marginWidth = function ($73) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.marginWidth($73));
};
var marginHeight = function ($74) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.marginHeight($74));
};
var manifest = function ($75) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.manifest($75));
};
var low = function ($76) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.low($76));
};
var loop = function ($77) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.loop($77));
};
var list = function ($78) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.list($78));
};
var lang = function ($79) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.lang($79));
};
var label = function ($80) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.label($80));
};
var kind = function ($81) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.kind($81));
};
var keyType = function ($82) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.keyType($82));
};
var keyParams = function ($83) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.keyParams($83));
};
var key = function ($84) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.key($84));
};
var itemType = function ($85) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemType($85));
};
var itemScope = function ($86) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemScope($86));
};
var itemRef = function ($87) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemRef($87));
};
var itemProp = function ($88) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemProp($88));
};
var itemID = function ($89) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.itemID($89));
};
var isEnterEvent = function (e) {
    return e.which === 13 || e.keyCode === 13;
};
var onKeyEnter = Concur_Core_Props.filterProp(isEnterEvent)(onKeyDown);
var is = function ($90) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.is($90));
};
var integrity = function ($91) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.integrity($91));
};
var inputMode = function ($92) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.inputMode($92));
};
var inlist = function ($93) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.inlist($93));
};
var icon = function ($94) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.icon($94));
};
var httpEquiv = function ($95) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.httpEquiv($95));
};
var htmlFor = function ($96) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.htmlFor($96));
};
var hrefLang = function ($97) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.hrefLang($97));
};
var href = function ($98) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.href($98));
};
var high = function ($99) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.high($99));
};
var hidden = function ($100) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.hidden($100));
};
var height = function ($101) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.height($101));
};
var headers = function ($102) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.headers($102));
};
var frameBorder = function ($103) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.frameBorder($103));
};
var formTarget = function ($104) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formTarget($104));
};
var formNoValidate = function ($105) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formNoValidate($105));
};
var formMethod = function ($106) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formMethod($106));
};
var formEncType = function ($107) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formEncType($107));
};
var formAction = function ($108) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.formAction($108));
};
var form = function ($109) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.form($109));
};
var fillOpacity = function ($110) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.fillOpacity($110));
};
var fill = function ($111) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.fill($111));
};
var encType = function ($112) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.encType($112));
};
var emptyProp = new Concur_Core_Props.PrimProp($foreign.emptyProp_);
var draggable = function ($113) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.draggable($113));
};
var download = function ($114) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.download($114));
};
var disabled = function ($115) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.disabled($115));
};
var dir = function ($116) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.dir($116));
};
var defer = function ($117) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.defer($117));
};
var defaultValue = function ($118) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.defaultValue($118));
};
var defaultChecked = function ($119) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.defaultChecked($119));
};
var $$default = function ($120) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["default"]($120));
};
var dateTime = function ($121) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.dateTime($121));
};
var datatype = function ($122) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.datatype($122));
};
var dangerouslySetInnerHTML = function ($123) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.dangerouslySetInnerHTML($123));
};
var d = function ($124) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.d($124));
};
var cy = function ($125) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cy($125));
};
var cx = function ($126) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cx($126));
};
var crossOrigin = function ($127) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.crossOrigin($127));
};
var coords = function ($128) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.coords($128));
};
var controls = function ($129) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.controls($129));
};
var contextMenu = function ($130) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.contextMenu($130));
};
var contentEditable = function ($131) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.contentEditable($131));
};
var content = function ($132) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.content($132));
};
var cols = function ($133) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cols($133));
};
var color = function ($134) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.color($134));
};
var colSpan = function ($135) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.colSpan($135));
};
var className = function ($136) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.className($136));
};
var classList = (function () {
    var $137 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ");
    var $138 = Data_Array.concatMap(Data_Maybe.maybe([  ])(function (s) {
        return [ s ];
    }));
    return function ($139) {
        return className($137($138($139)));
    };
})();
var classID = function ($140) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.classID($140));
};
var cite = function ($141) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cite($141));
};
var checked = function ($142) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.checked($142));
};
var charSet = function ($143) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.charSet($143));
};
var challenge = function ($144) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.challenge($144));
};
var cellSpacing = function ($145) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cellSpacing($145));
};
var cellPadding = function ($146) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.cellPadding($146));
};
var capture = function ($147) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.capture($147));
};
var autoSave = function ($148) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoSave($148));
};
var autoPlay = function ($149) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoPlay($149));
};
var autoFocus = function ($150) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoFocus($150));
};
var autoCorrect = function ($151) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoCorrect($151));
};
var autoComplete = function ($152) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoComplete($152));
};
var autoCapitalize = function ($153) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.autoCapitalize($153));
};
var async = function ($154) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.async($154));
};
var aria = function ($155) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.aria($155));
};
var alt = function ($156) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.alt($156));
};
var allowTransparency = function ($157) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.allowTransparency($157));
};
var allowFullScreen = function ($158) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.allowFullScreen($158));
};
var action = function ($159) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.action($159));
};
var accessKey = function ($160) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.accessKey($160));
};
var acceptCharset = function ($161) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.acceptCharset($161));
};
var accept = function ($162) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.accept($162));
};
var about = function ($163) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props.about($163));
};
var _type = function ($164) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["_type"]($164));
};
var _id = function ($165) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["_id"]($165));
};
var _data = function ($166) {
    return Concur_Core_Props.PrimProp.create(React_DOM_Props["_data"]($166));
};
module.exports = {
    emptyProp: emptyProp,
    unsafeMkPropHandler: unsafeMkPropHandler,
    unsafeMkProp: unsafeMkProp,
    classList: classList,
    unsafeTargetValue: unsafeTargetValue,
    isEnterEvent: isEnterEvent,
    aria: aria,
    "_data": _data,
    style: style,
    dangerouslySetInnerHTML: dangerouslySetInnerHTML,
    accept: accept,
    acceptCharset: acceptCharset,
    accessKey: accessKey,
    action: action,
    allowFullScreen: allowFullScreen,
    allowTransparency: allowTransparency,
    alt: alt,
    async: async,
    autoComplete: autoComplete,
    autoFocus: autoFocus,
    autoPlay: autoPlay,
    capture: capture,
    cellPadding: cellPadding,
    cellSpacing: cellSpacing,
    charSet: charSet,
    challenge: challenge,
    checked: checked,
    cite: cite,
    classID: classID,
    className: className,
    cols: cols,
    colSpan: colSpan,
    content: content,
    contentEditable: contentEditable,
    contextMenu: contextMenu,
    controls: controls,
    coords: coords,
    crossOrigin: crossOrigin,
    dateTime: dateTime,
    "default": $$default,
    defaultChecked: defaultChecked,
    defaultValue: defaultValue,
    defer: defer,
    dir: dir,
    disabled: disabled,
    download: download,
    draggable: draggable,
    encType: encType,
    form: form,
    formAction: formAction,
    formEncType: formEncType,
    formMethod: formMethod,
    formNoValidate: formNoValidate,
    formTarget: formTarget,
    frameBorder: frameBorder,
    headers: headers,
    height: height,
    hidden: hidden,
    high: high,
    href: href,
    hrefLang: hrefLang,
    htmlFor: htmlFor,
    httpEquiv: httpEquiv,
    icon: icon,
    "_id": _id,
    inputMode: inputMode,
    integrity: integrity,
    is: is,
    key: key,
    keyParams: keyParams,
    keyType: keyType,
    kind: kind,
    label: label,
    lang: lang,
    list: list,
    loop: loop,
    low: low,
    manifest: manifest,
    marginHeight: marginHeight,
    marginWidth: marginWidth,
    max: max,
    maxLength: maxLength,
    media: media,
    mediaGroup: mediaGroup,
    method: method,
    min: min,
    minLength: minLength,
    multiple: multiple,
    muted: muted,
    name: name,
    nonce: nonce,
    noValidate: noValidate,
    open: open,
    optimum: optimum,
    pattern: pattern,
    placeholder: placeholder,
    poster: poster,
    preload: preload,
    profile: profile,
    radioGroup: radioGroup,
    readOnly: readOnly,
    rel: rel,
    required: required,
    reversed: reversed,
    role: role,
    rows: rows,
    rowSpan: rowSpan,
    sandbox: sandbox,
    scope: scope,
    scoped: scoped,
    scrolling: scrolling,
    seamless: seamless,
    selected: selected,
    shape: shape,
    size: size,
    sizes: sizes,
    span: span,
    spellCheck: spellCheck,
    src: src,
    srcDoc: srcDoc,
    srcLang: srcLang,
    srcSet: srcSet,
    start: start,
    step: step,
    summary: summary,
    tabIndex: tabIndex,
    target: target,
    title: title,
    "_type": _type,
    useMap: useMap,
    value: value,
    valueArray: valueArray,
    width: width,
    wmode: wmode,
    wrap: wrap,
    about: about,
    datatype: datatype,
    inlist: inlist,
    prefix: prefix,
    property: property,
    resource: resource,
    "typeof": $$typeof,
    vocab: vocab,
    autoCapitalize: autoCapitalize,
    autoCorrect: autoCorrect,
    autoSave: autoSave,
    color: color,
    itemProp: itemProp,
    itemScope: itemScope,
    itemType: itemType,
    itemID: itemID,
    itemRef: itemRef,
    results: results,
    security: security,
    unselectable: unselectable,
    onAnimationStart: onAnimationStart,
    onAnimationEnd: onAnimationEnd,
    onAnimationIteration: onAnimationIteration,
    onTransitionEnd: onTransitionEnd,
    onToggle: onToggle,
    onError: onError,
    onLoad: onLoad,
    onAbort: onAbort,
    onCanPlay: onCanPlay,
    onCanPlayThrough: onCanPlayThrough,
    onDurationChange: onDurationChange,
    onEmptied: onEmptied,
    onEncrypted: onEncrypted,
    onEnded: onEnded,
    onLoadedData: onLoadedData,
    onLoadedMetadata: onLoadedMetadata,
    onLoadStart: onLoadStart,
    onPause: onPause,
    onPlay: onPlay,
    onPlaying: onPlaying,
    onProgress: onProgress,
    onRateChange: onRateChange,
    onSeeked: onSeeked,
    onSeeking: onSeeking,
    onStalled: onStalled,
    onSuspend: onSuspend,
    onTimeUpdate: onTimeUpdate,
    onVolumeChange: onVolumeChange,
    onWaiting: onWaiting,
    onCopy: onCopy,
    onCut: onCut,
    onPaste: onPaste,
    onCompositionEnd: onCompositionEnd,
    onCompositionStart: onCompositionStart,
    onCompositionUpdate: onCompositionUpdate,
    onKeyDown: onKeyDown,
    onKeyPress: onKeyPress,
    onKeyUp: onKeyUp,
    onKeyEnter: onKeyEnter,
    onFocus: onFocus,
    onBlur: onBlur,
    onChange: onChange,
    onInput: onInput,
    onInvalid: onInvalid,
    onSubmit: onSubmit,
    onClick: onClick,
    onContextMenu: onContextMenu,
    onDoubleClick: onDoubleClick,
    onDrag: onDrag,
    onDragEnd: onDragEnd,
    onDragEnter: onDragEnter,
    onDragExit: onDragExit,
    onDragLeave: onDragLeave,
    onDragOver: onDragOver,
    onDragStart: onDragStart,
    onDrop: onDrop,
    onMouseDown: onMouseDown,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    onMouseMove: onMouseMove,
    onMouseOut: onMouseOut,
    onMouseOver: onMouseOver,
    onMouseUp: onMouseUp,
    onSelect: onSelect,
    onTouchCancel: onTouchCancel,
    onTouchEnd: onTouchEnd,
    onTouchMove: onTouchMove,
    onTouchStart: onTouchStart,
    onScroll: onScroll,
    onWheel: onWheel,
    onAnimationStartCapture: onAnimationStartCapture,
    onAnimationEndCapture: onAnimationEndCapture,
    onAnimationIterationCapture: onAnimationIterationCapture,
    onTransitionEndCapture: onTransitionEndCapture,
    onToggleCapture: onToggleCapture,
    onErrorCapture: onErrorCapture,
    onLoadCapture: onLoadCapture,
    onAbortCapture: onAbortCapture,
    onCanPlayCapture: onCanPlayCapture,
    onCanPlayThroughCapture: onCanPlayThroughCapture,
    onDurationChangeCapture: onDurationChangeCapture,
    onEmptiedCapture: onEmptiedCapture,
    onEncryptedCapture: onEncryptedCapture,
    onEndedCapture: onEndedCapture,
    onLoadedDataCapture: onLoadedDataCapture,
    onLoadedMetadataCapture: onLoadedMetadataCapture,
    onLoadStartCapture: onLoadStartCapture,
    onPauseCapture: onPauseCapture,
    onPlayCapture: onPlayCapture,
    onPlayingCapture: onPlayingCapture,
    onProgressCapture: onProgressCapture,
    onRateChangeCapture: onRateChangeCapture,
    onSeekedCapture: onSeekedCapture,
    onSeekingCapture: onSeekingCapture,
    onStalledCapture: onStalledCapture,
    onSuspendCapture: onSuspendCapture,
    onTimeUpdateCapture: onTimeUpdateCapture,
    onVolumeChangeCapture: onVolumeChangeCapture,
    onWaitingCapture: onWaitingCapture,
    onCopyCapture: onCopyCapture,
    onCutCapture: onCutCapture,
    onPasteCapture: onPasteCapture,
    onCompositionEndCapture: onCompositionEndCapture,
    onCompositionStartCapture: onCompositionStartCapture,
    onCompositionUpdateCapture: onCompositionUpdateCapture,
    onKeyDownCapture: onKeyDownCapture,
    onKeyPressCapture: onKeyPressCapture,
    onKeyUpCapture: onKeyUpCapture,
    onFocusCapture: onFocusCapture,
    onBlurCapture: onBlurCapture,
    onChangeCapture: onChangeCapture,
    onInputCapture: onInputCapture,
    onInvalidCapture: onInvalidCapture,
    onSubmitCapture: onSubmitCapture,
    onClickCapture: onClickCapture,
    onContextMenuCapture: onContextMenuCapture,
    onDoubleClickCapture: onDoubleClickCapture,
    onDragCapture: onDragCapture,
    onDragEndCapture: onDragEndCapture,
    onDragEnterCapture: onDragEnterCapture,
    onDragExitCapture: onDragExitCapture,
    onDragLeaveCapture: onDragLeaveCapture,
    onDragOverCapture: onDragOverCapture,
    onDragStartCapture: onDragStartCapture,
    onDropCapture: onDropCapture,
    onMouseDownCapture: onMouseDownCapture,
    onMouseEnterCapture: onMouseEnterCapture,
    onMouseLeaveCapture: onMouseLeaveCapture,
    onMouseMoveCapture: onMouseMoveCapture,
    onMouseOutCapture: onMouseOutCapture,
    onMouseOverCapture: onMouseOverCapture,
    onMouseUpCapture: onMouseUpCapture,
    onSelectCapture: onSelectCapture,
    onTouchCancelCapture: onTouchCancelCapture,
    onTouchEndCapture: onTouchEndCapture,
    onTouchMoveCapture: onTouchMoveCapture,
    onTouchStartCapture: onTouchStartCapture,
    onScrollCapture: onScrollCapture,
    onWheelCapture: onWheelCapture,
    ref: ref,
    suppressContentEditableWarning: suppressContentEditableWarning,
    x: x,
    y: y,
    cx: cx,
    cy: cy,
    r: r,
    fill: fill,
    opacity: opacity,
    fillOpacity: fillOpacity,
    stroke: stroke,
    strokeWidth: strokeWidth,
    points: points,
    d: d,
    viewBox: viewBox,
    emptyProp_: $foreign.emptyProp_,
    resetTargetValue: $foreign.resetTargetValue
};
