// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Data_ArrayBuffer_Typed = require("../Data.ArrayBuffer.Typed/index.js");
var createUint8Buffer = function (len) {
    return Data_ArrayBuffer_Typed.empty(Data_ArrayBuffer_Typed.typedArrayUint8)(len);
};
var createFloat32Buffer = function (len) {
    return Data_ArrayBuffer_Typed.empty(Data_ArrayBuffer_Typed.typedArrayFloat32)(len);
};
module.exports = {
    createUint8Buffer: createUint8Buffer,
    createFloat32Buffer: createFloat32Buffer,
    unsafeGetProp: $foreign.unsafeGetProp,
    unsafeSetProp: $foreign.unsafeSetProp
};
