// Generated by purs version 0.13.6
"use strict";
var Concur_Core_Types = require("../Concur.Core.Types/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Free = require("../Control.Monad.Free/index.js");
var Control_Parallel_Class = require("../Control.Parallel.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Effect = require("../Effect/index.js");
var Effect_AVar = require("../Effect.AVar/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_AVar = require("../Effect.Aff.AVar/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var mkNodeWidget$prime = function (mkView) {
    return function (w) {
        var v = Concur_Core_Types.resume(Concur_Core_Types.functorWidgetStep)(w);
        if (v instanceof Data_Either.Left) {
            return Control_Applicative.pure(Control_Monad_Free.freeApplicative)(v.value0);
        };
        if (v instanceof Data_Either.Right) {
            if (v.value0 instanceof Data_Either.Left) {
                return Control_Monad_Free.wrap(Concur_Core_Types.WidgetStep(new Data_Either.Left(function __do() {
                    var w$prime = v.value0.value0();
                    return mkNodeWidget$prime(mkView)(w$prime);
                })));
            };
            if (v.value0 instanceof Data_Either.Right) {
                return Control_Monad_Free.wrap(Concur_Core_Types.WidgetStep(new Data_Either.Left(function __do() {
                    var $$var = Effect_AVar.empty();
                    var eventHandler = function (a) {
                        return Data_Functor["void"](Effect.functorEffect)(Effect_AVar.tryPut(Control_Applicative.pure(Control_Monad_Free.freeApplicative)(a))($$var));
                    };
                    var cont$prime = Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Control_Alt.alt(Effect_Aff.altParAff)(Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Effect_Aff_Class.liftAff(Effect_Aff_Class.monadAffAff)(Effect_Aff_AVar.take($$var))))(Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Data_Functor.map(Effect_Aff.functorAff)(mkNodeWidget$prime(mkView))(v.value0.value0.cont))));
                    return Control_Monad_Free.wrap(Concur_Core_Types.WidgetStep(new Data_Either.Right({
                        view: mkView(eventHandler)(v.value0.value0.view),
                        cont: cont$prime
                    })));
                })));
            };
            throw new Error("Failed pattern match at Concur.Core (line 36, column 28 - line 49, column 10): " + [ v.value0.constructor.name ]);
        };
        throw new Error("Failed pattern match at Concur.Core (line 34, column 26 - line 49, column 10): " + [ v.constructor.name ]);
    };
};
var mkNodeWidget = function (mkView) {
    return function (v) {
        return mkNodeWidget$prime(mkView)(v);
    };
};
var mkLeafWidget = function (mkView) {
    return Concur_Core_Types.Widget(Control_Monad_Free.wrap(Concur_Core_Types.WidgetStep(new Data_Either.Left(function __do() {
        var $$var = Effect_AVar.empty();
        return Control_Monad_Free.wrap(Concur_Core_Types.WidgetStep(new Data_Either.Right({
            view: mkView(function (a) {
                return Data_Functor["void"](Effect.functorEffect)(Effect_AVar.tryPut(Control_Applicative.pure(Control_Monad_Free.freeApplicative)(a))($$var));
            }),
            cont: Effect_Aff_Class.liftAff(Effect_Aff_Class.monadAffAff)(Effect_Aff_AVar.take($$var))
        })));
    }))));
};
module.exports = {
    mkLeafWidget: mkLeafWidget,
    mkNodeWidget: mkNodeWidget,
    "mkNodeWidget'": mkNodeWidget$prime
};
