// Generated by purs version 0.13.6
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_RWS_Trans = require("../Control.Monad.RWS.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var ShiftMap = function (shiftMap) {
    this.shiftMap = shiftMap;
};
var writerShiftMap = function (dictMonoid) {
    return new ShiftMap(function (f) {
        return function (v) {
            return f(function (a) {
                return new Data_Tuple.Tuple(a, Data_Monoid.mempty(dictMonoid));
            })(v);
        };
    });
};
var stateShiftMap = function (dictMonad) {
    return new ShiftMap(function (f) {
        return function (v) {
            return function (s) {
                return f(function (a) {
                    return new Data_Tuple.Tuple(a, s);
                })(v(s));
            };
        };
    });
};
var shiftMap = function (dict) {
    return dict.shiftMap;
};
var rwsShiftMap = function (dictMonoid) {
    return new ShiftMap(function (f) {
        return function (v) {
            return function (r) {
                return function (s) {
                    return f(function (a) {
                        return new Control_Monad_RWS_Trans.RWSResult(s, a, Data_Monoid.mempty(dictMonoid));
                    })(v(r)(s));
                };
            };
        };
    });
};
var readerShiftMap = new ShiftMap(function (f) {
    return function (v) {
        return function (r) {
            return f(Control_Category.identity(Control_Category.categoryFn))(v(r));
        };
    };
});
var exceptShiftMap = new ShiftMap(function (f) {
    return function (v) {
        return f(Data_Either.Right.create)(v);
    };
});
module.exports = {
    shiftMap: shiftMap,
    ShiftMap: ShiftMap,
    exceptShiftMap: exceptShiftMap,
    rwsShiftMap: rwsShiftMap,
    readerShiftMap: readerShiftMap,
    stateShiftMap: stateShiftMap,
    writerShiftMap: writerShiftMap
};
