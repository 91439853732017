// Generated by purs version 0.13.6
"use strict";
var Affjax = require("../Affjax/index.js");
var Affjax_ResponseFormat = require("../Affjax.ResponseFormat/index.js");
var Assets = require("../Assets/index.js");
var Audio_WebAudio_AudioBufferSourceNode = require("../Audio.WebAudio.AudioBufferSourceNode/index.js");
var Audio_WebAudio_BaseAudioContext = require("../Audio.WebAudio.BaseAudioContext/index.js");
var Audio_WebAudio_Types = require("../Audio.WebAudio.Types/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Parallel_Class = require("../Control.Parallel.Class/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HTTP_Method = require("../Data.HTTP.Method/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Sound = (function () {
    function Sound(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Sound.create = function (value0) {
        return function (value1) {
            return new Sound(value0, value1);
        };
    };
    return Sound;
})();
var Tada = (function () {
    function Tada() {

    };
    Tada.value = new Tada();
    return Tada;
})();
var Nope = (function () {
    function Nope() {

    };
    Nope.value = new Nope();
    return Nope;
})();
var Quack = (function () {
    function Quack() {

    };
    Quack.value = new Quack();
    return Quack;
})();
var Xylophone = (function () {
    function Xylophone() {

    };
    Xylophone.value = new Xylophone();
    return Xylophone;
})();
var soundTypesShow = new Data_Show.Show(function (v) {
    if (v instanceof Tada) {
        return "Tada";
    };
    if (v instanceof Nope) {
        return "Nope";
    };
    if (v instanceof Quack) {
        return "Quack";
    };
    if (v instanceof Xylophone) {
        return "Xylophone";
    };
    throw new Error("Failed pattern match at Sounds (line 43, column 1 - line 47, column 31): " + [ v.constructor.name ]);
});
var soundTypesEq = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Tada && y instanceof Tada) {
            return true;
        };
        if (x instanceof Nope && y instanceof Nope) {
            return true;
        };
        if (x instanceof Quack && y instanceof Quack) {
            return true;
        };
        if (x instanceof Xylophone && y instanceof Xylophone) {
            return true;
        };
        return false;
    };
});
var soundTypesOrd = new Data_Ord.Ord(function () {
    return soundTypesEq;
}, function (x) {
    return function (y) {
        if (x instanceof Tada && y instanceof Tada) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Tada) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Tada) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Nope && y instanceof Nope) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Nope) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Nope) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Quack && y instanceof Quack) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Quack) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Quack) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Xylophone && y instanceof Xylophone) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Sounds (line 41, column 1 - line 41, column 41): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var play = function (k) {
    var play$prime = function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
        if (v instanceof Data_Maybe.Just) {
            return function __do() {
                var startTime = Audio_WebAudio_BaseAudioContext.currentTime(v.value0.value0)();
                var src = Audio_WebAudio_BaseAudioContext.createBufferSource(v.value0.value0)();
                var dst = Audio_WebAudio_BaseAudioContext.destination(v.value0.value0)();
                Audio_WebAudio_Types.connect(Audio_WebAudio_Types.connectableDestinationNode)()(src)(dst)();
                Audio_WebAudio_AudioBufferSourceNode.setBuffer(v.value0.value1)(src)();
                return Audio_WebAudio_AudioBufferSourceNode.startBufferSource(Audio_WebAudio_AudioBufferSourceNode.defaultStartOptions)(src)();
            };
        };
        throw new Error("Failed pattern match at Sounds (line 88, column 3 - line 88, column 38): " + [ v.constructor.name ]);
    };
    var $26 = Data_Map_Internal.lookup(soundTypesOrd)(k);
    return function ($27) {
        return play$prime($26($27));
    };
};
var loadSoundBuffer = function (ctx) {
    return function (filename) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Affjax.request({
            method: new Data_Either.Left(Data_HTTP_Method.GET.value),
            url: filename,
            headers: Affjax.defaultRequest.headers,
            content: Affjax.defaultRequest.content,
            username: Affjax.defaultRequest.username,
            password: Affjax.defaultRequest.password,
            withCredentials: Affjax.defaultRequest.withCredentials,
            responseFormat: Affjax_ResponseFormat.arrayBuffer
        }))(function (res) {
            if (res instanceof Data_Either.Left) {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Exception["throw"](Affjax.printError(res.value0)));
            };
            if (res instanceof Data_Either.Right) {
                return Audio_WebAudio_BaseAudioContext.decodeAudioDataAsync(ctx)(res.value0.body);
            };
            throw new Error("Failed pattern match at Sounds (line 108, column 3 - line 110, column 55): " + [ res.constructor.name ]);
        });
    };
};
var load = Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Audio_WebAudio_BaseAudioContext.newAudioContext))(function (ctx) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Data_Traversable.traverse(Data_Traversable.traversableArray)(Effect_Aff.applicativeParAff)(function (v) {
        return Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Data_Functor.map(Effect_Aff.functorAff)((function () {
            var $28 = Data_Tuple.Tuple.create(v.value0);
            var $29 = Sound.create(ctx);
            return function ($30) {
                return $28($29($30));
            };
        })())(loadSoundBuffer(ctx)(Assets["for"](v.value1))));
    })([ new Data_Tuple.Tuple(Tada.value, Assets.tada), new Data_Tuple.Tuple(Nope.value, Assets.nope), new Data_Tuple.Tuple(Quack.value, Assets.quackSound), new Data_Tuple.Tuple(Xylophone.value, Assets.xylophoneSound) ])))(function (sounds) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Map_Internal.fromFoldable(soundTypesOrd)(Data_Foldable.foldableArray)(sounds));
    });
});
var encodeJsonKey = new Data_Argonaut_Encode_Class.EncodeJson((function () {
    var $31 = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonJString);
    var $32 = Data_Show.show(soundTypesShow);
    return function ($33) {
        return $31($32($33));
    };
})());
var def = Data_Map_Internal.empty;
var decodeJsonKey = new Data_Argonaut_Decode_Class.DecodeJson((function () {
    var $34 = Data_Argonaut_Core.caseJsonString(Tada.value)(function (v) {
        if (v === "Tada") {
            return Tada.value;
        };
        if (v === "Nope") {
            return Nope.value;
        };
        if (v === "Quack") {
            return Quack.value;
        };
        return Xylophone.value;
    });
    return function ($35) {
        return Data_Either.Right.create($34($35));
    };
})());
module.exports = {
    load: load,
    play: play,
    def: def,
    Tada: Tada,
    Nope: Nope,
    Quack: Quack,
    Xylophone: Xylophone,
    soundTypesEq: soundTypesEq,
    soundTypesOrd: soundTypesOrd,
    soundTypesShow: soundTypesShow,
    decodeJsonKey: decodeJsonKey,
    encodeJsonKey: encodeJsonKey
};
