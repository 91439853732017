// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Effect = require("../Effect/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var TypedArray = function (BinaryValue0, create) {
    this.BinaryValue0 = BinaryValue0;
    this.create = create;
};
var unsafeAt = function (dictTypedArray) {
    return function (dictPartial) {
        return function (a) {
            return function (o) {
                return function () {
                    return $foreign.unsafeAtImpl(a, o);
                };
            };
        };
    };
};
var typedArrayUint8Clamped = new TypedArray(function () {
    return undefined;
}, $foreign.newUint8ClampedArray);
var typedArrayUint8 = new TypedArray(function () {
    return undefined;
}, $foreign.newUint8Array);
var typedArrayUint32 = new TypedArray(function () {
    return undefined;
}, $foreign.newUint32Array);
var typedArrayUint16 = new TypedArray(function () {
    return undefined;
}, $foreign.newUint16Array);
var typedArrayInt8 = new TypedArray(function () {
    return undefined;
}, $foreign.newInt8Array);
var typedArrayInt32 = new TypedArray(function () {
    return undefined;
}, $foreign.newInt32Array);
var typedArrayInt16 = new TypedArray(function () {
    return undefined;
}, $foreign.newInt16Array);
var typedArrayFloat64 = new TypedArray(function () {
    return undefined;
}, $foreign.newFloat64Array);
var typedArrayFloat32 = new TypedArray(function () {
    return undefined;
}, $foreign.newFloat32Array);
var traverseWithIndex_$prime = function (dictTypedArray) {
    return function (f) {
        return function (a) {
            return function () {
                return $foreign.forEachImpl(a, Effect_Uncurried.mkEffectFn2(f));
            };
        };
    };
};
var traverseWithIndex_ = function (dictTypedArray) {
    var $66 = traverseWithIndex_$prime(dictTypedArray);
    return function ($67) {
        return $66(Data_Function.flip($67));
    };
};
var traverseWithIndex$prime = function (dictTypedArray) {
    return function (f) {
        return function (a) {
            return function () {
                return $foreign.mapImpl(a, Effect_Uncurried.mkEffectFn2(f));
            };
        };
    };
};
var traverseWithIndex = function (dictTypedArray) {
    var $68 = traverseWithIndex$prime(dictTypedArray);
    return function ($69) {
        return $68(Data_Function.flip($69));
    };
};
var toString = function (a) {
    return function () {
        return $foreign.toStringImpl(a);
    };
};
var toArray = function (dictTypedArray) {
    return function (a) {
        return function () {
            return $foreign.toArrayImpl(a);
        };
    };
};
var subArray = function (s) {
    return function (e) {
        return function (a) {
            return $foreign.subArrayImpl(a, s, e);
        };
    };
};
var sort = function (a) {
    return function () {
        return $foreign.sortImpl(a);
    };
};
var some = function (dictTypedArray) {
    return function (p) {
        return function (a) {
            return function () {
                return $foreign.someImpl(a, Data_Function_Uncurried.mkFn2(p));
            };
        };
    };
};
var slice = function (s) {
    return function (e) {
        return function (a) {
            return function () {
                return $foreign.sliceImpl(a, s, e);
            };
        };
    };
};
var reverse = function (a) {
    return function () {
        return $foreign.reverseImpl(a);
    };
};
var reduceRight1 = function (dictPartial) {
    return function (dictTypedArray) {
        return function (f) {
            return function (a) {
                return function () {
                    return $foreign.reduceRight1Impl(a, function (acc, x, o) {
                        return f(x)(acc)(o)();
                    });
                };
            };
        };
    };
};
var reduceRight = function (dictTypedArray) {
    return function (f) {
        return function (i) {
            return function (a) {
                return function () {
                    return $foreign.reduceRightImpl(a, function (acc, x, o) {
                        return f(x)(acc)(o)();
                    }, i);
                };
            };
        };
    };
};
var reduce1 = function (dictPartial) {
    return function (dictTypedArray) {
        return function (f) {
            return function (a) {
                return function () {
                    return $foreign.reduce1Impl(a, Effect_Uncurried.mkEffectFn3(f));
                };
            };
        };
    };
};
var reduce = function (dictTypedArray) {
    return function (f) {
        return function (i) {
            return function (a) {
                return function () {
                    return $foreign.reduceImpl(a, Effect_Uncurried.mkEffectFn3(f), i);
                };
            };
        };
    };
};
var mapWithIndex$prime = function (dictTypedArray) {
    return function (f) {
        return function (a) {
            return Effect_Unsafe.unsafePerformEffect(function () {
                return $foreign.mapImpl(a, function (x, o) {
                    return f(x)(o);
                });
            });
        };
    };
};
var mapWithIndex = function (dictTypedArray) {
    var $70 = mapWithIndex$prime(dictTypedArray);
    return function ($71) {
        return $70(Data_Function.flip($71));
    };
};
var length = $foreign.lengthImpl;
var setInternal = function (lenfn) {
    return function (a) {
        return function (mo) {
            return function (b) {
                var o = Data_Maybe.fromMaybe(0)(mo);
                var $65 = o >= 0 && lenfn(b) <= (length(a) - o | 0);
                if ($65) {
                    return Control_Apply.applySecond(Effect.applyEffect)(function () {
                        return $foreign.setImpl(a, o, b);
                    })(Control_Applicative.pure(Effect.applicativeEffect)(true));
                };
                return Control_Applicative.pure(Effect.applicativeEffect)(false);
            };
        };
    };
};
var set = function (dictTypedArray) {
    return setInternal(Data_Array.length);
};
var setTyped = setInternal(length);
var lastIndexOf = function (dictTypedArray) {
    return function (x) {
        return function (mo) {
            return function (a) {
                return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(function () {
                    return $foreign.lastIndexOfImpl(a, x, Data_Nullable.toNullable(mo));
                });
            };
        };
    };
};
var join = function (s) {
    return function (a) {
        return function () {
            return $foreign.joinImpl(a, s);
        };
    };
};
var indexOf = function (dictTypedArray) {
    return function (x) {
        return function (mo) {
            return function (a) {
                return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(function () {
                    return $foreign.indexOfImpl(a, x, Data_Nullable.toNullable(mo));
                });
            };
        };
    };
};
var hasIndex = function (a) {
    return function (o) {
        return $foreign.hasIndexImpl(a, o);
    };
};
var foldrWithIndex = function (dictTypedArray) {
    return function (f) {
        return reduceRight(dictTypedArray)(function (a) {
            return function (x) {
                return function (o) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(f(o)(a)(x));
                };
            };
        });
    };
};
var foldr1 = function (dictPartial) {
    return function (dictTypedArray) {
        return function (f) {
            return reduceRight1()(dictTypedArray)(function (x) {
                return function (a) {
                    return function (v) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(f(a)(x));
                    };
                };
            });
        };
    };
};
var foldr = function (dictTypedArray) {
    return function (f) {
        return reduceRight(dictTypedArray)(function (a) {
            return function (x) {
                return function (v) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(f(a)(x));
                };
            };
        });
    };
};
var foldlWithIndex = function (dictTypedArray) {
    return function (f) {
        return reduce(dictTypedArray)(function (a) {
            return function (x) {
                return function (o) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(f(o)(a)(x));
                };
            };
        });
    };
};
var foldl1 = function (dictPartial) {
    return function (dictTypedArray) {
        return function (f) {
            return reduce1()(dictTypedArray)(function (a) {
                return function (x) {
                    return function (v) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(f(a)(x));
                    };
                };
            });
        };
    };
};
var foldl = function (dictTypedArray) {
    return function (f) {
        return reduce(dictTypedArray)(function (a) {
            return function (x) {
                return function (v) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(f(a)(x));
                };
            };
        });
    };
};
var findWithIndex$prime = function (dictTypedArray) {
    return function (f) {
        return function (a) {
            return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(function () {
                return $foreign.findImpl(a, Data_Function_Uncurried.mkFn2(f));
            });
        };
    };
};
var findWithIndex = function (dictTypedArray) {
    var $72 = findWithIndex$prime(dictTypedArray);
    return function ($73) {
        return $72(Data_Function.flip($73));
    };
};
var findIndex = function (dictTypedArray) {
    return function (f) {
        return function (a) {
            return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(function () {
                return $foreign.findIndexImpl(a, Data_Function_Uncurried.mkFn2(f));
            });
        };
    };
};
var filterWithIndex$prime = function (dictTypedArray) {
    return function (p) {
        return function (a) {
            return function () {
                return $foreign.filterImpl(a, Data_Function_Uncurried.mkFn2(p));
            };
        };
    };
};
var filterWithIndex = function (dictTypedArray) {
    var $74 = filterWithIndex$prime(dictTypedArray);
    return function ($75) {
        return $74(Data_Function.flip($75));
    };
};
var fill = function (dictTypedArray) {
    return function (x) {
        return function (s) {
            return function (e) {
                return function (a) {
                    return function () {
                        return $foreign.fillImpl(x, s, e, a);
                    };
                };
            };
        };
    };
};
var every = function (dictTypedArray) {
    return function (p) {
        return function (a) {
            return function () {
                return $foreign.everyImpl(a, Data_Function_Uncurried.mkFn2(p));
            };
        };
    };
};
var eq = function (dictTypedArray) {
    return function (dictEq) {
        return function (a) {
            return function (b) {
                return Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(Data_Eq.eq(Data_Eq.eqArray(dictEq)))(toArray(dictTypedArray)(a)))(toArray(dictTypedArray)(b));
            };
        };
    };
};
var elem = function (dictTypedArray) {
    return function (x) {
        return function (mo) {
            return function (a) {
                return function () {
                    return $foreign.includesImpl(a, x, Data_Nullable.toNullable(mo));
                };
            };
        };
    };
};
var create = function (dict) {
    return dict.create;
};
var empty = function (dictTypedArray) {
    return function (n) {
        return function () {
            return create(dictTypedArray)(n, Data_Nullable["null"], Data_Nullable["null"]);
        };
    };
};
var fromArray = function (dictTypedArray) {
    return function (a) {
        return function () {
            return create(dictTypedArray)(a, Data_Nullable["null"], Data_Nullable["null"]);
        };
    };
};
var part$prime = function (dictTypedArray) {
    return function (a) {
        return function (x) {
            return function (y) {
                return function () {
                    return create(dictTypedArray)(a, Data_Nullable.notNull(x), Data_Nullable.notNull(y));
                };
            };
        };
    };
};
var part = function (dictTypedArray) {
    return function (dictNat) {
        return function (dictBytesPerValue) {
            return function (a) {
                return function (x) {
                    return function (y) {
                        var o = x * Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value) | 0;
                        return part$prime(dictTypedArray)(a)(o)(y);
                    };
                };
            };
        };
    };
};
var remainder$prime = function (dictTypedArray) {
    return function (a) {
        return function (x) {
            return function () {
                return create(dictTypedArray)(a, Data_Nullable.notNull(x), Data_Nullable["null"]);
            };
        };
    };
};
var remainder = function (dictTypedArray) {
    return function (dictNat) {
        return function (dictBytesPerValue) {
            return function (a) {
                return function (x) {
                    var o = x * Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value) | 0;
                    return remainder$prime(dictTypedArray)(a)(o);
                };
            };
        };
    };
};
var whole = function (dictTypedArray) {
    return function (a) {
        return function () {
            return create(dictTypedArray)(a, Data_Nullable["null"], Data_Nullable["null"]);
        };
    };
};
var copyWithin = function (a) {
    return function (t) {
        return function (s) {
            return function (me) {
                return function () {
                    return $foreign.copyWithinImpl(a, t, s, Data_Nullable.toNullable(me));
                };
            };
        };
    };
};
var compare = function (dictTypedArray) {
    return function (dictOrd) {
        return function (a) {
            return function (b) {
                return Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(Data_Ord.compare(Data_Ord.ordArray(dictOrd)))(toArray(dictTypedArray)(a)))(toArray(dictTypedArray)(b));
            };
        };
    };
};
var at = function (dictTypedArray) {
    return function (a) {
        return function (n) {
            return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(function () {
                return $foreign.unsafeAtImpl(a, n);
            });
        };
    };
};
var ap1 = function (f) {
    return function (x) {
        return function (v) {
            return f(x);
        };
    };
};
var filter = function (dictTypedArray) {
    var $76 = filterWithIndex$prime(dictTypedArray);
    return function ($77) {
        return $76(ap1($77));
    };
};
var find = function (dictTypedArray) {
    var $78 = findWithIndex$prime(dictTypedArray);
    return function ($79) {
        return $78(ap1($79));
    };
};
var map = function (dictTypedArray) {
    var $80 = mapWithIndex$prime(dictTypedArray);
    return function ($81) {
        return $80(ap1($81));
    };
};
var traverse = function (dictTypedArray) {
    var $82 = traverseWithIndex$prime(dictTypedArray);
    return function ($83) {
        return $82(ap1($83));
    };
};
var traverse_ = function (dictTypedArray) {
    var $84 = traverseWithIndex_$prime(dictTypedArray);
    return function ($85) {
        return $84(ap1($85));
    };
};
var anyWithIndex = function (dictTypedArray) {
    var $86 = some(dictTypedArray);
    return function ($87) {
        return $86(Data_Function.flip($87));
    };
};
var any = function (dictTypedArray) {
    var $88 = some(dictTypedArray);
    return function ($89) {
        return $88(ap1($89));
    };
};
var allWithIndex = function (dictTypedArray) {
    var $90 = every(dictTypedArray);
    return function ($91) {
        return $90(Data_Function.flip($91));
    };
};
var all = function (dictTypedArray) {
    var $92 = every(dictTypedArray);
    return function ($93) {
        return $92(ap1($93));
    };
};
module.exports = {
    length: length,
    compare: compare,
    eq: eq,
    TypedArray: TypedArray,
    create: create,
    whole: whole,
    remainder: remainder,
    part: part,
    empty: empty,
    fromArray: fromArray,
    fill: fill,
    set: set,
    setTyped: setTyped,
    copyWithin: copyWithin,
    map: map,
    traverse: traverse,
    traverse_: traverse_,
    filter: filter,
    mapWithIndex: mapWithIndex,
    traverseWithIndex: traverseWithIndex,
    traverseWithIndex_: traverseWithIndex_,
    filterWithIndex: filterWithIndex,
    sort: sort,
    reverse: reverse,
    elem: elem,
    all: all,
    any: any,
    allWithIndex: allWithIndex,
    anyWithIndex: anyWithIndex,
    unsafeAt: unsafeAt,
    hasIndex: hasIndex,
    at: at,
    reduce: reduce,
    reduce1: reduce1,
    foldl: foldl,
    foldl1: foldl1,
    reduceRight: reduceRight,
    reduceRight1: reduceRight1,
    foldr: foldr,
    foldr1: foldr1,
    find: find,
    findIndex: findIndex,
    indexOf: indexOf,
    lastIndexOf: lastIndexOf,
    slice: slice,
    subArray: subArray,
    toString: toString,
    join: join,
    toArray: toArray,
    typedArrayUint8Clamped: typedArrayUint8Clamped,
    typedArrayUint32: typedArrayUint32,
    typedArrayUint16: typedArrayUint16,
    typedArrayUint8: typedArrayUint8,
    typedArrayInt32: typedArrayInt32,
    typedArrayInt16: typedArrayInt16,
    typedArrayInt8: typedArrayInt8,
    typedArrayFloat32: typedArrayFloat32,
    typedArrayFloat64: typedArrayFloat64,
    buffer: $foreign.buffer,
    byteOffset: $foreign.byteOffset,
    byteLength: $foreign.byteLength
};
