// Generated by purs version 0.13.6
"use strict";
var Effect_AVar = require("../Effect.AVar/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var tryTake = (function () {
    var $0 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
    return function ($1) {
        return $0(Effect_AVar.tryTake($1));
    };
})();
var tryRead = (function () {
    var $2 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
    return function ($3) {
        return $2(Effect_AVar.tryRead($3));
    };
})();
var tryPut = function (value) {
    var $4 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
    var $5 = Effect_AVar.tryPut(value);
    return function ($6) {
        return $4($5($6));
    };
};
var take = function (avar) {
    return Effect_Aff.makeAff(function (k) {
        return function __do() {
            var c = Effect_AVar.take(avar)(k)();
            return Effect_Aff.effectCanceler(c);
        };
    });
};
var status = (function () {
    var $7 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
    return function ($8) {
        return $7(Effect_AVar.status($8));
    };
})();
var read = function (avar) {
    return Effect_Aff.makeAff(function (k) {
        return function __do() {
            var c = Effect_AVar.read(avar)(k)();
            return Effect_Aff.effectCanceler(c);
        };
    });
};
var put = function (value) {
    return function (avar) {
        return Effect_Aff.makeAff(function (k) {
            return function __do() {
                var c = Effect_AVar.put(value)(avar)(k)();
                return Effect_Aff.effectCanceler(c);
            };
        });
    };
};
var $$new = (function () {
    var $9 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
    return function ($10) {
        return $9(Effect_AVar["new"]($10));
    };
})();
var kill = function (error) {
    var $11 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
    var $12 = Effect_AVar.kill(error);
    return function ($13) {
        return $11($12($13));
    };
};
var empty = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_AVar.empty);
module.exports = {
    "new": $$new,
    empty: empty,
    status: status,
    take: take,
    tryTake: tryTake,
    put: put,
    tryPut: tryPut,
    read: read,
    tryRead: tryRead,
    kill: kill
};
