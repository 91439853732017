// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var y = $foreign.unsafeMkProps("y");
var x = $foreign.unsafeMkProps("x");
var wrap = $foreign.unsafeMkProps("wrap");
var wmode = $foreign.unsafeMkProps("wmode");
var width = $foreign.unsafeMkProps("width");
var vocab = $foreign.unsafeMkProps("vocab");
var viewBox = $foreign.unsafeMkProps("viewBox");
var valueArray = $foreign.unsafeMkProps("value");
var value = $foreign.unsafeMkProps("value");
var useMap = $foreign.unsafeMkProps("useMap");
var unselectable = $foreign.unsafeMkProps("unselectable");
var $$typeof = $foreign.unsafeMkProps("typeof");
var title = $foreign.unsafeMkProps("title");
var target = $foreign.unsafeMkProps("target");
var tabIndex = $foreign.unsafeMkProps("tabIndex");
var suppressContentEditableWarning = $foreign.unsafeMkProps("suppressContentEditableWarning");
var summary = $foreign.unsafeMkProps("summary");
var style = $foreign.unsafeUnfoldProps("style");
var strokeWidth = $foreign.unsafeMkProps("strokeWidth");
var stroke = $foreign.unsafeMkProps("stroke");
var step = $foreign.unsafeMkProps("step");
var start = $foreign.unsafeMkProps("start");
var srcSet = $foreign.unsafeMkProps("srcSet");
var srcLang = $foreign.unsafeMkProps("srcLang");
var srcDoc = $foreign.unsafeMkProps("srcDoc");
var src = $foreign.unsafeMkProps("src");
var spellCheck = $foreign.unsafeMkProps("spellCheck");
var span = $foreign.unsafeMkProps("span");
var sizes = $foreign.unsafeMkProps("sizes");
var size = $foreign.unsafeMkProps("size");
var shape = $foreign.unsafeMkProps("shape");
var selected = $foreign.unsafeMkProps("selected");
var security = $foreign.unsafeMkProps("security");
var seamless = $foreign.unsafeMkProps("seamless");
var scrolling = $foreign.unsafeMkProps("scrolling");
var scoped = $foreign.unsafeMkProps("scoped");
var scope = $foreign.unsafeMkProps("scope");
var sandbox = $foreign.unsafeMkProps("sandbox");
var rows = $foreign.unsafeMkProps("rows");
var rowSpan = $foreign.unsafeMkProps("rowSpan");
var role = $foreign.unsafeMkProps("role");
var reversed = $foreign.unsafeMkProps("reversed");
var results = $foreign.unsafeMkProps("results");
var resource = $foreign.unsafeMkProps("resource");
var required = $foreign.unsafeMkProps("required");
var rel = $foreign.unsafeMkProps("rel");
var ref = $foreign.unsafeMkProps("ref");
var readOnly = $foreign.unsafeMkProps("readOnly");
var radioGroup = $foreign.unsafeMkProps("radioGroup");
var r = $foreign.unsafeMkProps("r");
var property = $foreign.unsafeMkProps("property");
var profile = $foreign.unsafeMkProps("profile");
var preload = $foreign.unsafeMkProps("preload");
var prefix = $foreign.unsafeMkProps("prefix");
var poster = $foreign.unsafeMkProps("poster");
var points = $foreign.unsafeMkProps("points");
var placeholder = $foreign.unsafeMkProps("placeholder");
var pattern = $foreign.unsafeMkProps("pattern");
var optimum = $foreign.unsafeMkProps("optimum");
var open = $foreign.unsafeMkProps("open");
var opacity = $foreign.unsafeMkProps("opacity");
var onWheelCapture = function (f) {
    return $foreign.unsafeMkProps("onWheelCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onWheel = function (f) {
    return $foreign.unsafeMkProps("onWheel")(Effect_Uncurried.mkEffectFn1(f));
};
var onWaitingCapture = function (f) {
    return $foreign.unsafeMkProps("onWaitingCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onWaiting = function (f) {
    return $foreign.unsafeMkProps("onWaiting")(Effect_Uncurried.mkEffectFn1(f));
};
var onVolumeChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onVolumeChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onVolumeChange = function (f) {
    return $foreign.unsafeMkProps("onVolumeChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onTransitionEndCapture = function (f) {
    return $foreign.unsafeMkProps("onTransitionEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTransitionEnd = function (f) {
    return $foreign.unsafeMkProps("onTransitionEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchStartCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchStart = function (f) {
    return $foreign.unsafeMkProps("onTouchStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchMoveCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchMoveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchMove = function (f) {
    return $foreign.unsafeMkProps("onTouchMove")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchEndCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchEnd = function (f) {
    return $foreign.unsafeMkProps("onTouchEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchCancelCapture = function (f) {
    return $foreign.unsafeMkProps("onTouchCancelCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTouchCancel = function (f) {
    return $foreign.unsafeMkProps("onTouchCancel")(Effect_Uncurried.mkEffectFn1(f));
};
var onToggleCapture = function (f) {
    return $foreign.unsafeMkProps("onToggleCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onToggle = function (f) {
    return $foreign.unsafeMkProps("onToggle")(Effect_Uncurried.mkEffectFn1(f));
};
var onTimeUpdateCapture = function (f) {
    return $foreign.unsafeMkProps("onTimeUpdateCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onTimeUpdate = function (f) {
    return $foreign.unsafeMkProps("onTimeUpdate")(Effect_Uncurried.mkEffectFn1(f));
};
var onSuspendCapture = function (f) {
    return $foreign.unsafeMkProps("onSuspendCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSuspend = function (f) {
    return $foreign.unsafeMkProps("onSuspend")(Effect_Uncurried.mkEffectFn1(f));
};
var onSubmitCapture = function (f) {
    return $foreign.unsafeMkProps("onSubmitCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSubmit = function (f) {
    return $foreign.unsafeMkProps("onSubmit")(Effect_Uncurried.mkEffectFn1(f));
};
var onStalledCapture = function (f) {
    return $foreign.unsafeMkProps("onStalledCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onStalled = function (f) {
    return $foreign.unsafeMkProps("onStalled")(Effect_Uncurried.mkEffectFn1(f));
};
var onSelectCapture = function (f) {
    return $foreign.unsafeMkProps("onSelectCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSelect = function (f) {
    return $foreign.unsafeMkProps("onSelect")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeekingCapture = function (f) {
    return $foreign.unsafeMkProps("onSeekingCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeeking = function (f) {
    return $foreign.unsafeMkProps("onSeeking")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeekedCapture = function (f) {
    return $foreign.unsafeMkProps("onSeekedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onSeeked = function (f) {
    return $foreign.unsafeMkProps("onSeeked")(Effect_Uncurried.mkEffectFn1(f));
};
var onScrollCapture = function (f) {
    return $foreign.unsafeMkProps("onScrollCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onScroll = function (f) {
    return $foreign.unsafeMkProps("onScroll")(Effect_Uncurried.mkEffectFn1(f));
};
var onRateChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onRateChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onRateChange = function (f) {
    return $foreign.unsafeMkProps("onRateChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onProgressCapture = function (f) {
    return $foreign.unsafeMkProps("onProgressCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onProgress = function (f) {
    return $foreign.unsafeMkProps("onProgress")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlayingCapture = function (f) {
    return $foreign.unsafeMkProps("onPlayingCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlaying = function (f) {
    return $foreign.unsafeMkProps("onPlaying")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlayCapture = function (f) {
    return $foreign.unsafeMkProps("onPlayCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPlay = function (f) {
    return $foreign.unsafeMkProps("onPlay")(Effect_Uncurried.mkEffectFn1(f));
};
var onPauseCapture = function (f) {
    return $foreign.unsafeMkProps("onPauseCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPause = function (f) {
    return $foreign.unsafeMkProps("onPause")(Effect_Uncurried.mkEffectFn1(f));
};
var onPasteCapture = function (f) {
    return $foreign.unsafeMkProps("onPasteCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onPaste = function (f) {
    return $foreign.unsafeMkProps("onPaste")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseUpCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseUpCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseUp = function (f) {
    return $foreign.unsafeMkProps("onMouseUp")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOverCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseOverCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOver = function (f) {
    return $foreign.unsafeMkProps("onMouseOver")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOutCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseOutCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseOut = function (f) {
    return $foreign.unsafeMkProps("onMouseOut")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseMoveCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseMoveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseMove = function (f) {
    return $foreign.unsafeMkProps("onMouseMove")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseLeaveCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseLeaveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseLeave = function (f) {
    return $foreign.unsafeMkProps("onMouseLeave")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseEnterCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseEnterCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseEnter = function (f) {
    return $foreign.unsafeMkProps("onMouseEnter")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseDownCapture = function (f) {
    return $foreign.unsafeMkProps("onMouseDownCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onMouseDown = function (f) {
    return $foreign.unsafeMkProps("onMouseDown")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedMetadataCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadedMetadataCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedMetadata = function (f) {
    return $foreign.unsafeMkProps("onLoadedMetadata")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedDataCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadedDataCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadedData = function (f) {
    return $foreign.unsafeMkProps("onLoadedData")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadStartCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadStart = function (f) {
    return $foreign.unsafeMkProps("onLoadStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoadCapture = function (f) {
    return $foreign.unsafeMkProps("onLoadCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onLoad = function (f) {
    return $foreign.unsafeMkProps("onLoad")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyUpCapture = function (f) {
    return $foreign.unsafeMkProps("onKeyUpCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyUp = function (f) {
    return $foreign.unsafeMkProps("onKeyUp")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyPressCapture = function (f) {
    return $foreign.unsafeMkProps("onKeyPressCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyPress = function (f) {
    return $foreign.unsafeMkProps("onKeyPress")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyDownCapture = function (f) {
    return $foreign.unsafeMkProps("onKeyDownCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onKeyDown = function (f) {
    return $foreign.unsafeMkProps("onKeyDown")(Effect_Uncurried.mkEffectFn1(f));
};
var onInvalidCapture = function (f) {
    return $foreign.unsafeMkProps("onInvalidCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onInvalid = function (f) {
    return $foreign.unsafeMkProps("onInvalid")(Effect_Uncurried.mkEffectFn1(f));
};
var onInputCapture = function (f) {
    return $foreign.unsafeMkProps("onInputCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onInput = function (f) {
    return $foreign.unsafeMkProps("onInput")(Effect_Uncurried.mkEffectFn1(f));
};
var onFocusCapture = function (f) {
    return $foreign.unsafeMkProps("onFocusCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onFocus = function (f) {
    return $foreign.unsafeMkProps("onFocus")(Effect_Uncurried.mkEffectFn1(f));
};
var onErrorCapture = function (f) {
    return $foreign.unsafeMkProps("onErrorCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onError = function (f) {
    return $foreign.unsafeMkProps("onError")(Effect_Uncurried.mkEffectFn1(f));
};
var onEndedCapture = function (f) {
    return $foreign.unsafeMkProps("onEndedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onEnded = function (f) {
    return $foreign.unsafeMkProps("onEnded")(Effect_Uncurried.mkEffectFn1(f));
};
var onEncryptedCapture = function (f) {
    return $foreign.unsafeMkProps("onEncryptedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onEncrypted = function (f) {
    return $foreign.unsafeMkProps("onEncrypted")(Effect_Uncurried.mkEffectFn1(f));
};
var onEmptiedCapture = function (f) {
    return $foreign.unsafeMkProps("onEmptiedCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onEmptied = function (f) {
    return $foreign.unsafeMkProps("onEmptied")(Effect_Uncurried.mkEffectFn1(f));
};
var onDurationChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onDurationChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDurationChange = function (f) {
    return $foreign.unsafeMkProps("onDurationChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onDropCapture = function (f) {
    return $foreign.unsafeMkProps("onDropCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDrop = function (f) {
    return $foreign.unsafeMkProps("onDrop")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragStartCapture = function (f) {
    return $foreign.unsafeMkProps("onDragStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragStart = function (f) {
    return $foreign.unsafeMkProps("onDragStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragOverCapture = function (f) {
    return $foreign.unsafeMkProps("onDragOverCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragOver = function (f) {
    return $foreign.unsafeMkProps("onDragOver")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragLeaveCapture = function (f) {
    return $foreign.unsafeMkProps("onDragLeaveCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragLeave = function (f) {
    return $foreign.unsafeMkProps("onDragLeave")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragExitCapture = function (f) {
    return $foreign.unsafeMkProps("onDragExitCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragExit = function (f) {
    return $foreign.unsafeMkProps("onDragExit")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEnterCapture = function (f) {
    return $foreign.unsafeMkProps("onDragEnterCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEnter = function (f) {
    return $foreign.unsafeMkProps("onDragEnter")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEndCapture = function (f) {
    return $foreign.unsafeMkProps("onDragEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragEnd = function (f) {
    return $foreign.unsafeMkProps("onDragEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onDragCapture = function (f) {
    return $foreign.unsafeMkProps("onDragCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDrag = function (f) {
    return $foreign.unsafeMkProps("onDrag")(Effect_Uncurried.mkEffectFn1(f));
};
var onDoubleClickCapture = function (f) {
    return $foreign.unsafeMkProps("onDoubleClickCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onDoubleClick = function (f) {
    return $foreign.unsafeMkProps("onDoubleClick")(Effect_Uncurried.mkEffectFn1(f));
};
var onCutCapture = function (f) {
    return $foreign.unsafeMkProps("onCutCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCut = function (f) {
    return $foreign.unsafeMkProps("onCut")(Effect_Uncurried.mkEffectFn1(f));
};
var onCopyCapture = function (f) {
    return $foreign.unsafeMkProps("onCopyCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCopy = function (f) {
    return $foreign.unsafeMkProps("onCopy")(Effect_Uncurried.mkEffectFn1(f));
};
var onContextMenuCapture = function (f) {
    return $foreign.unsafeMkProps("onContextMenuCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onContextMenu = function (f) {
    return $foreign.unsafeMkProps("onContextMenu")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionUpdateCapture = function (f) {
    return $foreign.unsafeMkProps("onCompositionUpdateCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionUpdate = function (f) {
    return $foreign.unsafeMkProps("onCompositionUpdate")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionStartCapture = function (f) {
    return $foreign.unsafeMkProps("onCompositionStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionStart = function (f) {
    return $foreign.unsafeMkProps("onCompositionStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionEndCapture = function (f) {
    return $foreign.unsafeMkProps("onCompositionEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCompositionEnd = function (f) {
    return $foreign.unsafeMkProps("onCompositionEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onClickCapture = function (f) {
    return $foreign.unsafeMkProps("onClickCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onClick = function (f) {
    return $foreign.unsafeMkProps("onClick")(Effect_Uncurried.mkEffectFn1(f));
};
var onChangeCapture = function (f) {
    return $foreign.unsafeMkProps("onChangeCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onChange = function (f) {
    return $foreign.unsafeMkProps("onChange")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlayThroughCapture = function (f) {
    return $foreign.unsafeMkProps("onCanPlayThroughCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlayThrough = function (f) {
    return $foreign.unsafeMkProps("onCanPlayThrough")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlayCapture = function (f) {
    return $foreign.unsafeMkProps("onCanPlayCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onCanPlay = function (f) {
    return $foreign.unsafeMkProps("onCanPlay")(Effect_Uncurried.mkEffectFn1(f));
};
var onBlurCapture = function (f) {
    return $foreign.unsafeMkProps("onBlurCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onBlur = function (f) {
    return $foreign.unsafeMkProps("onBlur")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationStartCapture = function (f) {
    return $foreign.unsafeMkProps("onAnimationStartCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationStart = function (f) {
    return $foreign.unsafeMkProps("onAnimationStart")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationIterationCapture = function (f) {
    return $foreign.unsafeMkProps("onAnimationIterationCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationIteration = function (f) {
    return $foreign.unsafeMkProps("onAnimationIteration")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationEndCapture = function (f) {
    return $foreign.unsafeMkProps("onAnimationEndCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAnimationEnd = function (f) {
    return $foreign.unsafeMkProps("onAnimationEnd")(Effect_Uncurried.mkEffectFn1(f));
};
var onAbortCapture = function (f) {
    return $foreign.unsafeMkProps("onAbortCapture")(Effect_Uncurried.mkEffectFn1(f));
};
var onAbort = function (f) {
    return $foreign.unsafeMkProps("onAbort")(Effect_Uncurried.mkEffectFn1(f));
};
var nonce = $foreign.unsafeMkProps("nonce");
var noValidate = $foreign.unsafeMkProps("noValidate");
var name = $foreign.unsafeMkProps("name");
var muted = $foreign.unsafeMkProps("muted");
var multiple = $foreign.unsafeMkProps("multiple");
var minLength = $foreign.unsafeMkProps("minLength");
var min = $foreign.unsafeMkProps("min");
var method = $foreign.unsafeMkProps("method");
var mediaGroup = $foreign.unsafeMkProps("mediaGroup");
var media = $foreign.unsafeMkProps("media");
var maxLength = $foreign.unsafeMkProps("maxLength");
var max = $foreign.unsafeMkProps("max");
var marginWidth = $foreign.unsafeMkProps("marginWidth");
var marginHeight = $foreign.unsafeMkProps("marginHeight");
var manifest = $foreign.unsafeMkProps("manifest");
var low = $foreign.unsafeMkProps("low");
var loop = $foreign.unsafeMkProps("loop");
var list = $foreign.unsafeMkProps("list");
var lang = $foreign.unsafeMkProps("lang");
var label = $foreign.unsafeMkProps("label");
var kind = $foreign.unsafeMkProps("kind");
var keyType = $foreign.unsafeMkProps("keyType");
var keyParams = $foreign.unsafeMkProps("keyParams");
var key = $foreign.unsafeMkProps("key");
var itemType = $foreign.unsafeMkProps("itemType");
var itemScope = $foreign.unsafeMkProps("itemScope");
var itemRef = $foreign.unsafeMkProps("itemRef");
var itemProp = $foreign.unsafeMkProps("itemProp");
var itemID = $foreign.unsafeMkProps("itemID");
var is = $foreign.unsafeMkProps("is");
var integrity = $foreign.unsafeMkProps("integrity");
var inputMode = $foreign.unsafeMkProps("inputMode");
var inlist = $foreign.unsafeMkProps("inlist");
var icon = $foreign.unsafeMkProps("icon");
var httpEquiv = $foreign.unsafeMkProps("httpEquiv");
var htmlFor = $foreign.unsafeMkProps("htmlFor");
var hrefLang = $foreign.unsafeMkProps("hrefLang");
var href = $foreign.unsafeMkProps("href");
var high = $foreign.unsafeMkProps("high");
var hidden = $foreign.unsafeMkProps("hidden");
var height = $foreign.unsafeMkProps("height");
var headers = $foreign.unsafeMkProps("headers");
var frameBorder = $foreign.unsafeMkProps("frameBorder");
var formTarget = $foreign.unsafeMkProps("formTarget");
var formNoValidate = $foreign.unsafeMkProps("formNoValidate");
var formMethod = $foreign.unsafeMkProps("formMethod");
var formEncType = $foreign.unsafeMkProps("formEncType");
var formAction = $foreign.unsafeMkProps("formAction");
var form = $foreign.unsafeMkProps("form");
var fillOpacity = $foreign.unsafeMkProps("fillOpacity");
var fill = $foreign.unsafeMkProps("fill");
var encType = $foreign.unsafeMkProps("encType");
var draggable = $foreign.unsafeMkProps("draggable");
var download = $foreign.unsafeMkProps("download");
var disabled = $foreign.unsafeMkProps("disabled");
var dir = $foreign.unsafeMkProps("dir");
var defer = $foreign.unsafeMkProps("defer");
var defaultValue = $foreign.unsafeMkProps("defaultValue");
var defaultChecked = $foreign.unsafeMkProps("defaultChecked");
var $$default = $foreign.unsafeMkProps("default");
var dateTime = $foreign.unsafeMkProps("dateTime");
var datatype = $foreign.unsafeMkProps("datatype");
var dangerouslySetInnerHTML = $foreign.unsafeMkProps("dangerouslySetInnerHTML");
var d = $foreign.unsafeMkProps("d");
var cy = $foreign.unsafeMkProps("cy");
var cx = $foreign.unsafeMkProps("cx");
var crossOrigin = $foreign.unsafeMkProps("crossOrigin");
var coords = $foreign.unsafeMkProps("coords");
var controls = $foreign.unsafeMkProps("controls");
var contextMenu = $foreign.unsafeMkProps("contextMenu");
var contentEditable = $foreign.unsafeMkProps("contentEditable");
var content = $foreign.unsafeMkProps("content");
var cols = $foreign.unsafeMkProps("cols");
var color = $foreign.unsafeMkProps("color");
var colSpan = $foreign.unsafeMkProps("colSpan");
var className = $foreign.unsafeMkProps("className");
var classID = $foreign.unsafeMkProps("classID");
var cite = $foreign.unsafeMkProps("cite");
var checked = $foreign.unsafeMkProps("checked");
var charSet = $foreign.unsafeMkProps("charSet");
var challenge = $foreign.unsafeMkProps("checked");
var cellSpacing = $foreign.unsafeMkProps("cellSpacing");
var cellPadding = $foreign.unsafeMkProps("cellPadding");
var capture = $foreign.unsafeMkProps("capture");
var autoSave = $foreign.unsafeMkProps("autoSave");
var autoPlay = $foreign.unsafeMkProps("autoPlay");
var autoFocus = $foreign.unsafeMkProps("autoFocus");
var autoCorrect = $foreign.unsafeMkProps("autoCorrect");
var autoComplete = $foreign.unsafeMkProps("autoComplete");
var autoCapitalize = $foreign.unsafeMkProps("autoCapitalize");
var async = $foreign.unsafeMkProps("async");
var aria = $foreign.unsafePrefixProps("aria-");
var alt = $foreign.unsafeMkProps("alt");
var allowTransparency = $foreign.unsafeMkProps("allowTransparency");
var allowFullScreen = $foreign.unsafeMkProps("allowFullScreen");
var action = $foreign.unsafeMkProps("action");
var accessKey = $foreign.unsafeMkProps("accessKey");
var acceptCharset = $foreign.unsafeMkProps("acceptCharset");
var accept = $foreign.unsafeMkProps("accept");
var about = $foreign.unsafeMkProps("about");
var _type = $foreign.unsafeMkProps("type");
var _id = $foreign.unsafeMkProps("id");
var _data = $foreign.unsafePrefixProps("data-");
module.exports = {
    aria: aria,
    "_data": _data,
    style: style,
    dangerouslySetInnerHTML: dangerouslySetInnerHTML,
    accept: accept,
    acceptCharset: acceptCharset,
    accessKey: accessKey,
    action: action,
    allowFullScreen: allowFullScreen,
    allowTransparency: allowTransparency,
    alt: alt,
    async: async,
    autoComplete: autoComplete,
    autoFocus: autoFocus,
    autoPlay: autoPlay,
    capture: capture,
    cellPadding: cellPadding,
    cellSpacing: cellSpacing,
    charSet: charSet,
    challenge: challenge,
    checked: checked,
    cite: cite,
    classID: classID,
    className: className,
    cols: cols,
    colSpan: colSpan,
    content: content,
    contentEditable: contentEditable,
    contextMenu: contextMenu,
    controls: controls,
    coords: coords,
    crossOrigin: crossOrigin,
    dateTime: dateTime,
    "default": $$default,
    defaultChecked: defaultChecked,
    defaultValue: defaultValue,
    defer: defer,
    dir: dir,
    disabled: disabled,
    download: download,
    draggable: draggable,
    encType: encType,
    form: form,
    formAction: formAction,
    formEncType: formEncType,
    formMethod: formMethod,
    formNoValidate: formNoValidate,
    formTarget: formTarget,
    frameBorder: frameBorder,
    headers: headers,
    height: height,
    hidden: hidden,
    high: high,
    href: href,
    hrefLang: hrefLang,
    htmlFor: htmlFor,
    httpEquiv: httpEquiv,
    icon: icon,
    "_id": _id,
    inputMode: inputMode,
    integrity: integrity,
    is: is,
    key: key,
    keyParams: keyParams,
    keyType: keyType,
    kind: kind,
    label: label,
    lang: lang,
    list: list,
    loop: loop,
    low: low,
    manifest: manifest,
    marginHeight: marginHeight,
    marginWidth: marginWidth,
    max: max,
    maxLength: maxLength,
    media: media,
    mediaGroup: mediaGroup,
    method: method,
    min: min,
    minLength: minLength,
    multiple: multiple,
    muted: muted,
    name: name,
    nonce: nonce,
    noValidate: noValidate,
    open: open,
    optimum: optimum,
    pattern: pattern,
    placeholder: placeholder,
    poster: poster,
    preload: preload,
    profile: profile,
    radioGroup: radioGroup,
    readOnly: readOnly,
    rel: rel,
    required: required,
    reversed: reversed,
    role: role,
    rows: rows,
    rowSpan: rowSpan,
    sandbox: sandbox,
    scope: scope,
    scoped: scoped,
    scrolling: scrolling,
    seamless: seamless,
    selected: selected,
    shape: shape,
    size: size,
    sizes: sizes,
    span: span,
    spellCheck: spellCheck,
    src: src,
    srcDoc: srcDoc,
    srcLang: srcLang,
    srcSet: srcSet,
    start: start,
    step: step,
    summary: summary,
    tabIndex: tabIndex,
    target: target,
    title: title,
    "_type": _type,
    useMap: useMap,
    value: value,
    valueArray: valueArray,
    width: width,
    wmode: wmode,
    wrap: wrap,
    about: about,
    datatype: datatype,
    inlist: inlist,
    prefix: prefix,
    property: property,
    resource: resource,
    "typeof": $$typeof,
    vocab: vocab,
    autoCapitalize: autoCapitalize,
    autoCorrect: autoCorrect,
    autoSave: autoSave,
    color: color,
    itemProp: itemProp,
    itemScope: itemScope,
    itemType: itemType,
    itemID: itemID,
    itemRef: itemRef,
    results: results,
    security: security,
    unselectable: unselectable,
    onAnimationStart: onAnimationStart,
    onAnimationEnd: onAnimationEnd,
    onAnimationIteration: onAnimationIteration,
    onTransitionEnd: onTransitionEnd,
    onToggle: onToggle,
    onError: onError,
    onLoad: onLoad,
    onAbort: onAbort,
    onCanPlay: onCanPlay,
    onCanPlayThrough: onCanPlayThrough,
    onDurationChange: onDurationChange,
    onEmptied: onEmptied,
    onEncrypted: onEncrypted,
    onEnded: onEnded,
    onLoadedData: onLoadedData,
    onLoadedMetadata: onLoadedMetadata,
    onLoadStart: onLoadStart,
    onPause: onPause,
    onPlay: onPlay,
    onPlaying: onPlaying,
    onProgress: onProgress,
    onRateChange: onRateChange,
    onSeeked: onSeeked,
    onSeeking: onSeeking,
    onStalled: onStalled,
    onSuspend: onSuspend,
    onTimeUpdate: onTimeUpdate,
    onVolumeChange: onVolumeChange,
    onWaiting: onWaiting,
    onCopy: onCopy,
    onCut: onCut,
    onPaste: onPaste,
    onCompositionEnd: onCompositionEnd,
    onCompositionStart: onCompositionStart,
    onCompositionUpdate: onCompositionUpdate,
    onKeyDown: onKeyDown,
    onKeyPress: onKeyPress,
    onKeyUp: onKeyUp,
    onFocus: onFocus,
    onBlur: onBlur,
    onChange: onChange,
    onInput: onInput,
    onInvalid: onInvalid,
    onSubmit: onSubmit,
    onClick: onClick,
    onContextMenu: onContextMenu,
    onDoubleClick: onDoubleClick,
    onDrag: onDrag,
    onDragEnd: onDragEnd,
    onDragEnter: onDragEnter,
    onDragExit: onDragExit,
    onDragLeave: onDragLeave,
    onDragOver: onDragOver,
    onDragStart: onDragStart,
    onDrop: onDrop,
    onMouseDown: onMouseDown,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    onMouseMove: onMouseMove,
    onMouseOut: onMouseOut,
    onMouseOver: onMouseOver,
    onMouseUp: onMouseUp,
    onSelect: onSelect,
    onTouchCancel: onTouchCancel,
    onTouchEnd: onTouchEnd,
    onTouchMove: onTouchMove,
    onTouchStart: onTouchStart,
    onScroll: onScroll,
    onWheel: onWheel,
    onAnimationStartCapture: onAnimationStartCapture,
    onAnimationEndCapture: onAnimationEndCapture,
    onAnimationIterationCapture: onAnimationIterationCapture,
    onTransitionEndCapture: onTransitionEndCapture,
    onToggleCapture: onToggleCapture,
    onErrorCapture: onErrorCapture,
    onLoadCapture: onLoadCapture,
    onAbortCapture: onAbortCapture,
    onCanPlayCapture: onCanPlayCapture,
    onCanPlayThroughCapture: onCanPlayThroughCapture,
    onDurationChangeCapture: onDurationChangeCapture,
    onEmptiedCapture: onEmptiedCapture,
    onEncryptedCapture: onEncryptedCapture,
    onEndedCapture: onEndedCapture,
    onLoadedDataCapture: onLoadedDataCapture,
    onLoadedMetadataCapture: onLoadedMetadataCapture,
    onLoadStartCapture: onLoadStartCapture,
    onPauseCapture: onPauseCapture,
    onPlayCapture: onPlayCapture,
    onPlayingCapture: onPlayingCapture,
    onProgressCapture: onProgressCapture,
    onRateChangeCapture: onRateChangeCapture,
    onSeekedCapture: onSeekedCapture,
    onSeekingCapture: onSeekingCapture,
    onStalledCapture: onStalledCapture,
    onSuspendCapture: onSuspendCapture,
    onTimeUpdateCapture: onTimeUpdateCapture,
    onVolumeChangeCapture: onVolumeChangeCapture,
    onWaitingCapture: onWaitingCapture,
    onCopyCapture: onCopyCapture,
    onCutCapture: onCutCapture,
    onPasteCapture: onPasteCapture,
    onCompositionEndCapture: onCompositionEndCapture,
    onCompositionStartCapture: onCompositionStartCapture,
    onCompositionUpdateCapture: onCompositionUpdateCapture,
    onKeyDownCapture: onKeyDownCapture,
    onKeyPressCapture: onKeyPressCapture,
    onKeyUpCapture: onKeyUpCapture,
    onFocusCapture: onFocusCapture,
    onBlurCapture: onBlurCapture,
    onChangeCapture: onChangeCapture,
    onInputCapture: onInputCapture,
    onInvalidCapture: onInvalidCapture,
    onSubmitCapture: onSubmitCapture,
    onClickCapture: onClickCapture,
    onContextMenuCapture: onContextMenuCapture,
    onDoubleClickCapture: onDoubleClickCapture,
    onDragCapture: onDragCapture,
    onDragEndCapture: onDragEndCapture,
    onDragEnterCapture: onDragEnterCapture,
    onDragExitCapture: onDragExitCapture,
    onDragLeaveCapture: onDragLeaveCapture,
    onDragOverCapture: onDragOverCapture,
    onDragStartCapture: onDragStartCapture,
    onDropCapture: onDropCapture,
    onMouseDownCapture: onMouseDownCapture,
    onMouseEnterCapture: onMouseEnterCapture,
    onMouseLeaveCapture: onMouseLeaveCapture,
    onMouseMoveCapture: onMouseMoveCapture,
    onMouseOutCapture: onMouseOutCapture,
    onMouseOverCapture: onMouseOverCapture,
    onMouseUpCapture: onMouseUpCapture,
    onSelectCapture: onSelectCapture,
    onTouchCancelCapture: onTouchCancelCapture,
    onTouchEndCapture: onTouchEndCapture,
    onTouchMoveCapture: onTouchMoveCapture,
    onTouchStartCapture: onTouchStartCapture,
    onScrollCapture: onScrollCapture,
    onWheelCapture: onWheelCapture,
    ref: ref,
    suppressContentEditableWarning: suppressContentEditableWarning,
    x: x,
    y: y,
    cx: cx,
    cy: cy,
    r: r,
    fill: fill,
    opacity: opacity,
    fillOpacity: fillOpacity,
    stroke: stroke,
    strokeWidth: strokeWidth,
    points: points,
    d: d,
    viewBox: viewBox,
    unsafeMkProps: $foreign.unsafeMkProps,
    unsafeUnfoldProps: $foreign.unsafeUnfoldProps,
    unsafePrefixProps: $foreign.unsafePrefixProps,
    unsafeFromPropsArray: $foreign.unsafeFromPropsArray
};
