// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Audio_WebAudio_Utils = require("../Audio.WebAudio.Utils/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var startBufferSource = function (v) {
    return function (start) {
        if (v.when instanceof Data_Maybe.Nothing && (v.offset instanceof Data_Maybe.Nothing && v.duration instanceof Data_Maybe.Nothing)) {
            return $foreign.startBufferSourceFn1(start);
        };
        if (v.when instanceof Data_Maybe.Just && (v.offset instanceof Data_Maybe.Just && v.duration instanceof Data_Maybe.Just)) {
            return $foreign.startBufferSourceFn4(v.when.value0)(v.offset.value0)(v.duration.value0)(start);
        };
        if (v.when instanceof Data_Maybe.Just && (v.offset instanceof Data_Maybe.Just && v.duration instanceof Data_Maybe.Nothing)) {
            return $foreign.startBufferSourceFn3(v.when.value0)(v.offset.value0)(start);
        };
        if (v.when instanceof Data_Maybe.Just && (v.offset instanceof Data_Maybe.Nothing && v.duration instanceof Data_Maybe.Nothing)) {
            return $foreign.startBufferSourceFn2(v.when.value0)(start);
        };
        return $foreign.startBufferSourceFn1(start);
    };
};
var setLoopStart = function (l) {
    return function (n) {
        return Audio_WebAudio_Utils.unsafeSetProp("loopStart")(n)(l);
    };
};
var setLoopEnd = function (l) {
    return function (n) {
        return Audio_WebAudio_Utils.unsafeSetProp("loopEnd")(n)(l);
    };
};
var setLoop = function (l) {
    return function (n) {
        return Audio_WebAudio_Utils.unsafeSetProp("loop")(n)(l);
    };
};
var loopStart = Audio_WebAudio_Utils.unsafeGetProp("loopStart");
var loopEnd = Audio_WebAudio_Utils.unsafeGetProp("loopEnd");
var loop = Audio_WebAudio_Utils.unsafeGetProp("loop");
var defaultStartOptions = {
    when: Data_Maybe.Nothing.value,
    offset: Data_Maybe.Nothing.value,
    duration: Data_Maybe.Nothing.value
};
module.exports = {
    defaultStartOptions: defaultStartOptions,
    startBufferSource: startBufferSource,
    loop: loop,
    setLoop: setLoop,
    loopStart: loopStart,
    setLoopStart: setLoopStart,
    loopEnd: loopEnd,
    setLoopEnd: setLoopEnd,
    setBuffer: $foreign.setBuffer,
    stopBufferSource: $foreign.stopBufferSource,
    detune: $foreign.detune,
    playbackRate: $foreign.playbackRate,
    onended: $foreign.onended
};
