// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var ReactPureComponentSpec = {};
var ReactPropFields = {};
var ReactComponentSpec = {};
var IsReactElement = function (toElement) {
    this.toElement = toElement;
};
var writeStateWithCallback = $foreign.setStateWithCallbackImpl;
var writeState = $foreign.setStateImpl;
var unsafeCreateLeafElement = $foreign.createLeafElementImpl;
var unsafeCreateElementDynamic = $foreign.createElementDynamicImpl;
var unsafeCreateElement = $foreign.createElementImpl;
var toElement = function (dict) {
    return dict.toElement;
};
var setStateWithCallback = function (dictUnion) {
    return $foreign.setStateWithCallbackImpl;
};
var setState = function (dictUnion) {
    return $foreign.setStateImpl;
};
var reactPureComponentSpec = function (dictUnion) {
    return function (dictNub) {
        return ReactPureComponentSpec;
    };
};
var reactPropFields = function (dictUnion) {
    return function (dictUnion1) {
        return ReactPropFields;
    };
};
var reactComponentSpec = function (dictUnion) {
    return function (dictNub) {
        return ReactComponentSpec;
    };
};
var pureComponentWithDerivedState = function (dictReactPureComponentSpec) {
    return $foreign.componentWithDerivedStateImpl;
};
var pureComponent = function (dictReactPureComponentSpec) {
    return $foreign.pureComponentImpl;
};
var modifyStateWithCallback = $foreign.setStateWithCallbackImpl;
var modifyState = $foreign.setStateImpl;
var isReactElementString = new IsReactElement(Unsafe_Coerce.unsafeCoerce);
var isReactElementReactElement = new IsReactElement(Control_Category.identity(Control_Category.categoryFn));
var isReactElementNumber = new IsReactElement(Unsafe_Coerce.unsafeCoerce);
var isReactElementInt = new IsReactElement(Unsafe_Coerce.unsafeCoerce);
var isReactElementChildren = new IsReactElement(Unsafe_Coerce.unsafeCoerce);
var forceUpdate = function ($$this) {
    return $foreign.forceUpdateWithCallback($$this)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
};
var createLeafElement = function (dictReactPropFields) {
    return $foreign.createLeafElementImpl;
};
var createElementDynamic = function (dictReactPropFields) {
    return $foreign.createElementDynamicImpl;
};
var createElement = function (dictReactPropFields) {
    return $foreign.createElementImpl;
};
var fragmentWithKey = (function () {
    var $16 = createElement()($foreign.fragment);
    return function ($17) {
        return $16((function (v) {
            return {
                key: v
            };
        })($17));
    };
})();
var isReactElementArray = new IsReactElement(createElement()($foreign.fragment)({}));
var semigroupReactElement = new Data_Semigroup.Semigroup(function (a) {
    return function (b) {
        return toElement(isReactElementArray)([ a, b ]);
    };
});
var monoidReactElement = new Data_Monoid.Monoid(function () {
    return semigroupReactElement;
}, toElement(isReactElementArray)([  ]));
var componentWithDerivedState = function (dictReactComponentSpec) {
    return $foreign.componentWithDerivedStateImpl;
};
var component = function (dictReactComponentSpec) {
    return $foreign.componentImpl;
};
module.exports = {
    ReactComponentSpec: ReactComponentSpec,
    ReactPureComponentSpec: ReactPureComponentSpec,
    component: component,
    componentWithDerivedState: componentWithDerivedState,
    pureComponent: pureComponent,
    pureComponentWithDerivedState: pureComponentWithDerivedState,
    setState: setState,
    setStateWithCallback: setStateWithCallback,
    writeState: writeState,
    writeStateWithCallback: writeStateWithCallback,
    modifyState: modifyState,
    modifyStateWithCallback: modifyStateWithCallback,
    forceUpdate: forceUpdate,
    ReactPropFields: ReactPropFields,
    createElement: createElement,
    unsafeCreateElement: unsafeCreateElement,
    createElementDynamic: createElementDynamic,
    unsafeCreateElementDynamic: unsafeCreateElementDynamic,
    createLeafElement: createLeafElement,
    unsafeCreateLeafElement: unsafeCreateLeafElement,
    IsReactElement: IsReactElement,
    toElement: toElement,
    fragmentWithKey: fragmentWithKey,
    semigroupReactElement: semigroupReactElement,
    monoidReactElement: monoidReactElement,
    reactComponentSpec: reactComponentSpec,
    reactPureComponentSpec: reactPureComponentSpec,
    reactPropFields: reactPropFields,
    isReactElementString: isReactElementString,
    isReactElementNumber: isReactElementNumber,
    isReactElementInt: isReactElementInt,
    isReactElementChildren: isReactElementChildren,
    isReactElementReactElement: isReactElementReactElement,
    isReactElementArray: isReactElementArray,
    statelessComponent: $foreign.statelessComponent,
    getProps: $foreign.getProps,
    getState: $foreign.getState,
    forceUpdateWithCallback: $foreign.forceUpdateWithCallback,
    createElementTagName: $foreign.createElementTagName,
    createElementTagNameDynamic: $foreign.createElementTagNameDynamic,
    childrenToArray: $foreign.childrenToArray,
    childrenCount: $foreign.childrenCount,
    fragment: $foreign.fragment,
    createContext: $foreign.createContext
};
