// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Effect_Random = require("../Effect.Random/index.js");
var getWeighted = function ($copy_x) {
    return function ($copy_xs) {
        return function ($copy_total) {
            var $tco_var_x = $copy_x;
            var $tco_var_xs = $copy_xs;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(x, xs, total) {
                var v = Data_Array.uncons(xs);
                if (v instanceof Data_Maybe.Nothing) {
                    $tco_done = true;
                    return Data_Tuple.snd(x);
                };
                if (v instanceof Data_Maybe.Just) {
                    var $8 = total <= Data_Tuple.fst(x);
                    if ($8) {
                        $tco_done = true;
                        return Data_Tuple.snd(x);
                    };
                    $tco_var_x = v.value0.head;
                    $tco_var_xs = v.value0.tail;
                    $copy_total = total - Data_Ord.abs(Data_Ord.ordNumber)(Data_Ring.ringNumber)(Data_Tuple.fst(x));
                    return;
                };
                throw new Error("Failed pattern match at Effect.Random.Extra (line 44, column 26 - line 50, column 45): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_x, $tco_var_xs, $copy_total);
            };
            return $tco_result;
        };
    };
};
var randomWeighted = function (xs) {
    return function __do() {
        var r = Data_Functor.map(Effect.functorEffect)(function (v) {
            return v * Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)((function () {
                var $19 = Data_Ord.abs(Data_Ord.ordNumber)(Data_Ring.ringNumber);
                return function ($20) {
                    return $19(Data_Tuple.fst($20));
                };
            })())(xs));
        })(Effect_Random.random)();
        var v = Data_Array_NonEmpty.uncons(xs);
        return getWeighted(v.head)(v.tail)(r);
    };
};
var randomUniqElements = function (dictEq) {
    return function (v) {
        return function (xs) {
            if (v === 0) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Maybe.Nothing.value);
            };
            if (v === 1) {
                return Data_Functor.map(Effect.functorEffect)(function ($21) {
                    return Data_Maybe.Just.create(Data_Array_NonEmpty.singleton($21));
                })(randomWeighted(xs));
            };
            return function __do() {
                var x = randomWeighted(xs)();
                var v1 = Data_Array_NonEmpty.fromArray(Data_Array_NonEmpty.filter(function ($22) {
                    return (function (v2) {
                        return Data_Eq.notEq(dictEq)(v2)(x);
                    })(Data_Tuple.snd($22));
                })(xs));
                if (v1 instanceof Data_Maybe.Just) {
                    var rest = randomUniqElements(dictEq)(v - 1 | 0)(v1.value0)();
                    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array_NonEmpty.cons(x))(rest);
                };
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at Effect.Random.Extra (line 30, column 3 - line 34, column 28): " + [ v1.constructor.name ]);
            };
        };
    };
};
module.exports = {
    randomUniqElements: randomUniqElements,
    randomWeighted: randomWeighted
};
