// Generated by purs version 0.13.6
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var updateIf = function (dictFunctor) {
    return function (pred) {
        return function (f) {
            return Data_Functor.map(dictFunctor)(function (y) {
                var $1 = pred(y);
                if ($1) {
                    return f(y);
                };
                return y;
            });
        };
    };
};
module.exports = {
    updateIf: updateIf
};
