// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var whileM_ = function (dictMonad) {
    return function (p) {
        return function (f) {
            return Control_Bind.bind(dictMonad.Bind1())(p)(function (v) {
                if (v) {
                    return Control_Bind.bind(dictMonad.Bind1())(f)(function (v1) {
                        return whileM_(dictMonad)(p)(f);
                    });
                };
                return Control_Applicative.pure(dictMonad.Applicative0())(Data_Unit.unit);
            });
        };
    };
};
var whileM$prime = function (dictMonad) {
    return function (dictApplicative) {
        return function (dictMonoid) {
            return function (p) {
                return function (f) {
                    var loop = function (acc) {
                        return Control_Bind.bind(dictMonad.Bind1())(p)(function (v) {
                            if (v) {
                                return Control_Bind.bind(dictMonad.Bind1())(f)(function (x) {
                                    return loop(Data_Semigroup.append(dictMonoid.Semigroup0())(Control_Applicative.pure(dictApplicative)(x))(acc));
                                });
                            };
                            return Control_Applicative.pure(dictMonad.Applicative0())(acc);
                        });
                    };
                    return loop(Data_Monoid.mempty(dictMonoid));
                };
            };
        };
    };
};
var whileM = function (dictMonad) {
    return whileM$prime(dictMonad)(Control_Applicative.applicativeArray)(Data_Monoid.monoidArray);
};
var whileJust_ = function (dictMonad) {
    return function (p) {
        return function (f) {
            return Control_Bind.bind(dictMonad.Bind1())(p)(Data_Maybe.maybe(Control_Applicative.pure(dictMonad.Applicative0())(Data_Unit.unit))(function (v) {
                return Control_Bind.bind(dictMonad.Bind1())(f(v))(function (v1) {
                    return whileJust_(dictMonad)(p)(f);
                });
            }));
        };
    };
};
var whileJust$prime = function (dictMonad) {
    return function (dictApplicative) {
        return function (dictMonoid) {
            return function (p) {
                return function (f) {
                    return Control_Bind.bind(dictMonad.Bind1())(p)(Data_Maybe.maybe(Control_Applicative.pure(dictMonad.Applicative0())(Data_Monoid.mempty(dictMonoid)))(function (v) {
                        return Control_Bind.bind(dictMonad.Bind1())(f(v))(function (x) {
                            return Control_Bind.bind(dictMonad.Bind1())(whileJust$prime(dictMonad)(dictApplicative)(dictMonoid)(p)(f))((function () {
                                var $97 = Control_Applicative.pure(dictMonad.Applicative0());
                                return function ($98) {
                                    return $97((function (v1) {
                                        return Data_Semigroup.append(dictMonoid.Semigroup0())(Control_Applicative.pure(dictApplicative)(x))(v1);
                                    })($98));
                                };
                            })());
                        });
                    }));
                };
            };
        };
    };
};
var whileJust = function (dictMonad) {
    return whileJust$prime(dictMonad)(Control_Applicative.applicativeArray)(Data_Monoid.monoidArray);
};
var untilM_ = function (dictMonad) {
    return function (f) {
        return function (p) {
            return Control_Bind.bind(dictMonad.Bind1())(f)(function (v) {
                return Control_Bind.bind(dictMonad.Bind1())(p)(function (v1) {
                    if (v1) {
                        return untilM_(dictMonad)(f)(p);
                    };
                    return Control_Applicative.pure(dictMonad.Applicative0())(Data_Unit.unit);
                });
            });
        };
    };
};
var untilM$prime = function (dictMonad) {
    return function (dictApplicative) {
        return function (dictMonoid) {
            return function (f) {
                return function (p) {
                    var loop = function (acc) {
                        return Control_Bind.bind(dictMonad.Bind1())(Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())((function () {
                            var $99 = Control_Applicative.pure(dictApplicative);
                            return function ($100) {
                                return (function (v) {
                                    return Data_Semigroup.append(dictMonoid.Semigroup0())(v)(acc);
                                })($99($100));
                            };
                        })())(f))(function (acc$prime) {
                            return Control_Bind.bind(dictMonad.Bind1())(p)(function (v) {
                                if (v) {
                                    return Control_Applicative.pure(dictMonad.Applicative0())(acc$prime);
                                };
                                return loop(acc$prime);
                            });
                        });
                    };
                    return loop(Data_Monoid.mempty(dictMonoid));
                };
            };
        };
    };
};
var untilM = function (dictMonad) {
    return untilM$prime(dictMonad)(Control_Applicative.applicativeArray)(Data_Monoid.monoidArray);
};
var untilJust = function (dictMonad) {
    return function (m) {
        return Control_Bind.bind(dictMonad.Bind1())(m)(function (x) {
            return Data_Maybe.maybe(untilJust(dictMonad)(m))(Control_Applicative.pure(dictMonad.Applicative0()))(x);
        });
    };
};
var unfoldrM$prime = function (dictMonad) {
    return function (dictApplicative) {
        return function (dictMonoid) {
            return function (f) {
                var go = function (z) {
                    return Control_Bind.bind(dictMonad.Bind1())(f(z))(Data_Maybe.maybe(Control_Applicative.pure(dictMonad.Applicative0())(Data_Monoid.mempty(dictMonoid)))(function (v) {
                        return Control_Bind.bind(dictMonad.Bind1())(go(v.value1))((function () {
                            var $101 = Control_Applicative.pure(dictMonad.Applicative0());
                            return function ($102) {
                                return $101((function (v1) {
                                    return Data_Semigroup.append(dictMonoid.Semigroup0())(Control_Applicative.pure(dictApplicative)(v.value0))(v1);
                                })($102));
                            };
                        })());
                    }));
                };
                return go;
            };
        };
    };
};
var unfoldrM = function (dictMonad) {
    return unfoldrM$prime(dictMonad)(Control_Applicative.applicativeArray)(Data_Monoid.monoidArray);
};
var unfoldM_ = function (dictMonad) {
    return Data_Function.flip(whileJust_(dictMonad))(Control_Applicative.pure(dictMonad.Applicative0()));
};
var unfoldM$prime = function (dictMonad) {
    return function (dictApplicative) {
        return function (dictMonoid) {
            return Data_Function.flip(whileJust$prime(dictMonad)(dictApplicative)(dictMonoid))(Control_Applicative.pure(dictMonad.Applicative0()));
        };
    };
};
var unfoldM = function (dictMonad) {
    return unfoldM$prime(dictMonad)(Control_Applicative.applicativeArray)(Data_Monoid.monoidArray);
};
var orM = function (dictMonad) {
    return function (v) {
        if (v instanceof Data_List_Types.Nil) {
            return Control_Applicative.pure(dictMonad.Applicative0())(false);
        };
        if (v instanceof Data_List_Types.Cons) {
            return Control_Bind.bind(dictMonad.Bind1())(v.value0)(function (v1) {
                if (v1) {
                    return Control_Applicative.pure(dictMonad.Applicative0())(true);
                };
                return orM(dictMonad)(v.value1);
            });
        };
        throw new Error("Failed pattern match at Control.Monad.Loops (line 146, column 1 - line 146, column 58): " + [ v.constructor.name ]);
    };
};
var iterateUntilM = function (dictMonad) {
    return function (p) {
        return function (f) {
            return function (v) {
                var $72 = p(v);
                if ($72) {
                    return Control_Applicative.pure(dictMonad.Applicative0())(v);
                };
                return Control_Bind.bind(dictMonad.Bind1())(f(v))(iterateUntilM(dictMonad)(p)(f));
            };
        };
    };
};
var iterateUntil = function (dictMonad) {
    return function (p) {
        return function (x) {
            return Control_Bind.bind(dictMonad.Bind1())(x)(iterateUntilM(dictMonad)(p)(Data_Function["const"](x)));
        };
    };
};
var iterateWhile = function (dictMonad) {
    return function (p) {
        return iterateUntil(dictMonad)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(p));
    };
};
var anyPM = function (dictMonad) {
    return function (v) {
        return function (v1) {
            if (v instanceof Data_List_Types.Nil) {
                return Control_Applicative.pure(dictMonad.Applicative0())(false);
            };
            if (v instanceof Data_List_Types.Cons) {
                return Control_Bind.bind(dictMonad.Bind1())(v.value0(v1))(function (v2) {
                    if (v2) {
                        return Control_Applicative.pure(dictMonad.Applicative0())(true);
                    };
                    return anyPM(dictMonad)(v.value1)(v1);
                });
            };
            throw new Error("Failed pattern match at Control.Monad.Loops (line 153, column 1 - line 153, column 74): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var anyM = function (dictMonad) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Data_List_Types.Nil) {
                return Control_Applicative.pure(dictMonad.Applicative0())(false);
            };
            if (v1 instanceof Data_List_Types.Cons) {
                return Control_Bind.bind(dictMonad.Bind1())(v(v1.value0))(function (v2) {
                    if (v2) {
                        return Control_Applicative.pure(dictMonad.Applicative0())(true);
                    };
                    return anyM(dictMonad)(v)(v1.value1);
                });
            };
            throw new Error("Failed pattern match at Control.Monad.Loops (line 165, column 1 - line 165, column 71): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var andM = function (dictMonad) {
    return function (v) {
        if (v instanceof Data_List_Types.Nil) {
            return Control_Applicative.pure(dictMonad.Applicative0())(true);
        };
        if (v instanceof Data_List_Types.Cons) {
            return Control_Bind.bind(dictMonad.Bind1())(v.value0)(function (v1) {
                if (v1) {
                    return andM(dictMonad)(v.value1);
                };
                return Control_Applicative.pure(dictMonad.Applicative0())(false);
            });
        };
        throw new Error("Failed pattern match at Control.Monad.Loops (line 141, column 1 - line 141, column 61): " + [ v.constructor.name ]);
    };
};
var allPM = function (dictMonad) {
    return function (v) {
        return function (v1) {
            if (v instanceof Data_List_Types.Nil) {
                return Control_Applicative.pure(dictMonad.Applicative0())(true);
            };
            if (v instanceof Data_List_Types.Cons) {
                return Control_Bind.bind(dictMonad.Bind1())(v.value0(v1))(function (v2) {
                    if (v2) {
                        return allPM(dictMonad)(v.value1)(v1);
                    };
                    return Control_Applicative.pure(dictMonad.Applicative0())(false);
                });
            };
            throw new Error("Failed pattern match at Control.Monad.Loops (line 160, column 1 - line 160, column 74): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var allM = function (dictMonad) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Data_List_Types.Nil) {
                return Control_Applicative.pure(dictMonad.Applicative0())(true);
            };
            if (v1 instanceof Data_List_Types.Cons) {
                return Control_Bind.bind(dictMonad.Bind1())(v(v1.value0))(function (v2) {
                    if (v2) {
                        return allM(dictMonad)(v)(v1.value1);
                    };
                    return Control_Applicative.pure(dictMonad.Applicative0())(false);
                });
            };
            throw new Error("Failed pattern match at Control.Monad.Loops (line 170, column 1 - line 170, column 71): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
module.exports = {
    whileM: whileM,
    "whileM'": whileM$prime,
    whileM_: whileM_,
    untilM: untilM,
    "untilM'": untilM$prime,
    untilM_: untilM_,
    iterateWhile: iterateWhile,
    iterateUntil: iterateUntil,
    iterateUntilM: iterateUntilM,
    whileJust: whileJust,
    "whileJust'": whileJust$prime,
    whileJust_: whileJust_,
    untilJust: untilJust,
    unfoldM: unfoldM,
    "unfoldM'": unfoldM$prime,
    unfoldM_: unfoldM_,
    unfoldrM: unfoldrM,
    "unfoldrM'": unfoldrM$prime,
    andM: andM,
    orM: orM,
    anyPM: anyPM,
    allPM: allPM,
    anyM: anyM,
    allM: allM
};
