// Generated by purs version 0.13.6
"use strict";
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var extend = function (dictEncodeJson) {
    return function (v) {
        var $11 = Data_Argonaut_Core.caseJsonObject(Data_Argonaut_Core.jsonSingletonObject(v.value0)(v.value1))((function () {
            var $14 = Foreign_Object.insert(v.value0)(v.value1);
            return function ($15) {
                return Data_Argonaut_Core.fromObject($14($15));
            };
        })());
        var $12 = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
        return function ($13) {
            return $11($12($13));
        };
    };
};
var extendOptional = function (dictEncodeJson) {
    return function (v) {
        if (v instanceof Data_Maybe.Just) {
            return extend(dictEncodeJson)(v.value0);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
        };
        throw new Error("Failed pattern match at Data.Argonaut.Encode.Combinators (line 54, column 1 - line 54, column 83): " + [ v.constructor.name ]);
    };
};
var assoc = function (dictEncodeJson) {
    return function (k) {
        var $16 = Data_Tuple.Tuple.create(k);
        var $17 = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
        return function ($18) {
            return $16($17($18));
        };
    };
};
var assocOptional = function (dictEncodeJson) {
    return function (k) {
        return Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $19 = assoc(Data_Argonaut_Encode_Class.encodeJsonJson)(k);
            var $20 = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
            return function ($21) {
                return $19($20($21));
            };
        })());
    };
};
module.exports = {
    assoc: assoc,
    assocOptional: assocOptional,
    extend: extend,
    extendOptional: extendOptional
};
