// Generated by purs version 0.13.6
"use strict";
var Concur_Core = require("../Concur.Core/index.js");
var Concur_Core_LiftWidget = require("../Concur.Core.LiftWidget/index.js");
var Concur_Core_Props = require("../Concur.Core.Props/index.js");
var Control_MultiAlternative = require("../Control.MultiAlternative/index.js");
var Control_ShiftMap = require("../Control.ShiftMap/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var elLeaf = function (dictLiftWidget) {
    return function (dictFunctor) {
        return function (e) {
            return function (props) {
                return Concur_Core_LiftWidget.liftWidget(dictLiftWidget)(Concur_Core.mkLeafWidget(function (h) {
                    return e(Data_Functor.map(dictFunctor)(Concur_Core_Props.mkProp(h))(props));
                }));
            };
        };
    };
};
var el = function (dictShiftMap) {
    return function (dictFunctor) {
        return function (e) {
            return function (props) {
                return Control_ShiftMap.shiftMap(dictShiftMap)(function (f) {
                    return function (w) {
                        return Concur_Core.mkNodeWidget(function (h) {
                            return function (v) {
                                return e(Data_Functor.map(dictFunctor)((function () {
                                    var $7 = Concur_Core_Props.mkProp(h);
                                    var $8 = Data_Functor.map(Concur_Core_Props.functorProps)(f);
                                    return function ($9) {
                                        return $7($8($9));
                                    };
                                })())(props))(v);
                            };
                        })(w);
                    };
                });
            };
        };
    };
};
var el$prime = function (dictShiftMap) {
    return function (dictMultiAlternative) {
        return function (dictFunctor) {
            return function (e) {
                return function (props) {
                    var $10 = el(dictShiftMap)(dictFunctor)(e)(props);
                    var $11 = Control_MultiAlternative.orr(dictMultiAlternative);
                    return function ($12) {
                        return $10($11($12));
                    };
                };
            };
        };
    };
};
module.exports = {
    el: el,
    elLeaf: elLeaf,
    "el'": el$prime
};
