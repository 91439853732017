// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var elaborateFailure = function (s) {
    return function (e) {
        var msg = function (m) {
            return "Failed to decode key '" + (s + ("': " + m));
        };
        return Data_Bifunctor.lmap(Data_Either.bifunctorEither)(msg)(e);
    };
};
var getField = function (dictDecodeJson) {
    return function (o) {
        return function (s) {
            return Data_Maybe.maybe(Data_Either.Left.create("Expected field " + Data_Show.show(Data_Show.showString)(s)))((function () {
                var $9 = elaborateFailure(s);
                var $10 = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
                return function ($11) {
                    return $9($10($11));
                };
            })())(Foreign_Object.lookup(s)(o));
        };
    };
};
var getFieldDeprecated = function (dictWarn) {
    return function (dictDecodeJson) {
        return getField(dictDecodeJson);
    };
};
var getFieldOptional = function (dictDecodeJson) {
    return function (o) {
        return function (s) {
            var decode = function (json) {
                return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(elaborateFailure(s)(Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson)(json)));
            };
            return Data_Maybe.maybe(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value))(decode)(Foreign_Object.lookup(s)(o));
        };
    };
};
var getFieldOptionalDeprecated = function (dictWarn) {
    return function (dictDecodeJson) {
        return getFieldOptional(dictDecodeJson);
    };
};
var getFieldOptional$prime = function (dictDecodeJson) {
    return function (o) {
        return function (s) {
            var decode = function (json) {
                var $8 = Data_Argonaut_Core.isNull(json);
                if ($8) {
                    return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
                };
                return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(elaborateFailure(s)(Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson)(json)));
            };
            return Data_Maybe.maybe(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value))(decode)(Foreign_Object.lookup(s)(o));
        };
    };
};
var defaultField = function (parser) {
    return function ($$default) {
        return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.fromMaybe($$default))(parser);
    };
};
var defaultFieldDeprecated = function (dictWarn) {
    return defaultField;
};
module.exports = {
    getField: getField,
    getFieldDeprecated: getFieldDeprecated,
    getFieldOptional: getFieldOptional,
    getFieldOptionalDeprecated: getFieldOptionalDeprecated,
    "getFieldOptional'": getFieldOptional$prime,
    defaultField: defaultField,
    defaultFieldDeprecated: defaultFieldDeprecated
};
