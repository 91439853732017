// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_AVar = require("../Effect.AVar/index.js");
var Effect_Aff_AVar = require("../Effect.Aff.AVar/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var StateSubscription = (function () {
    function StateSubscription(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    StateSubscription.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new StateSubscription(value0, value1, value2);
            };
        };
    };
    return StateSubscription;
})();
var unsubscribe = function (v) {
    return function __do() {
        $foreign.unsubscribeDevTools(v.value1)();
        return Effect_AVar.kill(Effect_Exception.error("Unsubscribed"))(v.value2)();
    };
};
var subscribe = function (conn) {
    return function __do() {
        var v = Effect_AVar.empty();
        var subs = $foreign.subscribeDevTools(conn)(function (st) {
            return function __do() {
                Effect_AVar.tryPut(st)(v)();
                return Data_Unit.unit;
            };
        })();
        return new StateSubscription(conn, subs, v);
    };
};
var sendState = function (v) {
    return function (label) {
        return function (st) {
            return $foreign.sendToDevTools(v.value0)(label)(st);
        };
    };
};
var awaitState = function (v) {
    return Effect_Aff_AVar.take(v.value2);
};
var withStateful = function (dictMonadAff) {
    return function (dictAlt) {
        return function (subs) {
            return function (label) {
                return function (axn) {
                    return Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(Control_Alt.alt(dictAlt)(Data_Functor.map(dictAlt.Functor0())(Data_Either.Left.create)(axn))(Data_Functor.map(dictAlt.Functor0())(Data_Either.Right.create)(Effect_Aff_Class.liftAff(dictMonadAff)(awaitState(subs)))))(function (est) {
                        if (est instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(sendState(subs)(label)(est.value0)))(function () {
                                return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(est.value0);
                            });
                        };
                        if (est instanceof Data_Either.Right) {
                            return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(est.value0);
                        };
                        throw new Error("Failed pattern match at Concur.Core.DevTools (line 65, column 3 - line 69, column 24): " + [ est.constructor.name ]);
                    });
                };
            };
        };
    };
};
module.exports = {
    StateSubscription: StateSubscription,
    subscribe: subscribe,
    unsubscribe: unsubscribe,
    awaitState: awaitState,
    sendState: sendState,
    withStateful: withStateful,
    connectDevTools: $foreign.connectDevTools,
    disconnectDevTools: $foreign.disconnectDevTools,
    sendToDevTools: $foreign.sendToDevTools,
    subscribeDevTools: $foreign.subscribeDevTools,
    unsubscribeDevTools: $foreign.unsubscribeDevTools
};
