// Generated by purs version 0.13.6
"use strict";
var Assets = require("../Assets/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Decode_Combinators = require("../Data.Argonaut.Decode.Combinators/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Argonaut_Encode_Combinators = require("../Data.Argonaut.Encode.Combinators/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Extra = require("../Data.Functor.Extra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_NonEmpty_CodeUnits = require("../Data.String.NonEmpty.CodeUnits/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Effect_Random_Extra = require("../Effect.Random.Extra/index.js");
var Sounds = require("../Sounds/index.js");
var Word = function (x1) {
    return x1;
};
var Letter = function (x1) {
    return x1;
};
var Letters = function (x1) {
    return x1;
};
var wordNewtype = new Data_Newtype.Newtype(function (n1) {
    return n1;
}, Word);
var wordEq = new Data_Eq.Eq(function (x1) {
    return function (y1) {
        return Data_Eq.eq(Data_String_NonEmpty_Internal.eqNonEmptyString)(x1)(y1);
    };
});
var word = function (v1) {
    return Data_String_Common.toUpper(Data_String_NonEmpty_Internal.toString(Data_Newtype.unwrap(wordNewtype)(v1.word)));
};
var withFrequency = function (v1) {
    return new Data_Tuple.Tuple(v1.frequency, v1);
};
var sound = function (v1) {
    return v1.sound;
};
var mkLetter = function (word$prime) {
    return function (asset$prime) {
        return function (sound$prime) {
            return {
                word: Data_Newtype.wrap(wordNewtype)(word$prime),
                asset: asset$prime,
                frequency: 2.0,
                sound: sound$prime
            };
        };
    };
};
var n = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Nase";
})))(Data_Symbol.SProxy.value))(Assets.nose)(Data_Maybe.Nothing.value);
var o = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Ohr";
})))(Data_Symbol.SProxy.value))(Assets.ear)(Data_Maybe.Nothing.value);
var p = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Papa";
})))(Data_Symbol.SProxy.value))(Assets.papa)(Data_Maybe.Nothing.value);
var q = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Quack";
})))(Data_Symbol.SProxy.value))(Assets.quack)(new Data_Maybe.Just(Sounds.Quack.value));
var r = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Rakete";
})))(Data_Symbol.SProxy.value))(Assets.rocket)(Data_Maybe.Nothing.value);
var s = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Stern";
})))(Data_Symbol.SProxy.value))(Assets.star)(Data_Maybe.Nothing.value);
var t = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Tanze";
})))(Data_Symbol.SProxy.value))(Assets.dancing)(Data_Maybe.Nothing.value);
var u = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Uhu";
})))(Data_Symbol.SProxy.value))(Assets.uhu)(Data_Maybe.Nothing.value);
var v = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Velo";
})))(Data_Symbol.SProxy.value))(Assets.bicycle)(Data_Maybe.Nothing.value);
var w = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Winter";
})))(Data_Symbol.SProxy.value))(Assets.winter)(Data_Maybe.Nothing.value);
var x = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Xylophone";
})))(Data_Symbol.SProxy.value))(Assets.xylophone)(new Data_Maybe.Just(Sounds.Xylophone.value));
var y = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Yak";
})))(Data_Symbol.SProxy.value))(Assets.yak)(Data_Maybe.Nothing.value);
var z = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Zug";
})))(Data_Symbol.SProxy.value))(Assets.train)(Data_Maybe.Nothing.value);
var m = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Mama";
})))(Data_Symbol.SProxy.value))(Assets.mama)(Data_Maybe.Nothing.value);
var lettersNewtype = new Data_Newtype.Newtype(function (n1) {
    return n1;
}, Letters);
var letterEq = new Data_Eq.Eq(function (v1) {
    return function (v2) {
        return Data_Eq.eq(wordEq)(v1.word)(v2.word);
    };
});
var l = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Lache";
})))(Data_Symbol.SProxy.value))(Assets.laugh)(Data_Maybe.Nothing.value);
var k = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Karate";
})))(Data_Symbol.SProxy.value))(Assets.karate)(Data_Maybe.Nothing.value);
var j = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "J\xe4ger";
})))(Data_Symbol.SProxy.value))(Assets.hunter)(Data_Maybe.Nothing.value);
var i = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Igel";
})))(Data_Symbol.SProxy.value))(Assets.hedgehog)(Data_Maybe.Nothing.value);
var h = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Hund";
})))(Data_Symbol.SProxy.value))(Assets.dog)(Data_Maybe.Nothing.value);
var g = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Giraffe";
})))(Data_Symbol.SProxy.value))(Assets.giraffe)(Data_Maybe.Nothing.value);
var f = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Fuchs";
})))(Data_Symbol.SProxy.value))(Assets.fox)(Data_Maybe.Nothing.value);
var encodeJsonWord = new Data_Argonaut_Encode_Class.EncodeJson((function () {
    var $57 = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonJString);
    var $58 = Data_Newtype.unwrap(wordNewtype);
    return function ($59) {
        return $57(Data_String_NonEmpty_Internal.toString($58($59)));
    };
})());
var encodeJsonLetter = new Data_Argonaut_Encode_Class.EncodeJson(function (v1) {
    return Data_Argonaut_Encode_Combinators.extend(Data_Argonaut_Encode_Class.encodeJsonJson)(Data_Argonaut_Encode_Combinators.assoc(encodeJsonWord)("word")(v1.word))(Data_Argonaut_Encode_Combinators.extend(Data_Argonaut_Encode_Class.encodeJsonJson)(Data_Argonaut_Encode_Combinators.assoc(Assets.encodeJsonAsset)("asset")(v1.asset))(Data_Argonaut_Encode_Combinators.extend(Data_Argonaut_Encode_Class.encodeJsonJson)(Data_Argonaut_Encode_Combinators.assoc(Data_Argonaut_Encode_Class.encodeJsonJNumber)("frequency")(v1.frequency))(Data_Argonaut_Encode_Combinators.extend(Data_Argonaut_Encode_Class.encodeJsonJson)(Data_Argonaut_Encode_Combinators.assoc(Data_Argonaut_Encode_Class.encodeJsonMaybe(Sounds.encodeJsonKey))("sound")(v1.sound))(Data_Argonaut_Core.jsonEmptyObject))));
});
var encodeJsonLetters = new Data_Argonaut_Encode_Class.EncodeJson((function () {
    var $60 = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonArray(encodeJsonLetter));
    var $61 = Data_Newtype.unwrap(lettersNewtype);
    return function ($62) {
        return $60(Data_Array_NonEmpty.toArray($61($62)));
    };
})());
var e = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Elch";
})))(Data_Symbol.SProxy.value))(Assets.elk)(Data_Maybe.Nothing.value);
var decodeJsonWord = new Data_Argonaut_Decode_Class.DecodeJson(function (json) {
    var v1 = Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeJsonString)(json);
    if (v1 instanceof Data_Either.Right) {
        var v2 = Data_String_NonEmpty_Internal.fromString(v1.value0);
        if (v2 instanceof Data_Maybe.Just) {
            return Data_Either.Right.create(Data_Newtype.wrap(wordNewtype)(v2.value0));
        };
        if (v2 instanceof Data_Maybe.Nothing) {
            return new Data_Either.Left("Word can't be empty");
        };
        throw new Error("Failed pattern match at Letter (line 96, column 16 - line 98, column 44): " + [ v2.constructor.name ]);
    };
    if (v1 instanceof Data_Either.Left) {
        return new Data_Either.Left(v1.value0);
    };
    throw new Error("Failed pattern match at Letter (line 95, column 21 - line 99, column 25): " + [ v1.constructor.name ]);
});
var decodeJsonLetter = new Data_Argonaut_Decode_Class.DecodeJson(function (json) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeForeignObject(Data_Argonaut_Decode_Class.decodeJsonJson))(json))(function (x1) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Argonaut_Decode_Combinators.getField(decodeJsonWord)(x1)("word"))(function (word1) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Argonaut_Decode_Combinators.getField(Assets.decodeJsonAsset)(x1)("asset"))(function (asset1) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonNumber)(x1)("frequency"))(function (frequency) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Argonaut_Decode_Combinators.getField(Data_Argonaut_Decode_Class.decodeJsonMaybe(Sounds.decodeJsonKey))(x1)("sound"))(function (sound1) {
                        return Control_Applicative.pure(Data_Either.applicativeEither)({
                            word: word1,
                            asset: asset1,
                            frequency: frequency,
                            sound: sound1
                        });
                    });
                });
            });
        });
    });
});
var decodeJsonLetters = new Data_Argonaut_Decode_Class.DecodeJson(function (json) {
    var v1 = Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeArray(decodeJsonLetter))(json);
    if (v1 instanceof Data_Either.Right) {
        var v2 = Data_Array_NonEmpty.fromArray(v1.value0);
        if (v2 instanceof Data_Maybe.Just) {
            return Data_Either.Right.create(Data_Newtype.wrap(lettersNewtype)(v2.value0));
        };
        if (v2 instanceof Data_Maybe.Nothing) {
            return new Data_Either.Left("Letters can't be empty");
        };
        throw new Error("Failed pattern match at Letter (line 48, column 16 - line 50, column 47): " + [ v2.constructor.name ]);
    };
    if (v1 instanceof Data_Either.Left) {
        return new Data_Either.Left(v1.value0);
    };
    throw new Error("Failed pattern match at Letter (line 47, column 21 - line 51, column 25): " + [ v1.constructor.name ]);
});
var d = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Delfin";
})))(Data_Symbol.SProxy.value))(Assets.dolphin)(Data_Maybe.Nothing.value);
var character = function (v1) {
    return Data_String_CodeUnits.singleton((Data_String_NonEmpty_CodeUnits.uncons(Data_Newtype.unwrap(wordNewtype)(v1.word))).head);
};
var characterFrequencies = function (v1) {
    return Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v2) {
        return {
            "char": character(v2),
            freq: v2.frequency
        };
    })(v1);
};
var find = function (str) {
    return Data_Foldable.find(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(function ($63) {
        return (function (v1) {
            return v1 === Data_String_Common.toUpper(str);
        })(character($63));
    });
};
var sameLetter = function (a$prime) {
    return function (b$prime) {
        return character(a$prime) === character(b$prime);
    };
};
var c = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Clown";
})))(Data_Symbol.SProxy.value))(Assets.clown)(Data_Maybe.Nothing.value);
var b = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "B\xe4r";
})))(Data_Symbol.SProxy.value))(Assets.bear)(Data_Maybe.Nothing.value);
var asset = function (v1) {
    return Assets["for"](v1.asset);
};
var adjustFrequency$prime = function (f1) {
    return function (v1) {
        return {
            word: v1.word,
            asset: v1.asset,
            frequency: Data_Ord.clamp(Data_Ord.ordNumber)(1.0)(8.0)(f1(v1.frequency)),
            sound: v1.sound
        };
    };
};
var adjustFrequency = function (f1) {
    return function (pred) {
        var $64 = Data_Newtype.wrap(lettersNewtype);
        var $65 = Data_Functor_Extra.updateIf(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(pred)(adjustFrequency$prime(f1));
        var $66 = Data_Newtype.unwrap(lettersNewtype);
        return function ($67) {
            return $64($65($66($67)));
        };
    };
};
var a = mkLetter(Data_String_NonEmpty_Internal.nes(Data_String_NonEmpty_Internal.nonEmptyNonEmpty(new Data_Symbol.IsSymbol(function () {
    return "Aff";
})))(Data_Symbol.SProxy.value))(Assets.monkey)(Data_Maybe.Nothing.value);
var all = Data_Array_NonEmpty["cons'"](a)([ b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z ]);
var random = function (x$prime) {
    return function __do() {
        var letters = Data_Functor.map(Effect.functorEffect)(Data_Maybe.fromMaybe(Data_Array_NonEmpty.singleton(a)))(Effect_Random_Extra.randomUniqElements(letterEq)(3)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(withFrequency)(x$prime)))();
        var correct = Effect_Random_Extra.randomWeighted(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(withFrequency)(letters))();
        return {
            correct: correct,
            letters: letters
        };
    };
};
module.exports = {
    all: all,
    character: character,
    word: word,
    sound: sound,
    asset: asset,
    adjustFrequency: adjustFrequency,
    random: random,
    find: find,
    sameLetter: sameLetter,
    characterFrequencies: characterFrequencies,
    lettersNewtype: lettersNewtype,
    decodeJsonLetters: decodeJsonLetters,
    encodeJsonLetters: encodeJsonLetters,
    letterEq: letterEq,
    decodeJsonLetter: decodeJsonLetter,
    encodeJsonLetter: encodeJsonLetter
};
