// Generated by purs version 0.13.6
"use strict";
var Concur_React = require("../Concur.React/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ReactDOM = require("../ReactDOM/index.js");
var Web_DOM_NonElementParentNode = require("../Web.DOM.NonElementParentNode/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var runWidgetInDom = function (elemId) {
    return function (w) {
        return function __do() {
            var win = Web_HTML.window();
            var doc = Web_HTML_Window.document(win)();
            var node = Web_HTML_HTMLDocument.toNonElementParentNode(doc);
            var mroot = Web_DOM_NonElementParentNode.getElementById(elemId)(node)();
            if (mroot instanceof Data_Maybe.Nothing) {
                return Data_Unit.unit;
            };
            if (mroot instanceof Data_Maybe.Just) {
                return Data_Functor["void"](Effect.functorEffect)(ReactDOM.render(Concur_React.renderComponent(w))(mroot.value0))();
            };
            throw new Error("Failed pattern match at Concur.React.Run (line 23, column 3 - line 25, column 65): " + [ mroot.constructor.name ]);
        };
    };
};
module.exports = {
    runWidgetInDom: runWidgetInDom
};
