// Generated by purs version 0.13.6
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_RWS_Trans = require("../Control.Monad.RWS.Trans/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Control_Monad_Writer_Trans = require("../Control.Monad.Writer.Trans/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var MultiAlternative = function (Plus0, orr) {
    this.Plus0 = Plus0;
    this.orr = orr;
};
var orr = function (dict) {
    return dict.orr;
};
var readerMultiAlternative = function (dictMultiAlternative) {
    return new MultiAlternative(function () {
        return Control_Monad_Reader_Trans.plusReaderT(dictMultiAlternative.Plus0());
    }, function (rs) {
        return function (r) {
            return orr(dictMultiAlternative)(Data_Functor.map(Data_Functor.functorArray)(function (m) {
                return Data_Newtype.unwrap(Control_Monad_Reader_Trans.newtypeReaderT)(m)(r);
            })(rs));
        };
    });
};
var rwsMultiAlternative = function (dictMultiAlternative) {
    return new MultiAlternative(function () {
        return Control_Monad_RWS_Trans.plusRWST(dictMultiAlternative.Plus0());
    }, function (rs) {
        return function (r) {
            return function (s) {
                return orr(dictMultiAlternative)(Data_Functor.map(Data_Functor.functorArray)(function (m) {
                    return Data_Newtype.unwrap(Control_Monad_RWS_Trans.newtypeRWST)(m)(r)(s);
                })(rs));
            };
        };
    });
};
var stateMultiAlternative = function (dictMultiAlternative) {
    return function (dictMonad) {
        return new MultiAlternative(function () {
            return Control_Monad_State_Trans.plusStateT(dictMonad)(dictMultiAlternative.Plus0());
        }, function (rs) {
            return function (s) {
                return orr(dictMultiAlternative)(Data_Functor.map(Data_Functor.functorArray)(function (m) {
                    return Data_Newtype.unwrap(Control_Monad_State_Trans.newtypeStateT)(m)(s);
                })(rs));
            };
        });
    };
};
var writerMultiAlternative = function (dictMultiAlternative) {
    return new MultiAlternative(function () {
        return Control_Monad_Writer_Trans.plusWriterT(dictMultiAlternative.Plus0());
    }, function (rs) {
        return Control_Monad_Writer_Trans.WriterT(orr(dictMultiAlternative)(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap(Control_Monad_Writer_Trans.newtypeWriterT))(rs)));
    });
};
var defaultOrr = function (dictPlus) {
    return function (xs) {
        return Data_Foldable.foldl(Data_Foldable.foldableArray)(Control_Alt.alt(dictPlus.Alt0()))(Control_Plus.empty(dictPlus))(xs);
    };
};
var exceptMultiAlternative = function (dictMonoid) {
    return function (dictMultiAlternative) {
        return function (dictMonad) {
            return new MultiAlternative(function () {
                return Control_Monad_Except_Trans.plusExceptT(dictMonoid)(dictMonad);
            }, defaultOrr(Control_Monad_Except_Trans.plusExceptT(dictMonoid)(dictMonad)));
        };
    };
};
module.exports = {
    orr: orr,
    MultiAlternative: MultiAlternative,
    defaultOrr: defaultOrr,
    exceptMultiAlternative: exceptMultiAlternative,
    rwsMultiAlternative: rwsMultiAlternative,
    readerMultiAlternative: readerMultiAlternative,
    stateMultiAlternative: stateMultiAlternative,
    writerMultiAlternative: writerMultiAlternative
};
