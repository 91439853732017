// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Effect = require("../Effect/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var unmountComponentAtNode = Effect_Uncurried.runEffectFn1($foreign.unmountComponentAtNodeImpl);
var renderToString = Data_Function_Uncurried.runFn1($foreign.renderToStringImpl);
var renderToStaticMarkup = Data_Function_Uncurried.runFn1($foreign.renderToStaticMarkupImpl);
var render = function (rEl) {
    return function (el) {
        return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(function () {
            return $foreign.renderImpl(rEl, el);
        });
    };
};
var hydrate = function (rEl) {
    return function (el) {
        return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(function () {
            return $foreign.hydrateImpl(rEl, el);
        });
    };
};
var findDOMNode = Effect_Uncurried.runEffectFn1($foreign.findDOMNodeImpl);
module.exports = {
    render: render,
    hydrate: hydrate,
    unmountComponentAtNode: unmountComponentAtNode,
    findDOMNode: findDOMNode,
    renderToString: renderToString,
    renderToStaticMarkup: renderToStaticMarkup
};
