// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Gain = (function () {
    function Gain(value0) {
        this.value0 = value0;
    };
    Gain.create = function (value0) {
        return new Gain(value0);
    };
    return Gain;
})();
var AudioBufferSource = (function () {
    function AudioBufferSource(value0) {
        this.value0 = value0;
    };
    AudioBufferSource.create = function (value0) {
        return new AudioBufferSource(value0);
    };
    return AudioBufferSource;
})();
var Oscillator = (function () {
    function Oscillator(value0) {
        this.value0 = value0;
    };
    Oscillator.create = function (value0) {
        return new Oscillator(value0);
    };
    return Oscillator;
})();
var BiquadFilter = (function () {
    function BiquadFilter(value0) {
        this.value0 = value0;
    };
    BiquadFilter.create = function (value0) {
        return new BiquadFilter(value0);
    };
    return BiquadFilter;
})();
var Delay = (function () {
    function Delay(value0) {
        this.value0 = value0;
    };
    Delay.create = function (value0) {
        return new Delay(value0);
    };
    return Delay;
})();
var Analyser = (function () {
    function Analyser(value0) {
        this.value0 = value0;
    };
    Analyser.create = function (value0) {
        return new Analyser(value0);
    };
    return Analyser;
})();
var StereoPanner = (function () {
    function StereoPanner(value0) {
        this.value0 = value0;
    };
    StereoPanner.create = function (value0) {
        return new StereoPanner(value0);
    };
    return StereoPanner;
})();
var DynamicsCompressor = (function () {
    function DynamicsCompressor(value0) {
        this.value0 = value0;
    };
    DynamicsCompressor.create = function (value0) {
        return new DynamicsCompressor(value0);
    };
    return DynamicsCompressor;
})();
var Convolver = (function () {
    function Convolver(value0) {
        this.value0 = value0;
    };
    Convolver.create = function (value0) {
        return new Convolver(value0);
    };
    return Convolver;
})();
var Panner = (function () {
    function Panner(value0) {
        this.value0 = value0;
    };
    Panner.create = function (value0) {
        return new Panner(value0);
    };
    return Panner;
})();
var Destination = (function () {
    function Destination(value0) {
        this.value0 = value0;
    };
    Destination.create = function (value0) {
        return new Destination(value0);
    };
    return Destination;
})();
var SUSPENDED = (function () {
    function SUSPENDED() {

    };
    SUSPENDED.value = new SUSPENDED();
    return SUSPENDED;
})();
var RUNNING = (function () {
    function RUNNING() {

    };
    RUNNING.value = new RUNNING();
    return RUNNING;
})();
var CLOSED = (function () {
    function CLOSED() {

    };
    CLOSED.value = new CLOSED();
    return CLOSED;
})();
var BALANCED = (function () {
    function BALANCED() {

    };
    BALANCED.value = new BALANCED();
    return BALANCED;
})();
var INTERACTIVE = (function () {
    function INTERACTIVE() {

    };
    INTERACTIVE.value = new INTERACTIVE();
    return INTERACTIVE;
})();
var PLAYBACK = (function () {
    function PLAYBACK() {

    };
    PLAYBACK.value = new PLAYBACK();
    return PLAYBACK;
})();
var RawAudioNode = {};
var Connectable = function (connect, connectParam, disconnect) {
    this.connect = connect;
    this.connectParam = connectParam;
    this.disconnect = disconnect;
};
var showAudioContextState = new Data_Show.Show(function (v) {
    if (v instanceof SUSPENDED) {
        return "suspended";
    };
    if (v instanceof RUNNING) {
        return "running";
    };
    if (v instanceof CLOSED) {
        return "closed";
    };
    throw new Error("Failed pattern match at Audio.WebAudio.Types (line 40, column 1 - line 43, column 25): " + [ v.constructor.name ]);
});
var showAudioContextPlaybackCategory = new Data_Show.Show(function (v) {
    if (v instanceof BALANCED) {
        return "balanced";
    };
    if (v instanceof INTERACTIVE) {
        return "interactive";
    };
    if (v instanceof PLAYBACK) {
        return "playback";
    };
    throw new Error("Failed pattern match at Audio.WebAudio.Types (line 47, column 1 - line 50, column 29): " + [ v.constructor.name ]);
});
var eqAudioPlaybackCategory = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof BALANCED && y instanceof BALANCED) {
            return true;
        };
        if (x instanceof INTERACTIVE && y instanceof INTERACTIVE) {
            return true;
        };
        if (x instanceof PLAYBACK && y instanceof PLAYBACK) {
            return true;
        };
        return false;
    };
});
var eqAudioContextState = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof SUSPENDED && y instanceof SUSPENDED) {
            return true;
        };
        if (x instanceof RUNNING && y instanceof RUNNING) {
            return true;
        };
        if (x instanceof CLOSED && y instanceof CLOSED) {
            return true;
        };
        return false;
    };
});
var disconnect = function (dict) {
    return dict.disconnect;
};
var connectParam = function (dict) {
    return dict.connectParam;
};
var connect = function (dict) {
    return dict.connect;
};
var audioNodeStereoPannerNode = RawAudioNode;
var connectableStereoPannerNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodePannerNode = RawAudioNode;
var connectablePannerNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeOscillatorNode = RawAudioNode;
var connectableOscillatorNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeMediaElementAudioSourceNode = RawAudioNode;
var audioNodeGainNode = RawAudioNode;
var connectableGainNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeDynamicsCompressorNode = RawAudioNode;
var connectableDynamicsCompressorNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeDynamicsCConvolverNode = RawAudioNode;
var connectableConvolverNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeDestinationNode = RawAudioNode;
var connectableDestinationNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeDelayNode = RawAudioNode;
var connectableDelayNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeBiquadFilterNode = RawAudioNode;
var connectableBiquadFilterNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var audioNodeAudioBufferSourceNodeconnectParam = RawAudioNode;
var audioNodeAnalyserNode = RawAudioNode;
var connectableAnalyserNode = new Connectable(function (dictRawAudioNode) {
    return $foreign.nodeConnect()();
}, function (dictRawAudioNode) {
    return $foreign.unsafeConnectParam()();
}, function (dictRawAudioNode) {
    return $foreign.nodeDisconnect()();
});
var connectableAudioNode = new Connectable(function (dictRawAudioNode) {
    return function (s) {
        return function (v) {
            if (v instanceof Gain) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof Oscillator) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof BiquadFilter) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof Delay) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof Analyser) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof StereoPanner) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof DynamicsCompressor) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof Convolver) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof Destination) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof Panner) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
    };
}, function (dictRawAudioNode) {
    return function (s) {
        return function (v) {
            return function (p) {
                if (v instanceof Gain) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof AudioBufferSource) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof Oscillator) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof BiquadFilter) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof Delay) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof Analyser) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof StereoPanner) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof DynamicsCompressor) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof Convolver) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                if (v instanceof Destination) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                if (v instanceof Panner) {
                    return $foreign.unsafeConnectParam()()(s)(v.value0)(p);
                };
                throw new Error("Failed pattern match at Audio.WebAudio.Types (line 132, column 1 - line 168, column 57): " + [ s.constructor.name, v.constructor.name, p.constructor.name ]);
            };
        };
    };
}, function (dictRawAudioNode) {
    return function (s) {
        return function (v) {
            if (v instanceof Gain) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof Oscillator) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof BiquadFilter) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof Delay) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof Analyser) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof StereoPanner) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof DynamicsCompressor) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof Convolver) {
                return $foreign.nodeDisconnect()()(s)(v.value0);
            };
            if (v instanceof Destination) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            if (v instanceof Panner) {
                return $foreign.nodeConnect()()(s)(v.value0);
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
    };
});
module.exports = {
    RawAudioNode: RawAudioNode,
    Connectable: Connectable,
    Gain: Gain,
    AudioBufferSource: AudioBufferSource,
    Oscillator: Oscillator,
    BiquadFilter: BiquadFilter,
    Delay: Delay,
    Analyser: Analyser,
    StereoPanner: StereoPanner,
    DynamicsCompressor: DynamicsCompressor,
    Convolver: Convolver,
    Panner: Panner,
    Destination: Destination,
    SUSPENDED: SUSPENDED,
    RUNNING: RUNNING,
    CLOSED: CLOSED,
    BALANCED: BALANCED,
    INTERACTIVE: INTERACTIVE,
    PLAYBACK: PLAYBACK,
    connect: connect,
    disconnect: disconnect,
    connectParam: connectParam,
    eqAudioContextState: eqAudioContextState,
    showAudioContextState: showAudioContextState,
    eqAudioPlaybackCategory: eqAudioPlaybackCategory,
    showAudioContextPlaybackCategory: showAudioContextPlaybackCategory,
    audioNodeAudioBufferSourceNodeconnectParam: audioNodeAudioBufferSourceNodeconnectParam,
    audioNodeMediaElementAudioSourceNode: audioNodeMediaElementAudioSourceNode,
    audioNodeGainNode: audioNodeGainNode,
    audioNodeDestinationNode: audioNodeDestinationNode,
    audioNodeOscillatorNode: audioNodeOscillatorNode,
    audioNodeBiquadFilterNode: audioNodeBiquadFilterNode,
    audioNodeDelayNode: audioNodeDelayNode,
    audioNodeAnalyserNode: audioNodeAnalyserNode,
    audioNodeStereoPannerNode: audioNodeStereoPannerNode,
    audioNodeDynamicsCompressorNode: audioNodeDynamicsCompressorNode,
    audioNodeDynamicsCConvolverNode: audioNodeDynamicsCConvolverNode,
    audioNodePannerNode: audioNodePannerNode,
    connectableGainNode: connectableGainNode,
    connectableOscillatorNode: connectableOscillatorNode,
    connectableBiquadFilterNode: connectableBiquadFilterNode,
    connectableDelayNode: connectableDelayNode,
    connectableAnalyserNode: connectableAnalyserNode,
    connectableStereoPannerNode: connectableStereoPannerNode,
    connectableDynamicsCompressorNode: connectableDynamicsCompressorNode,
    connectableConvolverNode: connectableConvolverNode,
    connectableDestinationNode: connectableDestinationNode,
    connectablePannerNode: connectablePannerNode,
    connectableAudioNode: connectableAudioNode
};
