// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Asset = function (x) {
    return x;
};
var yak = "Yak";
var xylophoneSound = "Xylophone-sound";
var xylophone = "Xylophone";
var winter = "Winter";
var uhu = "Uhu";
var train = "Train";
var tada = "tada";
var star = "Star";
var rocket = "Rocket";
var quackSound = "Quack-sound";
var quack = "Quack";
var papa = "Papa";
var nose = "Nose";
var nope = "nope";
var monkey = "Monkey";
var mama = "Mama";
var laugh = "Laugh";
var karate = "Karate";
var hunter = "Hunter";
var hedgehog = "Hedgehog";
var giraffe = "Giraffe";
var fox = "Fox";
var $$for = function (v) {
    return $foreign.forAsset(v);
};
var elk = "Elk";
var ear = "Ear";
var dolphin = "Dolphin";
var dog = "Dog";
var dancing = "Dancing";
var correct = "correct";
var clown = "Clown";
var bicycle = "Bicycle";
var bear = "Bear";
var assetNewtype = new Data_Newtype.Newtype(function (n) {
    return n;
}, Asset);
var decodeJsonAsset = new Data_Argonaut_Decode_Class.DecodeJson((function () {
    var $17 = Data_Functor.map(Data_Either.functorEither)(Data_Newtype.wrap(assetNewtype));
    var $18 = Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeJsonString);
    return function ($19) {
        return $17($18($19));
    };
})());
var encodeJsonAsset = new Data_Argonaut_Encode_Class.EncodeJson((function () {
    var $20 = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonJString);
    var $21 = Data_Newtype.unwrap(assetNewtype);
    return function ($22) {
        return $20($21($22));
    };
})());
var assetEq = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var assetOrd = new Data_Ord.Ord(function () {
    return assetEq;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordString)(x)(y);
    };
});
module.exports = {
    "for": $$for,
    tada: tada,
    nope: nope,
    correct: correct,
    bear: bear,
    bicycle: bicycle,
    clown: clown,
    dancing: dancing,
    dog: dog,
    ear: ear,
    elk: elk,
    fox: fox,
    giraffe: giraffe,
    hedgehog: hedgehog,
    hunter: hunter,
    karate: karate,
    dolphin: dolphin,
    laugh: laugh,
    mama: mama,
    monkey: monkey,
    nose: nose,
    papa: papa,
    quack: quack,
    quackSound: quackSound,
    rocket: rocket,
    star: star,
    train: train,
    uhu: uhu,
    winter: winter,
    xylophone: xylophone,
    xylophoneSound: xylophoneSound,
    yak: yak,
    assetEq: assetEq,
    assetOrd: assetOrd,
    assetNewtype: assetNewtype,
    decodeJsonAsset: decodeJsonAsset,
    encodeJsonAsset: encodeJsonAsset
};
