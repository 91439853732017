// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Data_Either = require("../Data.Either/index.js");
var jsonParser = function (j) {
    return $foreign["_jsonParser"](Data_Either.Left.create, Data_Either.Right.create, j);
};
module.exports = {
    jsonParser: jsonParser
};
