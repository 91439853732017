// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Record = require("../Record/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var GDecodeJson = function (gDecodeJson) {
    this.gDecodeJson = gDecodeJson;
};
var DecodeJson = function (decodeJson) {
    this.decodeJson = decodeJson;
};
var gDecodeJsonNil = new GDecodeJson(function (v) {
    return function (v1) {
        return new Data_Either.Right({});
    };
});
var gDecodeJson = function (dict) {
    return dict.gDecodeJson;
};
var decodeVoid = new DecodeJson(function (v) {
    return new Data_Either.Left("Value cannot be Void");
});
var decodeRecord = function (dictGDecodeJson) {
    return function (dictRowToList) {
        return new DecodeJson(function (json) {
            var v = Data_Argonaut_Core.toObject(json);
            if (v instanceof Data_Maybe.Just) {
                return gDecodeJson(dictGDecodeJson)(v.value0)(Type_Data_RowList.RLProxy.value);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new Data_Either.Left("Could not convert JSON to object");
            };
            throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 128, column 5 - line 130, column 61): " + [ v.constructor.name ]);
        });
    };
};
var decodeJsonString = new DecodeJson(Data_Argonaut_Core.caseJsonString(new Data_Either.Left("Value is not a String"))(Data_Either.Right.create));
var decodeJsonNumber = new DecodeJson(Data_Argonaut_Core.caseJsonNumber(new Data_Either.Left("Value is not a Number"))(Data_Either.Right.create));
var decodeJsonNull = new DecodeJson(Data_Argonaut_Core.caseJsonNull(new Data_Either.Left("Value is not a null"))(Data_Function["const"](new Data_Either.Right(Data_Unit.unit))));
var decodeJsonJson = new DecodeJson(Data_Either.Right.create);
var decodeJsonBoolean = new DecodeJson(Data_Argonaut_Core.caseJsonBoolean(new Data_Either.Left("Value is not a Boolean"))(Data_Either.Right.create));
var decodeJson = function (dict) {
    return dict.decodeJson;
};
var decodeJsonChar = new DecodeJson(function (j) {
    return Control_Bind.bindFlipped(Data_Either.bindEither)(Data_Maybe.maybe(Data_Either.Left.create("Expected character but found: " + Data_Argonaut_Core.stringify(j)))(Data_Either.Right.create))(Data_Functor.map(Data_Either.functorEither)(Data_String_CodePoints.codePointAt(0))(decodeJson(decodeJsonString)(j)));
});
var decodeJsonInt = new DecodeJson(Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
    var $54 = Data_Maybe.maybe(new Data_Either.Left("Value is not an integer"))(Data_Either.Right.create);
    return function ($55) {
        return $54(Data_Int.fromNumber($55));
    };
})())(decodeJson(decodeJsonNumber)));
var decodeJsonMaybe = function (dictDecodeJson) {
    return new DecodeJson(function (j) {
        if (Data_Argonaut_Core.isNull(j)) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
        };
        if (Data_Boolean.otherwise) {
            return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(decodeJson(dictDecodeJson)(j));
        };
        throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 30, column 1 - line 33, column 40): " + [ j.constructor.name ]);
    });
};
var gDecodeJsonCons = function (dictDecodeJson) {
    return function (dictGDecodeJson) {
        return function (dictIsSymbol) {
            return function (dictCons) {
                return function (dictLacks) {
                    return new GDecodeJson(function (object) {
                        return function (v) {
                            var fieldName = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                            return Control_Bind.bind(Data_Either.bindEither)(gDecodeJson(dictGDecodeJson)(object)(Type_Data_RowList.RLProxy.value))(function (rest) {
                                var v1 = Foreign_Object.lookup(fieldName)(object);
                                if (v1 instanceof Data_Maybe.Just) {
                                    return Control_Bind.bind(Data_Either.bindEither)(decodeJson(dictDecodeJson)(v1.value0))(function (val) {
                                        return Data_Either.Right.create(Record.insert(dictIsSymbol)()()(Data_Symbol.SProxy.value)(val)(rest));
                                    });
                                };
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return Data_Either.Left.create("JSON was missing expected field: " + fieldName);
                                };
                                throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 155, column 5 - line 161, column 64): " + [ v1.constructor.name ]);
                            });
                        };
                    });
                };
            };
        };
    };
};
var decodeJObject = (function () {
    var $56 = Data_Maybe.maybe(new Data_Either.Left("Value is not an Object"))(Data_Either.Right.create);
    return function ($57) {
        return $56(Data_Argonaut_Core.toObject($57));
    };
})();
var decodeJsonEither = function (dictDecodeJson) {
    return function (dictDecodeJson1) {
        return new DecodeJson(function (json) {
            return Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
                return "Couldn't decode Either: " + v;
            })(Control_Bind.bind(Data_Either.bindEither)(decodeJObject(json))(function (obj) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Maybe.maybe(new Data_Either.Left("Expected field 'tag'"))(Data_Either.Right.create)(Foreign_Object.lookup("tag")(obj)))(function (tag) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Maybe.maybe(new Data_Either.Left("Expected field 'value'"))(Data_Either.Right.create)(Foreign_Object.lookup("value")(obj)))(function (val) {
                        var v = Data_Argonaut_Core.toString(tag);
                        if (v instanceof Data_Maybe.Just && v.value0 === "Right") {
                            return Data_Functor.map(Data_Either.functorEither)(Data_Either.Right.create)(decodeJson(dictDecodeJson1)(val));
                        };
                        if (v instanceof Data_Maybe.Just && v.value0 === "Left") {
                            return Data_Functor.map(Data_Either.functorEither)(Data_Either.Left.create)(decodeJson(dictDecodeJson)(val));
                        };
                        return new Data_Either.Left("'tag' field was not \"Left\" or \"Right\"");
                    });
                });
            }));
        });
    };
};
var decodeJArray = (function () {
    var $58 = Data_Maybe.maybe(new Data_Either.Left("Value is not an Array"))(Data_Either.Right.create);
    return function ($59) {
        return $58(Data_Argonaut_Core.toArray($59));
    };
})();
var decodeJsonNonEmptyArray = function (dictDecodeJson) {
    return new DecodeJson((function () {
        var $60 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
            return "Couldn't decode NonEmpty Array: " + v;
        });
        var $61 = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Data_Traversable.traverse(Data_NonEmpty.traversableNonEmpty(Data_Traversable.traversableArray))(Data_Either.applicativeEither)(decodeJson(dictDecodeJson)))(Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
            var $63 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
                return "JSON Array" + v;
            });
            var $64 = Data_Bifunctor.rmap(Data_Either.bifunctorEither)(function (x) {
                return new Data_NonEmpty.NonEmpty(x.head, x.tail);
            });
            var $65 = Data_Either.note(" is empty");
            return function ($66) {
                return $63($64($65(Data_Array.uncons($66))));
            };
        })())(decodeJArray));
        return function ($62) {
            return $60($61($62));
        };
    })());
};
var decodeJsonNonEmptyList = function (dictDecodeJson) {
    return new DecodeJson((function () {
        var $67 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
            return "Couldn't decode NonEmpty List: " + v;
        });
        var $68 = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Data_Traversable.traverse(Data_NonEmpty.traversableNonEmpty(Data_List_Types.traversableList))(Data_Either.applicativeEither)(decodeJson(dictDecodeJson)))(Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
            var $70 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
                return "JSON Array" + v;
            });
            var $71 = Data_Bifunctor.rmap(Data_Either.bifunctorEither)(function (x) {
                return new Data_NonEmpty.NonEmpty(x.head, x.tail);
            });
            var $72 = Data_Either.note(" is empty");
            return function ($73) {
                return $70($71($72(Data_List.uncons($73))));
            };
        })())(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Either.functorEither)(Data_List.fromFoldable(Data_Foldable.foldableArray)))(decodeJArray)));
        return function ($69) {
            return $67($68($69));
        };
    })());
};
var decodeList = function (dictDecodeJson) {
    return new DecodeJson((function () {
        var $74 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
            return "Couldn't decode List: " + v;
        });
        var $75 = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Data_Traversable.traverse(Data_List_Types.traversableList)(Data_Either.applicativeEither)(decodeJson(dictDecodeJson)))(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Either.functorEither)(Data_List.fromFoldable(Data_Foldable.foldableArray)))(decodeJArray));
        return function ($76) {
            return $74($75($76));
        };
    })());
};
var decodeJsonTuple = function (dictDecodeJson) {
    return function (dictDecodeJson1) {
        return new DecodeJson(function (j) {
            var f = function (v) {
                if (v instanceof Data_List_Types.Cons && (v.value1 instanceof Data_List_Types.Cons && v.value1.value1 instanceof Data_List_Types.Nil)) {
                    return Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(Data_Tuple.Tuple.create)(decodeJson(dictDecodeJson)(v.value0)))(decodeJson(dictDecodeJson1)(v.value1.value0));
                };
                return new Data_Either.Left("Couldn't decode Tuple");
            };
            return Control_Bind.bind(Data_Either.bindEither)(decodeJson(decodeList(decodeJsonJson))(j))(f);
        });
    };
};
var decodeMap = function (dictOrd) {
    return function (dictDecodeJson) {
        return function (dictDecodeJson1) {
            return new DecodeJson((function () {
                var $77 = Data_Functor.map(Data_Either.functorEither)(Data_Map_Internal.fromFoldable(dictOrd)(Data_List_Types.foldableList));
                var $78 = decodeJson(decodeList(decodeJsonTuple(dictDecodeJson)(dictDecodeJson1)));
                return function ($79) {
                    return $77($78($79));
                };
            })());
        };
    };
};
var decodeSet = function (dictOrd) {
    return function (dictDecodeJson) {
        return new DecodeJson((function () {
            var $80 = Data_Functor.map(Data_Either.functorEither)(Data_Set.fromFoldable(Data_List_Types.foldableList)(dictOrd));
            var $81 = decodeJson(decodeList(dictDecodeJson));
            return function ($82) {
                return $80($81($82));
            };
        })());
    };
};
var decodeForeignObject = function (dictDecodeJson) {
    return new DecodeJson((function () {
        var $83 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
            return "Couldn't decode ForeignObject: " + v;
        });
        var $84 = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Data_Traversable.traverse(Foreign_Object.traversableObject)(Data_Either.applicativeEither)(decodeJson(dictDecodeJson)))(decodeJObject);
        return function ($85) {
            return $83($84($85));
        };
    })());
};
var decodeArray = function (dictDecodeJson) {
    return new DecodeJson((function () {
        var msg = function (i) {
            return function (m) {
                return "Failed at index " + (Data_Show.show(Data_Show.showInt)(i) + ("): " + m));
            };
        };
        var f = function (i) {
            var $86 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(msg(i));
            var $87 = decodeJson(dictDecodeJson);
            return function ($88) {
                return $86($87($88));
            };
        };
        var $89 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
            return "Couldn't decode Array (" + v;
        });
        var $90 = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Data_Either.applicativeEither)(f))(decodeJArray);
        return function ($91) {
            return $89($90($91));
        };
    })());
};
module.exports = {
    decodeJson: decodeJson,
    gDecodeJson: gDecodeJson,
    DecodeJson: DecodeJson,
    decodeJArray: decodeJArray,
    decodeJObject: decodeJObject,
    GDecodeJson: GDecodeJson,
    decodeJsonMaybe: decodeJsonMaybe,
    decodeJsonTuple: decodeJsonTuple,
    decodeJsonEither: decodeJsonEither,
    decodeJsonNull: decodeJsonNull,
    decodeJsonBoolean: decodeJsonBoolean,
    decodeJsonNumber: decodeJsonNumber,
    decodeJsonInt: decodeJsonInt,
    decodeJsonString: decodeJsonString,
    decodeJsonJson: decodeJsonJson,
    decodeJsonNonEmptyArray: decodeJsonNonEmptyArray,
    decodeJsonNonEmptyList: decodeJsonNonEmptyList,
    decodeJsonChar: decodeJsonChar,
    decodeForeignObject: decodeForeignObject,
    decodeArray: decodeArray,
    decodeList: decodeList,
    decodeSet: decodeSet,
    decodeMap: decodeMap,
    decodeVoid: decodeVoid,
    decodeRecord: decodeRecord,
    gDecodeJsonNil: gDecodeJsonNil,
    gDecodeJsonCons: gDecodeJsonCons
};
