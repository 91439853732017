// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Audio_WebAudio_Types = require("../Audio.WebAudio.Types/index.js");
var Audio_WebAudio_Utils = require("../Audio.WebAudio.Utils/index.js");
var Effect_Aff_Compat = require("../Effect.Aff.Compat/index.js");
var state = function (ctx) {
    return function __do() {
        var s = $foreign["_state"](ctx)();
        if (s === "suspended") {
            return Audio_WebAudio_Types.SUSPENDED.value;
        };
        if (s === "running") {
            return Audio_WebAudio_Types.RUNNING.value;
        };
        if (s === "closed") {
            return Audio_WebAudio_Types.CLOSED.value;
        };
        return Audio_WebAudio_Types.CLOSED.value;
    };
};
var listener = Audio_WebAudio_Utils.unsafeGetProp("listener");
var destination = Audio_WebAudio_Utils.unsafeGetProp("destination");
var decodeAudioDataAsync = function (ctx) {
    var $1 = $foreign.decodeAudioDataAsyncImpl(ctx);
    return function ($2) {
        return Effect_Aff_Compat.fromEffectFnAff($1($2));
    };
};
module.exports = {
    destination: destination,
    listener: listener,
    state: state,
    decodeAudioDataAsync: decodeAudioDataAsync,
    newAudioContext: $foreign.newAudioContext,
    sampleRate: $foreign.sampleRate,
    currentTime: $foreign.currentTime,
    suspend: $foreign.suspend,
    resume: $foreign.resume,
    close: $foreign.close,
    decodeAudioData: $foreign.decodeAudioData,
    createBufferSource: $foreign.createBufferSource,
    createGain: $foreign.createGain,
    createOscillator: $foreign.createOscillator,
    createAnalyser: $foreign.createAnalyser,
    createBiquadFilter: $foreign.createBiquadFilter,
    createConvolver: $foreign.createConvolver,
    createDelay: $foreign.createDelay,
    createDynamicsCompressor: $foreign.createDynamicsCompressor,
    createStereoPanner: $foreign.createStereoPanner,
    createPanner: $foreign.createPanner
};
