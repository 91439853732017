// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var PrimProp = (function () {
    function PrimProp(value0) {
        this.value0 = value0;
    };
    PrimProp.create = function (value0) {
        return new PrimProp(value0);
    };
    return PrimProp;
})();
var Handler = (function () {
    function Handler(value0) {
        this.value0 = value0;
    };
    Handler.create = function (value0) {
        return new Handler(value0);
    };
    return Handler;
})();
var mkProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return v1.value0;
        };
        if (v1 instanceof Handler) {
            return v1.value0(v);
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 18, column 1 - line 22, column 7): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var handleProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return new PrimProp(v1.value0);
        };
        if (v1 instanceof Handler) {
            return new PrimProp(v1.value0(v));
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 27, column 1 - line 31, column 15): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var functorProps = new Data_Functor.Functor(function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return new PrimProp(v1.value0);
        };
        if (v1 instanceof Handler) {
            return new Handler(function (k) {
                return v1.value0(function ($25) {
                    return k(v($25));
                });
            });
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 13, column 1 - line 15, column 48): " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var filterProp = function (v) {
    return function (v1) {
        if (v1 instanceof PrimProp) {
            return v1;
        };
        if (v1 instanceof Handler) {
            return new Handler(function (h) {
                return v1.value0(function (a) {
                    var $23 = v(a);
                    if ($23) {
                        return h(a);
                    };
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                });
            });
        };
        throw new Error("Failed pattern match at Concur.Core.Props (line 37, column 1 - line 41, column 12): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
module.exports = {
    PrimProp: PrimProp,
    Handler: Handler,
    mkProp: mkProp,
    handleProp: handleProp,
    filterProp: filterProp,
    functorProps: functorProps
};
