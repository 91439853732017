// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Effect_Aff_Compat = require("../Effect.Aff.Compat/index.js");
var awaitKey = Effect_Aff_Compat.fromEffectFnAff($foreign["_awaitKey"]);
module.exports = {
    awaitKey: awaitKey,
    startListening: $foreign.startListening,
    stopListening: $foreign.stopListening
};
